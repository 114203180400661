.social-links {

    &__list {
        display: flex;
        justify-content: center;

        &__item {
            margin: 15px;

			.icon,
			i {
                display: block;
                width: 100%;
                height: 100%;
                font-size: 30px;
                color: var(--color-gray-1);
				line-height: 1;
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
                text-decoration: none !important;

                &:hover .icon, &:active .icon {
                    color: var(--color-nov-red);
                }
            }

            i {
                font-size: 32px;
            }
        }
    }
}
