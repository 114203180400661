/**
* UI tester
*/

.ui-tester {
	overflow: hidden;
	background-color: var(--color-white);
	padding: 90px 0 180px;

	.f-row {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
	}

	.f-col.half,
	.f-col {
		width: 100%;
		flex: 0 0 auto;

		&.full-width {
			width: 100%;
			flex: 1 0 auto;
		}

		&.dark {
			.ui-wrapper,
			.group-tap {
				background: var(--color-black);
				color: var(--color-white);
			}
		}

	}

	p,
	h3,
	h4 {
		font-weight: normal;

		&:first-letter {
			text-transform: uppercase;
		}
	}

	p {
		font-size: 14px;
	}

	.group-tap {
		display: inline-block;
		border: 1px solid var(--color-border);
		border-bottom: 0;
		background-color: var(--color-white);
		margin-bottom: -2px;
		z-index: 1;
		position: relative;
		border-radius: var(--spacing) var(--spacing) 0 0;
		padding: var(--spacing) calc(var(--spacing) * 2);
		margin: 0;
		color: var(--color-gray-1);
	}

	.ui-input {
		&+.ui-input {
			margin-left: 30px;
		}
	}

	.icons {
		i {
			display: inline-block;
			font-size: 28px;
			margin: var(--spacing);
		}
	}

	section {
		padding: var(--spacing);

		&+section {
			margin-top: 30px;
		}

		&.dark-bg {
			background: var(--color-gray-0);
			p,
			h3,
			h4 {
				color: var(--color-white);
			}
		}
	}

	// Test filter-wrapper.
	.ui-wrapper {
		padding: 50px;
		min-height: calc(100% - 30px);
		border: 1px solid var(--color-border);
		border-radius: 0 var(--spacing) var(--spacing) var(--spacing);
		display: flex;
		align-items: flex-start;
		position: relative;
		flex-wrap: wrap;

		.main-logo,
		> [class*="ui-"] {
			margin: var(--spacing);
		}

		&.vertical {
			flex-direction: column;
		}
	}
}

@media screen and (min-width: $bp-mobile) {}

@media screen and (min-width: $bp-tablet) {
	.ui-tester {
		.f-col.half {
			width: 50%;
			flex: 0 0 50%;
		}
	}
}

@media screen and (min-width: $bp-desktop-small) {}
