.J5-features-list {

	&__description {
		margin: 0 auto;
		max-width: 650px;

		@include media(sm) {
			text-align: left;
		}
	}

	&__columns {
		padding-top: 50px;

		@include media(md) {
			padding-top: 60px;
		}
		
		.J5-features-list--one-column &,
		.J5-features-list--two-column & {
			margin: 0 auto;
			max-width: 650px;
		}

		@include media(md) {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
		}
	}

	&__column {
		margin-bottom: 50px;
		width: 100%;

		@include media(md) {
			.J5-features-list--two-column & {
				margin-right: 3%;
			}

			.J5-features-list--three-column & {
				margin-right: 3%;
				width: 30%;
				flex: 0 0 30%;
			}
		}
	}

	&__headline {
		color: var(--color-gray-1);
		font-size: 16px;
		line-height: 28px;
		margin-top: 0;

		@include media(md) {
			line-height: 32px;
		}
	}

	.rte {
		h2, h3, h4, h5, h6, p, ol, ul {
			&:first-child {
				margin-top: 0;
			}
		}
	}
}
