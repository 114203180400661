/**
* UI Buttons
*/

@mixin nullify-webkit-button() {
	outline: none;
	border: 0;
	-webkit-appearance: none;
	background: transparent;
}

// svg sprite animation
@keyframes btn-svg-sprite-step {
	0% {
	background-position: 0px 0px;
	}

	100% {
	background-position: -432px 0px;
	}
}

// svg sprite animation
@keyframes btn-svg-step {
	0% {
		x: 0;
	}

	100% {
		x: -432px;
	}
}

@mixin ui-btn-factory($type: 'primary') {

	@if $type=='base' {
		@include nullify-webkit-button();
		display: inline-flex;
		font-family: var(--ui-font-family);
		font-size: var(--ui-btn-font-size, 16px);
		line-height: var(--ui-btn-line-height);
		font-weight: var(--font-weight-regular);
		letter-spacing: normal;
		text-decoration: none;
		transition: opacity var(--ui-transition-dur);

		&.inactive,
		&.disabled {
			pointer-events: none;
		}

		.icon-font,
		[class^="icon-"],
		i {
			pointer-events: none;
		}
		// type
		&.small {
			font-size: 14px;
			line-height: normal;
		}
	}

	@if $type=='primary' {
		color: var(--color-blue);
		position: relative;
		outline: none;
		line-height: normal;
		align-items: center;
		padding: 5px 0;
		// layer. prevent icon click
		&:after {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.001);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			content: "";
		}
		// underline
		&:before {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			width: 40px;
			height: 3px;
			background-color: var(--color-nov-red);

			transition: all .1s ease-in-out;
		}

		&:hover {
			&:before {
				width: calc(100% + 16px);
			}
		}

		&.white {
			color: var(--color-white);
		}
		// state
		&:focus {
			@include global-focus-link();
		}

		&:active,
		&.active {
			&:before {
				background-color: var(--color-dark-red);
			}
		}

		&.active {
			pointer-events: none;
		}

		&.inactive {
			color: var(--ui-btn-inactive-color);

			&:before {
				background-color: var(--ui-btn-inactive-color);
			}
		}
	}

	@if $type=='secondary' {
		position: relative;
		outline: none;
		line-height: normal;
		align-items: center;
		padding: 5px 0;
		color: #1b1b1b;
		transition: color var(--ui-transition-dur);
		// layer. prevent icon click
		&:after {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.001);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			content: "";
		}
		// icon at the begining or end
		i {
			display: inline-block;
			font-size: 16px;
			font-weight: 600;
			color: var(--color-nov-red);
			margin-left: calc(var(--spacing) * 0.5);
			pointer-events: none;
		}
		// state
		&:focus {
			@include global-focus-link();
		}

		&.white {
			color: var(--color-white);
		}

		&:active,
		&.active,
		&:hover {
			outline: none;
			color: #696969;
		}

		&.active {
			pointer-events: none;
		}

		&.inactive {
			color: var(--ui-btn-inactive-color);

			i {
				color: var(--ui-btn-inactive-color);
			}
		}
	}
	// round corder
	@if $type=='box' {
		@include nullify-webkit-button();
		font-weight: 600;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 8px 28px;
		height: 48px;
		border-radius: 24px;
		position: relative;
		color: var(--color-white);
		background-color: var(--color-nov-red);
		transition: background-color var(--ui-transition-dur);

		&:focus {
			@include global-focus-box();
		}

		&:active,
		&.active,
		&:hover {
			outline: none;
			background-color: var(--color-hover-red);
		}

		&.inactive {
			background-color: var(--ui-btn-inactive-color);
		}

		&.no-round-border {
			border-radius: 0;
		}
	}

	@if $type=='icon' {
		@include nullify-webkit-button();
		width: 30px;
		height: 30px;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--color-font-black);
		position: relative;
		border: 0;
		border-radius: 1px;
		text-decoration: none;
		padding: 2.5px;
		cursor: pointer;
		user-select: none;
		outline: none;
		transition: background-color var(--ui-transition-dur);

		.ui-btn-label {
			font-size: 12px;
			position: absolute;
			top: -14px;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
			transition: top var(--ui-transition-dur), opacity var(--ui-transition-dur);
		}
		// hit area
		&:after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			transform: translate3d(-50%, -50%, 0);
			top: 50%;
			left: 50%;
			z-index: 2;
		}
		// states
		&:focus {
			@include global-focus-box();
		}

		&:hover,
		&:active {
			outline: none;
			text-decoration: none;
		}

		&.large {
			width: 50px;
			height: 50px;

			i {
				font-size: 22px;
			}
		}

		&.has-bg {
			background-color: var(--color-nov-red);

			i {
				color: var(--color-white);
			}

			&:hover {
				background-color: var(--color-hover-red);
			}
		}

		&.circle {
			border-radius: 50%;
		}
		// unique type
		&.icon-red {
			i {
				color: var(--color-nov-red);
			}
		}

		&.inactive {
			background-color: var(--ui-btn-inactive-color);
		}
	}

	@include media(sm) {
		.hide-desktop {
			display: none;
		}
	}

	@include media(sm,true) {
		.hide-mobile {
			display: none;
		}
	}
}

%ui-btn,
.ui-btn {
	@include ui-btn-factory('base');
}

%ui-btn--icon,
.ui-btn--icon {
	@include ui-btn-factory('base');
	@include ui-btn-factory('icon');
}

.ui-btn--primary {
	@include ui-btn-factory('base');
	@include ui-btn-factory('primary');
}

.ui-btn--secondary {
	@include ui-btn-factory('base');
	@include ui-btn-factory('secondary');
}

.ui-btn--box {
	@include ui-btn-factory('base');
	@include ui-btn-factory('box');
}
