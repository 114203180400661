.I10-testimonial {
    &, p {
        font-size: var(--body-font-size-small);
        line-height: var(--body-line-height-small);
    }

    .search-sidebar-box__container {
        padding: 3em;

        @include media($bp-tablet) {
            max-width: 289px;
        }
    }

    blockquote {
        padding: 0;
        margin: 0;
        font-size: var(--subhead-font-size);
        line-height: 1.33;
        font-weight: var(--font-weight-bold);
    }

    cite {
        display: block;
        font-style: normal;
        margin: 1.5em 0;
    }

    &__name, &__title {
        display: block;
    }

    &__title {
        color: var(--color-gray-1);
    }

    &__cta {
        display: block;
        margin-top: 4em;
        font-size: var(--label-font-size);
        line-height: var(--label-line-height);
    }
}
