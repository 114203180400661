.D5-article-text {
	&-inner {
		max-width: 736px;
		margin: 0 auto;

		ul, ol {
			margin-bottom: 20px;
		}

		& > *:first-child{
			margin-top: 0;
			padding-top: 0;
		}

		& > *:last-child{
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.ui-btn--box {
			display: inline-block;
			margin-top: 34px; 
			padding: 15px 30px;

			color: white;

			border-radius: 0;
			background-color: var(--color-nov-red);
			transition: background-color .1s ease-in-out;
			text-decoration: none;

			&:hover {
				background-color: var(--color-dark-red);
				text-decoration: none;
			}
		}
		
		.D5-article-text__link-cta {
			margin-top: 34px;
		}
	}

	.D10-article-subhead + & {
		padding-top: 0;
	}

	.D6-article-image + & {
		padding-top: 50px;
	}
	.D5-article-text + & {
		padding-top: 0;

		h2 {
			padding-top: 50px;
		}
	}
}
