.h1-social-channels {
    text-align: center;

    &.is-grey {
		margin-top: var(--standard-padding);
		padding-bottom: calc(var(--standard-padding) / 2);
        background: var(--color-gray-4);
    }

    &__inner {

        max-width: 600px;
        margin-right: auto;
        margin-left: auto;

        &__heading {
            font-size: 30px;
            margin: 0 0 20px;
            @include media($bp-desktop-small) {
                font-size: 48px;
                line-height: 56px;
            }
        }

        &__desc {
            font-size: 14px;
            line-height: 24px;
            color: var(--color-gray-1);
            margin-bottom: 32px;
        }
    }
}
