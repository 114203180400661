
.D9-image-block__splitter {
    width: 100%;
    height: 100%;
    display:block;
    
    &.horizontal {
        flex-direction: column;
        

        @include media(md) {
            flex-direction: row;
            display:flex;

            > .D9-image-block__item {
                flex: 0 0 50%;
                width: 50%;
            }
        }

        .D9-image-block__splitter.horizontal {
            flex-direction: row;
            display:flex;

            > .D9-image-block__item {
                flex: 0 0 50%;
                width: 50%;
            }
        }
    }

    &.vertical {
        flex-direction: column;

        @include media(md) {
            display:flex;
        }

        > .D9-image-block__item {
            flex: 0 0 50%;
            height: 50%;
        }
    }

}
