.I9-search-stats {
    .search-sidebar-box__container {
        padding: 6em 2em;
        text-align: center;
    }

    &__number {
        font-weight: var(--font-weight-bold);
        font-size: 72px;
        line-height: 0.72;
    }

    &__description {
        position: relative;
        color: var(--color-gray-1);
        padding-top: 4em;

        &::before {
			content: '';

            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            display: block;
            margin: -2em 0;
            height: 2px;
            width: 43px;

            border-bottom: 2px solid var(--color-nov-red);
        }
    }

    &__cta {
        display: block;
		margin-top: 3em;

        font-size: var(--label-font-size);
        line-height: var(--label-line-height);
    }

}
