.B4-stock-hero {
    background-repeat: no-repeat;
	background-position: center center;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    background-color: var(--color-gray-2);


    @include media($bp-tablet) {
        min-height: 720px;
    };

    .container {
        position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		height: auto;
        min-height: 100vh;
        
        @include media($bp-tablet) {
            min-height: 720px;
        };
    }

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, .3);
    }

    &__inner {
        display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		height: auto;
        min-height: 100vh;
        align-items: flex-start;
        color: var(--color-white);

        &::before,
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 65px;
            background-color: transparent;
        }

        /*
        &::before {
            height: calc(65px + 62px);
    
            @include media(md) {
                height: calc(65px + 73px);
            }
    
            @include media(md) {
                height: calc(65px + 80px);
            }
        }
        */

        &::after {
            height: calc(65px + 160px);

            @include media($bp-tablet) {
                height: calc(65px + 100px);
            }
        }

        .ui-btn--primary:hover::before {
			width: 100%;
		}

        @include media($bp-tablet) {
            min-height: 720px;
        };

        & > * {
            padding-top: 8px;
            padding-bottom: 8px;
            z-index: 2;
        }

        &__label {
            font-size: 14px;
            line-height: 24px;
            font-weight: var(--font-weight-bold);
        }

        &__heading {
            font-size: 48px;
            line-height: 56px;
            @include media($bp-tablet) {
                font-size: 72px;
                line-height: 72px;
            }

            @include media($bp-desktop-small) {
                font-size: 80px;
                line-height: 80px;
            }
        } 

        &__desc {
            font-size: 14px;
            line-height: 24px;
            @include media($bp-tablet) {
                font-size: 16px;
            }
        }

        a {
            display: block;
            color: var(--color-white);
        }
    }

    &__data {
        position: absolute;
        height: 160px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        bottom: 0;

        @include media($bp-desktop-small) {
            width: 850px;
            right: 0;
        }

        padding: 0 16px;
        background: var(--color-white);
        border-bottom: 2px solid var(--color-gray-3);

        @include media($bp-tablet) {
            height: 100px;
            align-items: center;
            justify-content: space-around;
        }

        & > * {
            margin-left: 10px;
            margin-bottom: 6px;
            padding: 10px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 40px;
                background: #e3e3e3;
                left: -8px;
                top: 6px;

                @include media($bp-tablet) {
                    height: 20px;
                    top: 50px;
                }
            }


            &:first-child, &:last-child {
                &::after {
                    display: none;
                }
            }

            &:last-child {
                margin-top: 0;
                @include media($bp-tablet) {
                    margin-top: 8px;
                }
            }
        }

        &__item {
            @include media($bp-tablet) {
                padding-top: 24px;
            }
            &__label {
                font-size: 10px;
                padding-bottom: 2px;
                color: var(--color-gray-1);
                @include media($bp-tablet) {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            &:first-child {
                .B4-stock-hero__data__item__content {
                    color: var(--color-nov-red);
                }
            }

            &__content {
                font-size: 14px;
                color: var(--color-gray-1);
                font-weight: var(--font-weight-bold);
                position: relative;

                @include media($bp-tablet) {
                    font-size: 24px;
                    line-height: 32px;
                }

                a {
                    font-weight: var(--font-weight-regular);
                    font-size: 10px;
                    @include media($bp-tablet) {
                        display: none;
                    }
                }
            }

            &__stock-price {
                .ui-btn--icon {
                    pointer-events: none;
                    width: 11px;
                    height: 11px;
                    @include media($bp-tablet) {
                        width: 20px;
                        height: 20px;
                    }
                    i {
                        font-size: 16px;
                        @include media($bp-tablet) {
                            font-size: 24px;
                        }

                        &.is-green {
                            color: #3A873E;
                        }
                    }
                }
            }
        }

    }
}

// IE11 Hacks
_:-ms-input-placeholder, :root .B4-stock-hero__inner > * {
	max-width: 100%;
}

