/**
* UI Ballon
*/

@keyframes ui-balloon-in-down {
	0% {
		opacity: 0;
		transform: translateY(-8px);
	}
	75% {
		transform: translateY(2px);
	}
	100% {
		opaicty: 1;
		transform: translateY(0);
	}
}

@keyframes ui-balloon-in-up {
	0% {
		opacity: 0;
		transform: translateY(8px);
	}
	75% {
		transform: translateY(-2px);
	}
	100% {
		opaicty: 1;
		transform: translateY(0);
	}
}

.ui-balloon {
	max-width: 300px;
	position: relative;
	background-color: var(--color-white);
	filter: drop-shadow(0px 0px 5px rgba(51, 51, 51, 0.22));

	&__inner {
		margin: 0;
		padding: calc(var(--spacing) * 3) calc(var(--spacing) * 2);
	}

	&__items {
		margin: 0;
		padding: calc(var(--spacing) * 3) calc(var(--spacing) * 2);
		list-style: none;
	}

	&__item {

		& + & {
			margin-top: calc(var(--spacing) * 1.5);
		}

	}

	// point >
	&:before {
		content: "";
		width: 0;
		height: 0;

		position: absolute;
		top: calc(var(--ui-ballon--tip-width) * -2);
		right: calc(50% - var(--ui-ballon--tip-width));

		border-style: solid;
		border-width: var(--ui-ballon--tip-width);
		border-color: transparent transparent var(--color-white) transparent;
	}

	// variations
	&.down {
		// transform: translateY(var(--spacing));

		&:before {
			top: auto;
			bottom: calc(1px + var(--spacing) * -2);
			border-color: var(--color-white) transparent transparent transparent;
		}

	}

	&.tip-center {
		&:before {
			right: calc(50% + var(--ui-ballon--tip-width));
		}
	}

	&.tip-75 {
		&:before {
			right: calc(25% + var(--ui-ballon--tip-width));
		}
	}

	&.no-tip {
		&:before {
			visibility: hidden;
		}
	}

	//state
	&.active {
		// opacity: 1;
		// transform: none;
	}

	&.animate-in {
		animation-duration: 600ms;
		animation-fill-mode: forwards;
		animation-name: ui-balloon-in-down;

		&.down {
			animation-name: ui-balloon-in-up;
		}

	}

}

:root {
	--ui-ballon--tip-width: 7px;
}
