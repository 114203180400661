.D1-block-quote {
	padding-top: 30px;
	margin-top: 30px;

	@include media(sm) {
		padding-top: 80px;
		margin-top: 0;
	}

	.icon--quote-mark svg {
		width: 20px;
		height: 18px;

		path, circle, polygon {
			fill: var(--color-nov-red);
			stroke: none;
		}
	}

	blockquote {
		color: var(--color-gray-1);
		font-size: var(--body-font-size-small);
		line-height: var(--body-line-height-small);
		margin: 1em 0 0 0;

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__simple {
		margin: 0 auto;
		max-width: 480px;
		text-align: center;
	}

	&__image {
		margin: 0 auto;
		text-align: center;

		@include media(sm) {
			max-width: 480px;
		}

		&__figure {
			display: block;
			height: 0;
			padding-bottom: 100%;
			background: var(--color-gray-5);
			margin: -32px -25px 112px -25px;
			padding-left: 32px;
			padding-right: 32px;

			@include media(sm) {
				margin: 0 auto;
				width: 291px;
				padding: 0;
				background: transparent;
				height: auto;
			}

			&::after {
				content: "";
				clear: both;
				display: table;
			}

			&__topblock {
				background: var(--color-gray-5);

				@include media(sm) {
					float: right;
					width: 231px;
					height: 124px;
				}
			}

			&__imageblock {
				position: relative;
				padding: 64px 0 0 0;
				margin-bottom: 40px;

				@include media(sm) {
					padding: 0;
					top: -62px;
					float: left;
					width: 237px;
					height: 237px;
					margin-bottom: calc(2em - 62px);
				}

				img {
					display: block;
					margin: 0 auto;
					width: 100%;
					height: auto;
				}
			}
		}

		&.standard-padding {
			padding-top: 0;
		}
	}

	&__author {
		margin: 2em 0;

		&__name {
			font-weight: var(--font-weight-medium);
			font-size: var(--body-font-size-small);
			line-height: var(--body-line-height-small);
		}

		&__title {
			color: var(--color-gray-1);
			font-size: var(--label-font-size);
			line-height: var(--label-line-height);
		}
	}

	@include media(sm) {
		blockquote {
			font-size: var(--body-font-size);
			line-height: var(--body-line-height);
		}

		&__author {
			&__name {
				font-size: var(--body-font-size);
				line-height: var(--body-line-height);
			}

			&__title {
				font-size: var(--body-font-size-small);
				line-height: var(--body-line-height-small);
			}
		}
	}

	@include media(md) {
		.icon--quote-mark svg {
			width: 30px;
			height: 27px;
		}

		&__simple {
			max-width: 735px;

			blockquote {
				font-size: var(--subhead-font-size);
				line-height: var(--subhead-line-height);
			}

			.D1-block-quote__author__name {
				font-weight: var(--font-weight-regular);
			}
		}

		&__image {
			max-width: 990px;
			text-align: left;

			&::after {
				content: "";
				clear: both;
				display: table;
			}

			.icon--quote-mark svg {
				width: 35px;
				height: 31px;
			}

			&__figure {
				float: left;
				width: 608px;

				&__topblock {
					width: 416px;
					height: 224px;
				}

				&__imageblock {
					top: -111px;
					width: 383px;
					height: 383px;
					margin-bottom: calc(2em - 111px);
				}
			}

			&__quote {
				width: 480px;
				float: right;
				position: relative;
				top: -320px;
				margin-bottom: calc(2em - 320px);
			}
		}

		&__author {
			margin: 1em 0;
		}
	}
}
