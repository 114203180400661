.D8-faq-panel {

    &__inner {
        max-width: 544px;
        margin: 0 auto;

        h3 {
            font-size: var(--subhead-font-size);
            line-height: normal;
            font-weight: var(--font-weight-bold);

            @include media($bp-desktop-small) {
                line-height: 32px;
                font-weight: var(--font-weight-regular);
            }
        }
    }

    &__nav {
        position: relative;

        &__desktop {
            display: none;
            position: absolute;
            top: 3px;
            left: -200px;

            li {
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
                margin: 8px 0;

                a {
                    color: #000000;
                }
            }
        }

        &__mobile {
            display: block;
            text-align: center;

            select {
                border: 1px solid var(--color-gray-1);
            }
        }
        
        @include media($bp-desktop-small) {

            &__desktop {
                display: block;
            }

            &__mobile {
                display: none;
            }

        }
    }

    &__accordion {
        display: none;

        &[data-category="0"] {
            display: block;
        }
    }

    &__contact {
        margin-top: calc(56px / 2);

        p {
            color: var(--color-gray-1);
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}
@import 'card';