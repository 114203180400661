/**
* UI Pager
*/

.ui-pager {
	$btn-width: 16px;
	$btn-height: 16px;
	$icon-size: 8px;

	display: inline-flex;
	align-items: center;
	justify-content: space-between;

	font-size: 12px;
	line-height: normal;
	color: var(--color-black);

	>* {
		flex: 0 0 auto;
	}

	//current & total
	&__count {
		display: inline-block;
		text-align: center;
		min-width: 40px;
		margin: 0 var(--spacing);

	}

	&__index {
		display: inline-block;
		text-align: center;
		width: 20px;
		height: $btn-height;
		line-height: $btn-width;
		will-change: color;
		transition: color var(--ui-transition-dur)/2;
		color: var(--color-nov-red);
		&:focus,
		&:hover,
		&.active {
			color: var(--color-black);
			text-decoration: underline;
			outline: none;
		}
	}

	&__btn {

		i {
			font-size: 24px;
		}

		//inactive state
		&.inactive {
			background: transparent;
			i {
				color: var(--ui-btn-inactive-color);
			}
		}
	}
}

@media screen and (max-width: 480px) {}
