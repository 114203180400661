.cookie-policy {
	position: fixed;

	bottom: 0;
	left: 0;
	right: 0;

	padding: 22px 32px 24px;

	background: var(--color-white);
	z-index: 1000000;

	p {
		margin: 0 0 18px;
		color: var(--color-gray-1);
		font-size: 16px;
		line-height: 24px;
	}

	.ui-wrapper {
		width: 100%;
		justify-content: center;
	}

	.ui-btn {
		min-width: 120px;
		height: 40px;

		font-size: 14px;
		font-weight: var(--font-weight-regular);

		&--gray {
			background-color: #9b9b9b;

			&:active,
			&.active,
			&:hover {
				background-color: var(--color-gray-2);
			}

		}
	}
}
