/**
* Abstract view
*/

.view,
.module {
    .container {
        margin-left: auto;
        margin-right: auto;
        padding: 0 25px;
		width: 100%;

        @include media(md) {
            max-width: 970px;
        }
        @include media(lg) {
            max-width: 1170px;
        }
    }
}

// Inbetween top spacing.
$startIndex: 1;
$endIndex: 99;
$positiveSpacingName: 'p-space';
$negativeSpacingName: 'n-space';
// Positive margins.
@for $i from $startIndex through $endIndex {
    .#{$positiveSpacingName}-#{$i * 10} {
        margin-top: 40px;
    }
    .#{$negativeSpacingName}-#{$i * 10} {
        margin-top: -40px;
    }
}

@media screen and (min-width: $bp-mobile) {
    @for $i from $startIndex through $endIndex {
        .#{$positiveSpacingName}-#{$i * 10} {
            margin-top: $i * 6px;
        }
        .#{$negativeSpacingName}-#{$i * 10} {
            margin-top: -$i * 6px;
        }
    }
}

@media screen and (min-width: $bp-tablet) {
    @for $i from $startIndex through $endIndex {
        .#{$positiveSpacingName}-#{$i * 10} {
            margin-top: $i * 8px;
        }
        .#{$negativeSpacingName}-#{$i * 10} {
            margin-top: -$i * 8px;
        }
    }
}

@media screen and (min-width: $bp-desktop-small) {
    @for $i from $startIndex through $endIndex {
        .#{$positiveSpacingName}-#{$i * 10} {
            margin-top: $i * 10px;
        }
        .#{$negativeSpacingName}-#{$i * 10} {
            margin-top: -$i * 10px;
        }
    }
}
