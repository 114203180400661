.D10-article-subhead {
    &__inner {
        max-width: 736px;
        margin: 0 auto;
    }
    &__infobar {
        &__label {
            color: var(--color-gray-1);
            font-size: var(--label-font-size);
            line-height: var(--label-line-height);
        }
        margin-top: 1.5em;
		margin-bottom: 1.5em;

		@include media(md) {
			margin-top: 1em;
			margin-bottom: 2em;
		}
    }
    p:first-of-type  {
		margin-top: 2.5em;
		font-weight: var(--font-weight-medium);

        &::first-letter {
            font-size: 67px;
            font-weight: var(--font-weight-bold);
			line-height: 0.79;
            float: left;
            padding-right: 10px;
			margin-left: -0.065em;
        }
	}

	&__infobar + p:first-of-type {
		margin-top: 0;
	}

    @include media(md) {
        p:first-of-type::first-letter {
            font-size: 115px;
			line-height: 0.79;
		}
    }

    .B1-hero + &,
    .H2-social-share + & {
		padding-top: 0;

		@include media(md) {
			position: relative;
			margin-top: -100px;
		}

        .container {
            max-width: 992px;
			background-color: var(--color-white);

			@include media(md) {
				padding-top: 25px;
			}
        }
    }
}


@-moz-document url-prefix() {
    .D10-article-subhead {
        p:first-of-type::first-letter {
            margin-top: 0.1em;
        }
    }
}
