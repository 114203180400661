.I7-product-services-detail {
    &, p {
        font-size: var(--label-font-size);
        font-weight: var(--font-weight-regular);
        line-height: 2;

        @include media($bp-tablet) {
            line-height: 1.33;
        }

        @include media($bp-desktop-small) {
            font-size: var(--body-font-size-small);
            line-height: var(--body-line-height-small);
        }
    }

    h5 {
        font-size: var(--h4-font-size);
        font-weight: var(--font-weight-regular);
        line-height: 1.25;
        margin: 0;
        margin-bottom: 0.25em;

        @include media($bp-tablet) {
            font-weight: var(--font-weight-bold);
        }

        @include media($bp-desktop-small) {
            font-size: 48px;
            line-height: 1.17;
        }
    }

    p {
        margin: 0;
        margin-bottom: 1.5em;
    }

    &__container {
        margin: 0 auto;
        box-shadow: 0 12px 11px -6px rgba(0, 0, 0, 0.21);

        @include media($bp-tablet) {
            box-shadow: -3px 5px 10px 0 rgba(0, 0, 0, 0.14);
            max-width: 680px;
            display: flex;
            flex-direction: row-reverse;
        }

        @include media($bp-desktop-small) {
            max-width: 1119px;
        }
    }

    &__media {
        @include background-cover-image();
        width: 100%;
        margin-bottom: 0;
        padding: 0;

        &::before {
            content: "";
            display: block;
            padding-top: 48.98%;
        }

        @include media($bp-tablet) {
            max-width: 292px;

            &::before {
                padding-top: 120.55%;
            }
        }

        @include media($bp-desktop-small) {
            max-width: 385px;

            &::before {
                padding-top: 95.58%;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 3em;

        p {
            flex-grow: 1;
        }
    }

    &__links {
        column-count: 2;
        margin-top: 1.5em;

        li::before {
            position: relative;
            top: 0.1em;
            font-family: 'icomoon';
            content: "\e908";
            padding-right: 0.1em;

            
        }

        @include media($bp-tablet) {
            li {
                padding-bottom: 1em;
            }
        }

        @include media($bp-desktop-small) {
            font-size: var(--label-font-size);
        }
    }
}