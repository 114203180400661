.J6-product-table {
	color: var(--color-gray-1);

	.J6-product-table__title {
		font-size: var(--body-font-size);
		line-height: 1.5;
		margin: 1em 0;
		text-align: left;
	}

	.J6-product-table__description {
		font-size: var(--label-font-size);
		line-height: var(--label-line-height);
		text-align: left;
		margin-bottom: 16px;
		margin-top: 0;
		text-align: left;
	}

	.container {
		text-align: center;
	}

	&__centered-block {
		display: block;
		margin: 0 auto;
		width: 100%;
	}

	&__container {
		display: inline-block;
		margin: 0 auto;
		overflow-x: auto;
		width: 100%;

		@include media(xs) {
			width: auto;
		}

		&::-webkit-scrollbar {
			width: 1em;
			height: 4px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: var(--color-nov-red);
			border-radius: 4px;
		}

		table ~ p,
		table ~ small {
			font-size: var(--label-font-size);
			line-height: var(--label-line-height);
			margin-top: 16px;
			margin-bottom: 0;
			text-align: center;
		}


		&.u-disable-first-column-header {
			table {
				tr {
					td {
						&:first-child {
							background-color: transparent;
							border-bottom-color: var(--color-gray-4);
							font-weight: var(--font-weight-regular);
						}
					}
				}
			}
		}

		&.u-enable-first-row-header {
			table {
				tr {
					&:first-child {
						td {
							background-color: var(--color-gray-4);
							border-right-color: var(--color-white);
							border-bottom-color: var(--color-white);
							font-weight: var(--font-weight-bold);

							&:last-child {
								border-right-color: var(--color-gray-4);
							}
						}
					}
				}
			}
		}

		&.u-full-width {
			&,
			table,
			&.u-narrow-width {
				min-width: auto;
				width: 100%;
			}
		}

		&.u-narrow-width {
			min-width: auto;
			width: 100%;

			@include media(sm) {
				width: 650px;
			}

			table {
				min-width: auto;
				width: 100%;
			}
		}
	}

	table {
		margin: 2em auto 0;
		text-align: left;

		th {
			box-sizing: border-box;
			padding: 20px 30px 20px 30px;
			border: 2px solid var(--color-gray-4);
			font-size: var(--label-font-size);
			line-height: var(--label-line-height);
			background-color: var(--color-gray-4);
			border-right-color: var(--color-white);
			border-bottom-color: var(--color-white);
			font-weight: var(--font-weight-bold);

			&:last-child {
				border-right-color: var(--color-gray-4);
			}
		}


		tr {
			td {
				box-sizing: border-box;
				padding: 20px 30px 20px 30px;
				border: 2px solid var(--color-gray-4);
				font-size: var(--label-font-size);
				line-height: var(--label-line-height);

				p {
					margin: 0;
					padding: 0;
					font-size: var(--label-font-size);
					line-height: var(--label-line-height);

					+ p {
						margin-top: 16px;
					}
				}

				&:first-child {
					background-color: var(--color-gray-4);
					border-bottom-color: var(--color-white);
					font-weight: var(--font-weight-bold);
				}
			}

			&:last-child {

				td {

					&:first-child {
						border-bottom-color: var(--color-gray-4);
					}
				}

			}
		}
	}
}
