.D13-hover-image {
    height: 300px;
    max-width: calc(var(--module-inner-width));
    min-width: 300px;
    margin: 50px auto;
    
    @include media(sm) {
        .simple-slider {
            overflow: visible;
        }
    }

    .nav-dots {
        bottom: -40px !important;
    }

    .D13-hover-image__inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 50px;
            position: relative;
            @include background-cover-image();
            flex: 1 auto;
            color: var(--color-white);

            & > * {
                margin-top: 16px;
                margin-bottom: 16px;
                text-align: center;
            }

            &__heading {
                z-index: 99;
                font-size: 24px;
                font-weight: var(--font-weight-medium);
            }

            &__desc {
                display: none;
                z-index: 99;
                font-size: 14px;
                line-height: 24px;
            }

            .ui-btn--secondary {
                display: none;
                color: var(--color-white);
            }

            @media(hover:hover){
                &:hover {
                    text-decoration: none;
                    .D13-hover-image__inner__item__desc {
                        display: block;
                    }
        
                    .ui-btn--secondary {
                        display: block;
                    }
                    
                    &::before {
                        background-color: rgba(0,0,0, .6);
                    }
                }
            }
            @media(hover:none){
                text-decoration: none;
                .D13-hover-image__inner__item__desc {
                    display: block;
                }
        
                .ui-btn--secondary {
                    display: block;
                }
                    
                &::before {
                    background-color: rgba(0,0,0, .6);
                }
            }


            &::before {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0, .2);
            }
        }
    }

    @include media($bp-mobile) {
        height: 360px;
    }

    @include media($bp-tablet) {
        height: 600px;
    }
}
