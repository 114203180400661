.D7-authors-block {
	&__inner {
		max-width: 544px;
		margin: 0 auto;
		h3 {
			font-size: var(--subhead-font-size);
			line-height: 1.33;
			font-weight: var(--font-weight-regular);
			margin: 0 0 16px 0;
			padding: 0;
		}
	}
	&__card {
		&__header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: var(--label-font-size);
			line-height: normal;
			height: 56px;

			&__name {
				padding-right: 15px;
				width: 80px;

				@include media(sm) {
					width: auto;
					min-width: 180px;
				}
			}

			&__indicator {
				margin-left: auto;
				width: 56px;
				flex: 0 0 56px;

				.icon {
					text-align: center;
					font-size: var(--subhead-font-size);
					font-weight: var(--font-weight-regular);
					line-height: normal;
					color: var(--color-nov-red);
				}
			}
		}
		&__header__title, &__content {
			color: var(--color-gray-1);
		}
		&__content {
			display: flex;
			img {
				width: 96px;
				height: auto;
				margin-right: 1em;
				margin-top: 0.5em;
			}
			p {
				display: block;
				font-size: var(--body-font-size-small);
				line-height: var(--body-line-height-small);
				padding-bottom: 1em;
			}
		}
		border-bottom: 1px solid var(--color-gray-3);
		&:first-child {
			border-top: 1px solid var(--color-gray-3);
		}
		@include media($bp-tablet) {
			&__header {
				font-size: var(--body-font-size);
			}
		}
	}
}
