.I8-form {
    .container {
        margin: 0 auto;
		padding: 15px 0 30px;

        width: 100%;
        max-width: 680px;

        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.08);

        @include media(sm) {
            display: flex;
            flex-direction: row-reverse;
        }

        @include media(md) {
            max-width: 1120px;
        }
	}

}
