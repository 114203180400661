.D6-article-image {
    &__inner {
        max-width: 800px;
        margin: 0 auto;
        position: relative;
    }
    &__imageblock {
        margin-bottom: 20px;
        width: 100%;
        height: auto;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    &__caption {
        margin: 0 23px;
        p, &__credit {
            color: var(--color-gray-1);
            font-size: var(--label-font-size);
            line-height: var(--label-line-height);
            margin-bottom: 0.5em;
        }
        &__credit {
            color: var(--color-font-black);
        }
    }
    @include media(sm) {
        &__inner {
            margin-bottom: 1.5em;
        }
        &__imageblock {
            margin: 0 30px 0 30px;
            width: calc(100% - 60px);
        }
        &__caption {
            width: 199px;
            padding: 27px;
            padding-bottom: 0;
            margin: 0 30px;
            position: absolute;
            background: var(--color-white);
            right: 0;
            bottom: -1.5em;
        }
    }
    @include media(md) {
        &__imageblock {
            margin: 0;
            width: 100%;
        }
        &__caption {
            width: 225px;
            margin: 0;
            padding: 31px;
            padding-bottom: 0;
        }
    }

    .D5-article-text + & {
        padding-top: 50px;
    }
}