.D14-sidebar-section {
    > .container {
        position: relative;

        @include media(sm) {
            display: flex;
        }
    }

    &__placeholder {
        width: 100%;
        flex-grow: 1;
    }

    &__inner {
		margin: 30px auto 0;
		padding: 48px 16px 32px;

        width: 100%;
        background-color: var(--color-gray-4);

        @include media(sm) {
            margin: 0 -25px 0 auto;
			padding: 49px;
			width: 350px;

            flex: 0 0 350px;
            align-self: flex-start;
		}

        @include media(lg) {
            width: 450px;
            flex: 0 0 450px;
        }

        h3, p, li {
            margin: 0px;
            padding: 8px;
        }
        h3 {
            font-size: var(--subhead-font-size);
            line-height: 1.67;
        }
        p {
            font-size: var(--body-font-size-small);
            line-height: var(--body-line-height-small);
            padding-bottom: 24px;
        }
        ul {
            margin-bottom: 24px;
        }
        li {
            border-bottom: 1px solid var(--color-gray-3);
            padding-top: 19px;
            padding-bottom: 19px;
            a {
                display: block;
                width: 100%;
                font-size: var(--body-font-size);
                line-height: 1.5;
                font-weight: var(--font-weight-medium);
                color: var(--color-black);

                @include clearfix;
                span {
                    display: block;
                    float: left;
                    width: calc(100% - 20px);
                }
                .icon {
                    display: block;
                    float: right;
                    position: relative;
                    top: 4px;
                    width: 15px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
        li:first-child {
            border-top: 1px solid var(--color-gray-3);
        }
    }


    .D4-title-block {
        .container {
            padding-left: 0;
        }
    }

    .D6-article-image {
        @include media(sm) {
            padding-right: 25px;

            .D6-article-image__imageblock {
                width: 100%;
                margin: 0 0 20px 0;
            }
        }
	}

    .H2-social-share + .D6-article-image {
        margin-top: 70px;
    }
}
