/**
* UI Components index.
*/
@import 'ui/config';
@import 'ui/loader';
@import 'ui/button';
@import 'ui/filter';
@import 'ui/breadcrumb';
@import 'ui/dropdown';
@import 'ui/input';
@import 'ui/social';
@import 'ui/dots';
@import 'ui/checkbox';
@import 'ui/pager';
@import 'ui/tag';
@import 'ui/balloon';
@import 'ui/label';
@import 'ui/letter-masker';
@import 'ui/image-component';
@import 'ui/social-links';

.ui-wrapper {
	display: inline-flex;
	align-items: flex-start;

	.ui-btn+.ui-btn {
		margin-left: 20px;
	}

	&.vertical {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.ui-btn + .ui-btn {
			margin-top: 20px;
		}
	}
}
