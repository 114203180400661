/**
* UI filter/tab
*/

//local vars
$ui-filter-text-color: rgb(33, 33, 33);
$ui-filter-text-color-active: rgba(255, 255, 255, 1);

.ui-filter {
	position: relative;
	text-align: center;
	padding: 0 15px;
	ul,
	li {
		margin: 0;
		padding: 0;
		list-style: none;
	} //when items length exceeds so use arrow UI
	&.show-arrow-ui {
		.ui-filter-arrow {
			display: block;
		}
		.ui-filter-masker {
			display: block;
		} //this can alter "offset parent"
		.ui-filter-list {
			transform: translate3d(0, 0, 0);
			will-change: transform;
			transition: transform 800ms;
		}
		.ui-filter-item {
			&:hover,
			&.active {
				&:after {
					opacity: 1;
				}
			}
		}
	}
	&.reached-first {
		.ui-filter-arrow[data-type="left"] {
			pointer-events: none;
			opacity: .25;
		}
	}
	&.reached-last {
		.ui-filter-arrow[data-type="right"] {
			pointer-events: none;
			opacity: .25;
		}
	}
	// when items are used as a link
	// &.use-link {}
}

.ui-filter-masker {
	position: relative;
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

.ui-filter-list {
	display: inline-flex;
	flex-wrap: nowrap;
	li {
		flex: 0 0 auto;
		&+li {
			margin-left: 60px;
		}
	}
}

.ui-filter-item {
	display: inline-block;
	font-family: var(--ui-font-family);
	font-size: 15px;
	padding: 15px 25px;
	font-weight: 600;
	border-radius: 30px;
	min-width: 100px;
	color: $ui-filter-text-color;
	position: relative;
	will-change: color;
	transition: color var(--ui-transition-dur), background-color var(--ui-transition-dur);
	text-decoration: none;

	.ui-filter.use-link &:hover {
		text-decoration: none;
		color: $ui-filter-text-color-active;
		background-color: var(--color-global-focus-color);
	}

	&:hover {
		background-color: rgb(226, 223, 218); //fc1921
		text-decoration: none;
	}

	&:focus,
	&.active {
		color: var(--color-white, #fff);
		background-color: var(--color-global-focus-color);
		text-decoration: none;
		outline:none;
	}

	&.active {
		pointer-events: none;
	}

}

.ui-filter-arrow {
	position: absolute;
	padding: 5px;
	color: rgba(33, 33, 33, 1);
	display: none;
	top: 50%;
	transform: translateY(-50%); //this can vary per design
	i {
		pointer-events: none;
		display: inline-block;
		color: inherit;
		font-size: 24px;
	}
	&[data-type="left"] {
		left: -15px;
	}
	&[data-type="right"] {
		right: -15px;
	}
}

@media screen and (max-width: 768px) {
	.ui-filter.show-arrow-ui,
	.ui-filter {
		.ui-filter-masker {
			overflow-x: auto;
			justify-content: flex-start;
		}
	}
	.ui-filter-list {
		li+li {
			margin-left: 15px;
		}
	}
}

//in mobile/tablet
@media screen and (max-width: 1024px) {
	.ui-filter.show-arrow-ui,
	.ui-filter {
		// .ui-filter-arrow {
		//   display: none;
		// }
		.ui-filter-masker {
			overflow-x: auto;
		}
		.ui-filter-list {
			transform: none !important;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.ui-filter {
		.ui-filter-arrow[data-type="left"],
		.ui-filter-arrow[data-type="right"] {
			opacity: .25; // just visually indicative
			pointer-events: none; // none clikable
		}
	}
}
