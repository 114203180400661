.J3-product-list {
    &__header {
        display: flex;

        p {
            font-size: var(--label-font-size);
            line-height: var(--label-line-height);

            @include media($bp-desktop-small) {
                font-size: var(--body-font-size-small);
                line-height: var(--body-line-height-small);
            }
        }

        &__text {
            flex-grow: 1;
            padding-bottom: 0.5em;
            padding: 0 2em;
        }

        &__video {
            @include background-cover-image();
            display: none;
            max-width: 528px;
            margin-bottom: 0;
            padding: 0;

            &::before {
                content: "";
                display: block;
                padding-top: 74.24%;
            }

            @include media($bp-desktop-small) {
                display: inline-block;
            }
        }
    }

    &__list {
        margin-top: 1.5em;
        padding-left: 2em;
        padding-bottom: 2em;

        li {
			display: inline-block;
            padding: 1em 0;
			width: 100%;

            font-size: var(--body-font-size-small);
            line-height: var(--body-line-height-small);

            a {
                color: var(--color-black);

                &:hover {
                    color: var(--color-nov-red);
                }
            }
        }

        @include media($bp-tablet) {
            column-count: 3;
            column-gap: 5em;
        }
    }
}
