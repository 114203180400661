.G2-history-timeline {
	position: relative;
	display: flex;
	min-height: 100vh;

	&__container {
		z-index: 1;
		position: relative;

		@include media($bp-tablet + 2) {
			position: relative;
			display: block;
		}

		&.container {
			padding: 0;

			@include media($bp-tablet + 2) {
				padding: 0 25px;
			}
		}
	}

	.G2-history-timeline__container {
		display: flex;
	}

	&.init {
		.G2-history-timeline__card,
		.G2-history-timeline__face {
			position: absolute;
		}

		.G2-history-timeline__face {
			display: block;
		}

		.G2-history-timeline__copy {
			@include media($bp-tablet + 2) {
				overflow-y: auto;
				overflow-x: hidden;
				@include webkit-custom-scrollbar(4, 0, 0, var(--color-nov-red), var(--color-gray-3), 0);
			}
		}

		.G2-history-timeline__cards.utility__disable-scroll {
			.G2-history-timeline__copy {
				overflow-y: hidden;
			}
		}
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		flex-wrap: wrap;
		padding: 0;
		flex: 1;

		@include media($bp-tablet + 2) {
			padding: 120px 0;
			flex-wrap: nowrap;
			align-items: center;
		}
	}

	&__chrono {
		position: absolute;
		display: flex;
		flex-direction: row;
		align-items: center;
		top: calc(50% - (224px / 2));
		padding: 0;
		margin-top: 0;
		z-index: 0;
		transform: translate(0, -50%);

		@include media($bp-tablet + 2) {
			position: relative;
			top: auto;
			transform: translate(0, 0);
		}
	}

	&__chrono-list {
		color: var(--color-white);
		padding-right: 96px;
		padding-left: 32px;

		@include media($bp-tablet + 2) {
			padding-left: 0;
		}
	}

	&__unordered-list {
		min-width: 130px;
	}

	&__item {
		font-size: 16px;
		line-height: 1.5;

		&:not(:last-child) {
			margin-bottom: 0;

			@include media($bp-mobile) {
				margin-bottom: 8px;
			}
		}

		&.action {
			&__active {
				font-weight: bold;

				.G2-history-timeline__decade {

					&::before {
						background-color: var(--color-nov-red);
						transform: translateY(-50%) scale(1.5);
					}

					&-label {
						opacity: 1;
					}
				}

			}
		}

		.G2-history-timeline {
			&__decade {
				position: relative;
				color: var(--color-white);
				padding-left: 24px;

				&:hover {
					text-decoration: none;
				}

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					display: block;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-color: var(--color-white);
					transform: translateY(-50%) scale(1);
					margin-top: 1px;
				}

				&-label {
					opacity: 0;
					transition: opacity .3s;

					@include media($bp-mobile) {
						opacity: 1;
					}
				}
			}
		}
	}

	&__year {
		display: none;
		font-size: 136px;
		color: var(--color-white);
		font-weight: var(--font-weight-black);
		padding-right: 25px;

		@include media($bp-mobile) {
			display: block;
		}

		@include media($bp-tablet + 2) {
			padding-right: 96px;
		}
	}

	&__console-anchor {
		position: relative;
		height: auto;
		width: 100%;
		z-index: 2;

		@include media($bp-tablet + 2) {
			flex: 0 0 352px;
			width: auto;
			height: 376px;
		}
	}

	&__console {
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: var(--color-white);
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
		overflow: hidden;
		min-height: 224px;
		transition: transform 1s;

		@include media($bp-tablet + 2) {
			position: absolute;
			left: 0;
			bottom: 0;
			min-height: 376px;
		}
	}

	&__expand {

		&-copy {
			text-align: center;
			padding: 0 32px 24px;

			.G2-history-timeline__expand-button:hover {
				text-decoration: none;
			}
		}

		&-button {
			position: relative;
			display: none;
			margin-top: 8px;
			width: 50px;
			height: 32px;
			transform: rotate(180deg);

			@include media($bp-tablet + 2) {
				display: none;
			}

			i {
				display: block;
				width: 100%;
				height: 100%;
				font-size: 30px;
				color: #979797;

			}

			&.action {
				&__expand {
					transform: rotate(0deg);
				}
			}

			&.utility {
				&__visible {
					display: inline-block;

					@include media($bp-tablet + 2) {
						display: none;
					}
				}
			}
		}
	}

	&__cards {
		position: relative;
		width: 100%;
		transition: height 1s;
		z-index: 0;
		
		&.utility {
			&__show-scroll {
				.G2-history-timeline__face.action__expand .G2-history-timeline__copy {
					overflow-y: auto;
					overflow-x: hidden;
					@include webkit-custom-scrollbar(4, 0, 0, var(--color-nov-red), var(--color-gray-3), 0);
				}
			}

			&__disable-scroll {
				.G2-history-timeline__face.action__expand .G2-history-timeline__copy {
					overflow-y: hidden;
				}
			}
		}

	}

	&__card {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: none;

		&.action {
			&__active {
				display: block;
			}
		}
	}

	&__face {
		display: none;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		transition: opacity 1s;
		z-index: 0;

		&.action {
			&__active {
				opacity: 1;
				z-index: 1;
				display: block;
			}

			&__expand {
				.G2-history-timeline__copy {
					max-height: none;

					&-wrapper {
						& > .G2-history-timeline__title:first-child,
						& > .G2-history-timeline__detail:first-child {
							height: auto;
							display: block;
						}
					}
				}
			}
		}

		.G2-history-timeline {
			&__wrapper {
				padding: 0 32px;
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			&__cta {
				&:hover {
					text-decoration: none;

					&::before {
						width: 100%;
					}
				}
			}
		}
	}

	&__sub-year {
		color: var(--color-nov-red);
		font-size: 14px;
		line-height: 1.71;
		margin-bottom: 16px;
	}

	&__copy {
		padding-right: 16px;
		margin-right: -27px;
		padding: 0;
		max-height: 50px;
		overflow: hidden;
		height: 100%;

		@include media($bp-mobile) {
			margin-right: -16px;
		}

		@include media($bp-tablet + 2) {
			max-height: none;
		}

		&-wrapper {
			padding-right: 16px;

			& > .G2-history-timeline__title:first-child {
				@include multiline-ellipsis(2, 24px, true);
				display: block;

				@include media($bp-tablet + 2) {
					height: auto;
				}
			}

			& > .G2-history-timeline__detail:first-child {
				@include multiline-ellipsis(2, 16px, true);
				display: block;

				@include media($bp-tablet + 2) {
					height: auto;
				}
			}
		}

		.G2-history-timeline {
			&__inner {
				display: block;
			}
		}
	}

	&__title {
		font-weight: bold;
		font-size: 16px;
		line-height: 1.5;

		& + * {
			margin-top: 16px;
		}
	}

	&__detail,
	&__detail p {
		font-size: 14px;
		line-height: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__title,
	&__detail {
		& + .G2-history-timeline__cta {
			padding-top: 16px;
		}
	}

	&__cta {
		font-weight: var(--font-weight-medium);
		margin-bottom: 5px;
	}

	&__bullets {
		position: relative;
		padding: 24px 32px;
		text-align: center;
		margin-top: auto;
		z-index: 1;
		background-color: var(--color-white);
	}

	&__bullet {
		width: 8px;
		height: 8px;
		background-color: var(--color-gray-2);
		border-radius: 50%;
		margin: 0 8px;
		transform: scale(1);
		display: inline-block;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&.action {
			&__active {
				transform: scale(1.5);
				background-color: var(--color-nov-red);
			}
		}

		&.utility {
			&__not-visible {
				visibility: hidden;
			}
		}
	}

	&__controls {
		position: relative;
		display: flex;
		flex-direction: row;
		background-color: var(--color-gray-3);
		z-index: 1;

		.icon {
			font-size: 30px;
			color: var(--color-nov-red);
		}

		.G2-history-timeline {
			&__prev,
			&__next {
				&:hover {
					text-decoration: none;
				}
			}

			&__text {
				color: var(--color-black);
				display: block;
				width: 100%;
			}
		}
	}

	&__prev,
	&__next {
		position: relative;
		padding-top: 8px;
		padding-bottom: 8px;
		width: 50%;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:hover {
			background-color: var(--color-gray-6);
		}

		&.action {
			&__disabled {
				cursor: default;

				.icon {
					color: #d9d9d9;
				}

				&:hover {
					background-color: transparent;
				}
			}
		}
	}

	&__prev {
		padding-left: 48px;
		padding-right: 24px;

		&::after {
			content: '';
			position: absolute;
			top: 5px;
			bottom: 5px;
			right: 0;
			width: 1px;
			background-color: var(--color-gray-2);
		}

		.G2-history-timeline {
			&__text {
				text-align: left;

				@include media($bp-mobile) {
					text-align: center;
				}

				@include media($bp-tablet + 2) {
					text-align: left;
				}
			}
		}
	}

	&__next {
		padding-right: 48px;
		padding-left: 24px;

		.G2-history-timeline {
			&__text {
				text-align: right;

				@include media($bp-mobile) {
					text-align: center;
				}

				@include media($bp-tablet + 2) {
					text-align: right;
				}
			}
		}
	}

	&__background-touch {
		display: none;
	}

	&__backgrounds {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 224px;
		left: 0;
		z-index: 0;
		overflow: hidden;
		background-color: var(--color-gray-2);

		@include media($bp-tablet + 2) {
			bottom: 0;
		}

		&.utility {
			&__forward {
				.G2-history-timeline__canvas {
					transition-delay: 1s;

					&.utility__delay {
						transition-delay: 0s;
					}
				}
			}

			&__backward {
				.G2-history-timeline__canvas {
					transition: transform 0s;

					&.utility__delay {
						transition: transform 1s;
					}
				}
			}
		}

	}

	&__canvas {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		box-shadow: -10px 0 30px -10px rgba(0, 0, 0, 0.5);
		transform: translateX(calc(100vw + 20px));
		transition: transform 1s;

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 3;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
		}

		&.action {
			&__active {
				transform: translateX(0);
			}
		}

		&.animate {
			&__bottom {
				z-index: 0;
				box-shadow: 0 -10px 30px -10px rgba(0, 0, 0, 0.5);
				transform: translate(0, calc(100vh + 20px));

				&.action__active {
					transform: translate(0, 0);
				}

				&.utility__top {
					z-index: 3;
				}
			}
		}

		&.utility {
			&__top {
				z-index: 3;
			}

			&__full {
				.G2-history-timeline__image {
					&.utility__left {
						right: 0;
					}
				}
			}
		}
	}

	&__image {
		position: absolute;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		&.utility {
			&__left {

				@include media(1200) {
					right: 36%;
				}

				
			}

			&__right {
				left: 64%;
				z-index: 0;
			}
		}
	}
}

// MOBILE & TABLET HACKS
body.is-mobile .G2-history-timeline__background-touch {
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 50%;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0);
	width: 100vw;
	transform: translateX(-50%);
}

body.is-mobile .G2-history-timeline__bullet {
	pointer-events: none
}

// IE11 HACKS
_:-ms-input-placeholder, :root .G2-history-timeline__container {
	min-height: 100vh;
}

_:-ms-input-placeholder, :root .G2-history-timeline__console {
	min-height: auto;
}

_:-ms-input-placeholder, :root .G2-history-timeline__canvas {
	transform: translate(105%, 0);

	&.action {
		&__active {
			transform: translate(0, 0);
		}
	}

	&.animate {
		&__bottom {
			transform: translate(0, 105%);

			&.action__active {
				transform: translate(0, 0);
			}
		}
	}
}


