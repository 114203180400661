.D3-content-block {
	margin-top: 30px;

	@include media(sm) {
		margin-top: 0;
	}

	&__inner {
		display: flex;
		flex-direction: column;

		&.is-reversed {
			@include media(sm) {
				flex-direction: row-reverse;

				.D3-content-block__inner__figure {
					&__color-block{
						margin: 0;
						float: right;
						.figure-image-wrapper {
							padding: 0;
						}
						&__image {
							right: auto;
							top: 0;
							left: -64px;
						}
					}
				}

				.D3-content-block__inner__texts {
					padding: 48px 100px 48px 0;

				}
			}

			@include media(md) {
				.D3-content-block__inner__texts {
					padding: 48px 100px 48px 0;
				}
			}
		}

		@include media(sm) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

		}

		&__figure {
			position: relative;
			&__color-block {
				margin: -32px -25px 112px -25px;
				padding-bottom: 100%;
				height: 0;
				background-color: var(--color-gray-3);

				.figure-image-wrapper {
					padding: 64px 32px 32px 32px;
				}

				&__image {
					height: 312px;

					> img {
						display: block;
						width: 100%;
						height: auto;
					}
				}

				&.is-red {
					background-color: var(--color-nov-red);
				}

				@include media(sm) {
					margin: 0;
					padding-bottom: 0;
					position: relative;
					width: 264px;
					height: 328px;

					&__image {
						width: 385px;
						height: 385px;
						position: absolute;
						top: 0;
						bottom: 0;
						right: -64px;
						margin: auto;
						width: 264px;
						height: 264px;
					}
				}

				@include media(md) {
					width: 480px;
					height: 560px;
					&__image {
						width: 385px;
						height: 385px;
					}
				}
			}

		}

		&__texts {
			
			&__inner {
				&__heading {
					font-size: 24px;
					margin-top: 0;
					margin-bottom: 14px;
				}

				&__desc {
					color: var(--color-gray-1);
				}

				.ui-btn--secondary {
					margin-top: 16px;
				}

				&__name {
					font-weight: var(--font-weight-bold);
					margin-bottom: 8px;
				}

				.label {
					margin-bottom: 24px;
				}
			}

			@include media(sm) {
				display: flex;
				padding: 48px 0 48px 100px;

				&__inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 100%;
				}
			}

			@include media(md) {
				padding: 48px 0 48px 100px;

				&__inner {
					&__heading {
						font-size: 32px;
						line-height: 40px;
					}
				}
			}
		}
	}
}
