:root {
    --E5-document-list-item-height: 104px;
}
.E5-document-list {
    max-width: 768px;
    margin: 0 auto;

    &__list {
        &__item {
            position: relative;
            width: 100%;
            height: var(--E5-document-list-item-height);
            border: 1px solid var(--color-gray-3);
			margin: 0 0 25px 0;

			display: flex;


            @include clearfix;

            &:last-child {
                margin: 0;
            }

            strong {
				display: block;
				margin: 0;
				width: 100%;

				max-height: 4.29em;
				overflow: hidden;

                font-size: var(--body-font-size-small);
                line-height: 1.43;
                font-weight: var(--font-weight-medium);
                color: var(--color-black);
            }

            p {
                margin: 0;
                font-size: var(--label-font-size);
                line-height: 2;
				color: var(--color-gray-1);

				display: none;

				@include media(sm) {
					display: block;
				}
            }

            &__description {
                padding: 17px;
				display: flex;
				flex-flow: row wrap;
				align-items: center;
                width: calc(100% - var(--E5-document-list-item-height));

                @include media ($bp-tablet) {
                    padding: 25px;
                }
            }

            &__link {
                position: relative;
                display: block;
                float: right;
                background-color: var(--color-gray-4);
                height: 100%;
                width: var(--E5-document-list-item-height);
                padding: 0;

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: var(--color-nov-red);
                    transform: translate(-50%, -50%);
                }

                &:hover, &:active {
                    background-color: var(--color-nov-red);
                    .icon {
                        color: var(--color-white);
                    }
                }
            }
        }
    }
}
