.D12-video-player {
	&__inner {
		max-width: 928px;
		margin: 0 auto;
	}

	&__player {
		position: relative;
		display: block;
		height: 0;
		padding: 0;
		overflow: hidden;
		padding-bottom: 56.25%;

		iframe, &__thumbnail {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			border: 0;
			@include background-cover-image();
		}

		&__thumbnail {
			background-color: var(--color-gray-5); // Removed sitecore poster, grey block will show till iframe loads
		}

		&__button {
			position: absolute;
			bottom: 14px;
			left: 14px;
		}
	}
	@include media ($bp-tablet) {
		&__inner {
			padding: 0 44px;
		}
	}
	@include media ($bp-desktop-small) {
		&__player__button {
			bottom: 20px;
			left: 20px;
			button {
				width: 39px;
				height: 39px;
				i {
					font-size: 20px;
				}
			}
		}
	}
}

.D12-video-player--slider {
	padding-bottom: 50px;
	overflow: hidden;

	.experienceeditor & {
		height: auto !important;
	}

	&,
	.D12-video-player__inner {
		height: 300px;

		@include media(376px) {
			height: 330px;
		}

		@include media(435px) {
			height: 370px;
		}

		@include media(510px) {
			height: 400px;
		}

		@include media(570px) {
			height: 470px;
		}

		@include media(700px) {
			height: 530px;
		}

		@include media(sm) {
			height: 580px;
		}

		@include media(840px) {
			height: 630px;
		}
	}

	.simple-slider {
		width: 100vw;
		overflow: visible;

		.nav-dots {
			bottom: -25px;
			pointer-events: none;

			.nav-dot {
				pointer-events: all;
			}
		}
	}

	.nav-buttons {
		display: none;
		position: relative;
		height: 100%;
		pointer-events: none;

		@extend .container;

		@include media(md) {
			display: block
		}

		.slide-nav-btn {
			color: var(--color-nov-red);
			pointer-events: all;
		}
	}
}
