.js-accordion {
    .js-accordion-item {
        .js-accordion-item-content {
            overflow: hidden;
            max-height: 0px;
            transition: max-height var(--ui-transition-dur) var(--ui-easing);
        }
        .js-accordion-item-indicator .when-open {
            display: none;
        }
        .js-accordion-item-indicator .when-closed {
            display: inherit;
		}
		.js-accordion-item-trigger {
			cursor: pointer;
		}
        &.is-open {
            .js-accordion-item-content {
                max-height: 100%;
            }
            .js-accordion-item-indicator .when-open {
                display: inherit;
            }
            .js-accordion-item-indicator .when-closed {
                display: none;
            }
        }
    }
}
@include media($bp-mobile-sm) {
    .js-accordion[data-bp-mobile-sm="true"] {
        .js-accordion-item-content {
            max-height: 100%;
        }
        .js-accordion-item-indicator {
            display: none;
        }
    }
}
@include media($bp-mobile) {
    .js-accordion[data-bp-mobile="true"] {
        .js-accordion-item-content {
            max-height: 100%;
        }
        .js-accordion-item-indicator {
            display: none;
        }
    }
}
@include media($bp-tablet) {
    .js-accordion[data-bp-tablet="true"] {
        .js-accordion-item-content {
            max-height: 100%;
        }
        .js-accordion-item-indicator {
            display: none;
        }
    }
}
@include media($bp-desktop-small) {
    .js-accordion[data-bp-desktop-small="true"] {
        .js-accordion-item-content {
            max-height: 100%;
        }
        .js-accordion-item-indicator {
            display: none;
        }
    }
}
@include media($bp-desktop-medium) {
    .js-accordion[data-bp-desktop-medium="true"] {
        .js-accordion-item-content {
            max-height: 100%;
        }
        .js-accordion-item-indicator {
            display: none;
        }
    }
}
@include media($bp-desktop-large) {
    .js-accordion[data-bp-desktop-large="true"] {
        .js-accordion-item-content {
            max-height: 100%;
        }
        .js-accordion-item-indicator {
            display: none;
        }
    }
}
@include media($bp-desktop-xlarge) {
    .js-accordion[data-bp-desktop-xlarge="true"] {
        .js-accordion-item-content {
            max-height: 100%;
        }
        .js-accordion-item-indicator {
            display: none;
        }
    }
}
