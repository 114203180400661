.eloqua-form {
	overflow: hidden;

    .elq-form {
        font-size: 16px;

        .hidden{
            display:none;
        }
        .elq-heading{
            margin-left:10px;
        }
        .elq-item-textarea{
            resize:none;
        }

        .section-break .heading {
            margin-left: 0.6%;
        }

		.item-padding {
			padding: 6px 5px 9px;
        }

        .field-style {
			position: relative;
            margin-top: 2em;

            &.dynamic-label {
                margin-top: 1em;
			}

			&.hidden {
				position: absolute;
				left: -5000px;
				height: 0;
				overflow: hidden;
			}

			&._50 {
				width: 96%;

				@include media(600) {
					width: 46%;
				}
			}
        }

		.field-p {
			line-height: 24px;
		}

        input:focus, textarea:focus {
            outline: 2px dotted var(--color-gray-0);
        }

        label, input[type=button], input[type=submit], input[type=reset] {
            color: var(--color-gray-1);
        }

        label {
            cursor: text;
            z-index:1;
        }

        .dynamic-label label {
            position: relative;
            margin-bottom: -6px;
            top: 1.5em;

            &.focused {
                top: 0;
                margin-bottom: 0;
                font-size: var(--label-font-size);
            }
        }

        .dynamic-label.animated label {
            transition: font-size var(--ui-transition-dur) var(--ui-easing), top var(--ui-transition-dur) var(--ui-easing);
        }

        input[type=text] {
            border: 0;
            border-bottom: 1px solid var(--color-gray-1);
        }

        input[type=text] {
			box-sizing: border-box;
        }

        textarea {
			box-sizing: border-box;
            border: 1px solid var(--color-gray-6);
            height: 6em;
        }

        input[type=text] {
            height: 2em;
        }

		.select-label {
            position: relative;

            &::before {
                font-family: 'icomoon';
                text-transform: none;
                line-height: 1;
                speak: none;
                position: absolute;
                content: "\e906";
                bottom: 20px;
                right: 35px;
                color: var(--color-nov-red);
			}
		}

        .select-wrapper {
			position: relative;
			display: inline-block;
			width: 100%;
            overflow: hidden;
            line-height: 32px;
            border-bottom: 1px solid var(--color-gray-1);

            &.focused {
                outline: 2px dotted var(--color-gray-0);
            }
        }

        select {
            color: var(--color-black);
            background: transparent;
            // border-bottom: 1px solid var(--color-gray-1);
            width: calc(100% + 25px);
			line-height: 32px;
            height: 32px;
            border: none;
            -webkit-appearance: none;

            &:focus {
                outline: none;
            }
        }

        .checkbox-wrapper {
            display: inline-block;
            cursor: pointer;
            position: relative;
            user-select: none;
            height: 1em;
            width: 1em;
            text-indent: 0;

            input[type=checkbox] {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;

                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border: 2px solid var(--color-nov-red);
                border-radius: 20%;

                &::after {
                    font-family: 'icomoon';
                    content: '';
                    position: absolute;
                    color: var(--color-white);
                    font-weight: var(--font-weight-medium);
                    display: none;
                    top: -0.64em;
                    left: -10%;
                }
            }

            input[type=checkbox]:focus ~ .checkmark {
                outline: 2px dotted var(--color-gray-0);
            }

            input[type=checkbox]:checked ~ .checkmark {
                background-color: var(--color-nov-red);

                &::after {
                    content: '\e912';
                    display: block;
                }
            }
        }

        label .checkbox-wrapper {
            top: 0.25em;
            margin-right: 0.25em;
        }

        .radio-wrapper {
            display: inline-block;
            cursor: pointer;
            position: relative;
            user-select: none;
            height: 1em;
            width: 1em;
            text-indent: 0;

            input[type=radio] {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;

                cursor: pointer;

                &:focus {
                    outline: none;
                }
            }

            .radiomark {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border: 2px solid var(--color-nov-red);
                border-radius: 100%;

                &::after {
                    position: absolute;
                    content: '';
                    display: block;
                    background-color: var(--color-white);
                    width: 50%;
                    height: 50%;
                    top: 25%;
                    left: 25%;
                    border-radius: 100%;
                }
            }

            input[type=radio]:focus ~ .radiomark {
                outline: 2px dotted var(--color-gray-0);
            }

            input[type=radio]:checked ~ .radiomark {
                background-color: var(--color-nov-red);
            }
        }

        label .radio-wrapper {
            top: 0.25em;
            margin-right: 0.25em;
        }

        input[type=button], input[type=submit], input[type=reset] {
            background: var(--color-nov-red);
            color: var(--color-white);
            text-decoration: none;
            border: none;
            line-height: 1;
            padding: 0.75em 1.5em;
            height: auto !important;
            width: auto !important;
            font-weight: bold;
            border-radius: 0;
            margin:25px 15px 0;
        }

        .LV_valid_field {
            outline: none;
            // border-color: lightgreen !important;
		}

		.LV_validation_message.LV_invalid {
			position: absolute;
			bottom: -15px;
			left: 10px;
		}

        .LV_invalid_field {
            outline: none;
            border-color: var(--color-nov-red) !important;
        }
    }
}
