.simple-slider {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	opacity: 1;

	.experienceeditor & {
		opacity: 1;
	}

	&.is-attached {
		.simple-slider-inner {
			height: 100%;
			width: 100%;
			position: relative;
			position: absolute;
			overflow: hidden;
		}

		.slide {
			position: absolute;
			width: 100%;
			height: 100%;
			@include background-cover-image();

			&.is-animating {
				transition: transform 300ms cubic-bezier(.25, .12, .65, .94);
			}
		}

		.nav-dots {
			z-index: 2;
			text-align: center;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 20px;
			.nav-dot {
				cursor: pointer;
				height: 12px;
				width: 12px;
				margin-right: 12px;
				background-color: #c5c5c5;
				border: 2px solid var(--color-white);
				border-radius: 50%;
				display: inline-block;
				transition: 0.3s ease;
			}

			.active, .nav-dot:hover {
				background-color: var(--color-white);
				border: 2px solid var(--color-nov-red);
			}
		}
	}

	&.simple-slider--align-left.is-attached .nav-dots {
		text-align: center;
		@include media($bp-desktop-small) {
			text-align: left;
		}
	}

	&.has-background-image {
		.nav-dots {
			.nav-dot {
				padding: 5px;
				border: 2px solid transparent;
				background-color: var(--color-white);
				background-clip: padding-box;
				margin: 0 5px;
			}
			.active, .nav-dot:hover {
				border-color: var(--color-nov-red);
				background-color: transparent;
			}
		}
	}


	&.simple-slider--text-black {
		.nav-buttons {
			.slide-nav-btn {
				color: var(--color-black);
			}
		}
	}


	.slide-nav-btn {
		z-index: 2;
		cursor: pointer;
		color: var(--color-white);
		font-size: 30px;
		position: absolute;
		top: calc(50% - 15px);

		&:first-child {
			left: 0;
			@include media($bp-tablet) {
				left: 8px;
			};
		}
		&:last-child {
			right: 0;
			@include media($bp-tablet) {
				right: 8px;
			};
		}
	}

	.page-numbers {
		z-index: 2;
		margin-top: 16px;
		text-align: center;
		position: absolute;
		top: 20px;
		left: 0;
		right: 0;
	}
}



// DUMMY ELEMENT.
// Could be .your-own-slider-class
// with you custom styling

.my-slider-dummy {
	margin: 100px 0;
	width: 100%;
	height: 400px;
	margin-left: auto;
	margin-right: auto;

	@include media($bp-tablet) {
		height: 450px;
		width: 450px;
	}

	@include media($bp-desktop-small) {
		height: 400px;
		width: 400px;
	}
}

.my-slider-dummy-fullscreen {
	margin: 100px 0;
	width: 100%;
	height: 650px;
}
