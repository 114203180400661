/**
* Dropdown
*/

.dropdown {

	&__button,
	&__list {
		display: none;
	}

	&.init {
		.dropdown__button {
			display: inline-block;
		}

		select {
			display: none;
		}
	}

	&.dropdown--hide-label {
		label {
			display: none;
		}
	}

	&.dropdown--active {
		.dropdown__list {
			display: block;
		}

		.dropdown__button {
			display: inline-block;

			border-bottom: 2px solid var(--color-nov-red);

			i {
				transform: rotate(180deg);
			}
		}
	}

	label {
		font-size: 14px;
	}

	.dropdown__button {
		border-bottom: 2px solid transparent;
		white-space: nowrap;

		i {
			position: relative;
			top: 3px;
		}
	}

	&__dropdown {
		display: inline-block;

		position: relative;
	}

	&__list {
		position: absolute;
		z-index: 2000;
		min-width:260px;

		box-shadow: 5px 5px 15px rgba(0,0,0,.2);
		background: var(--color-white);
	}

	&__item {
		display: block;

		&__button {
			@include nullify-webkit-button();

			display: block;
			width: 100%;
			padding: 7px 10px;

			text-align: left;

			&:focus,
			&:hover {
				color: var(--color-nov-red);
				background-color: var(--color-gray-6);
		}
		}
	}

	&__item--optgroup {
		.dropdown__item__button {
			padding-left: 25px;
		}
	}
}
