/**
* Test Page
* Use pages to override positional features of components on the page
*/
.test-page {

	.test-component {
		position: absolute;
		top: 0;
		left: 30px;
	}

}
