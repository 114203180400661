.search-promo-sidebar {
	width: 100%;

	.search-result--main + & {
		margin-top: 30px;
	}

	@include media(sm) {
		margin-top: 60px;
		order: 10;
		width: 292px;

		.search-result--main + & {
			margin-top: 60px;
		}
	}
}

.search-sidebar-box {

	+ .search-sidebar-box {
		margin-top: 30px;

		@include media(sm) {
			margin-top: 60px;
		}
	}

	.experienceeditor & {
		margin-bottom: 30px;

		@include media(sm) {
			margin-bottom: 60px;
		}
	}

    &, p {
        font-size: 16px;
        line-height: 1.71;
    }

    h4 {
        line-height: 1.25;
        margin: 0;
        margin-bottom: 0.25em;

        @include media(md) {
            font-size: 48px;
            line-height: 1.17;
        }
    }

    &__container {
        width: 100%;
        margin: 0 auto;
        box-shadow: 0 12px 11px -6px rgba(0, 0, 0, 0.21);

        @include media($bp-tablet) {
            box-shadow: -3px 5px 10px 0 rgba(0, 0, 0, 0.14);
        }
    }

    &__media {
        @include background-cover-image();
		height: auto;
        width: 100%;
        margin-bottom: 0;
        padding: 0;

        &::before {
            content: "";
            display: block;
            padding-top: 200px;
        }
    }

    &__content {
        padding: 3em;

        @include media(sm) {
            display: flex;
            flex-direction: column;
        }

        p {
            flex-grow: 1;
        }
    }

    &__links {
        column-count: 2;
        margin-top: 1.5em;

        li::before {
            position: relative;
            top: 0.1em;
            font-family: 'icomoon';
            content: "\e908";
            padding-right: 0.1em;
        }

        @include media(sm) {
            li {
                padding-bottom: 1em;
            }
        }

        @include media(md) {
            font-size: var(--label-font-size);
        }
    }
}
