.D11-table {

	.D4-title-block + & {
		padding-top: 0;
	}

    &__form {
        display: block;
        max-width: 734px;
        margin: 0 auto;
        margin-bottom: 46px;
        .ui-component {
            margin-right: 20px;
        }
    }
    &__inner {
        display: block;
        max-width: 734px;
        margin: 0 auto;
        width: calc(100% - 34px);
        overflow-x: auto;
        &::-webkit-scrollbar {
            width: 1em;
            height: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-nov-red);
            border-radius: 4px;
        }
    }
    &__table {
        width: 100%;
        background-color: transparent;
        border-collapse: collapse;
        th, td {
            padding: 20px 45px 20px 0;
            border-bottom: 1px solid var(--color-gray-6);
            white-space: nowrap;
            &:last-child {
                padding-right: 0;
            }
        }
        th {
            text-align: inherit;
            font-weight: var(--font-weight-medium);
        }
        thead {
            font-size: var(--body-font-size-small);
            line-height: var(--body-line-height-small);
            color: var(--color-black);
        }
        thead th {
            vertical-align: bottom;
            padding-top: 0;
            padding-bottom: 30px;
            border-bottom: 2px solid var(--color-gray-1);
        }
        tbody {
            font-size: var(--label-font-size);
            line-height: var(--label-line-height);
            color: var(--color-gray-1);
        }
        &__spacer {
            width: 99px;
            padding: 0;
        }
        &__link {
            color: inherit;
            margin-left:  30px;
            &:first-child {
                margin-left: 0;
            }
            i {
                font-size: 10px;
            }
        }
    }
    &__footer {
        display: block;
        max-width: 734px;
        margin: 0 auto;
        margin-top: 60px;
        text-align: center;
    }
}
