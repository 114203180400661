.E1-filtered-feed {
	.D4-title-block {
		@include media(md) {
			margin-bottom: 60px;
		}
	}

	&__cards {
		position: relative;
	}

	.js-masonry-grid-sizer {
		width: 100%;

		@include media(sm) {
			width: calc(50% - 16px);
		}

		@include media(md) {
			width: calc(33% - 16px);
		}
	}

	&__filter {
		position: sticky;
		top: 65px;

		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;

		z-index: 849;
		padding: 15px 25px;
		margin: 0 -25px 48px -25px;

		border-top: 1px solid var(--color-gray-3);
		border-bottom: 1px solid var(--color-gray-3);
		background: var(--color-white);

		@include media(md) {
			position: static;
			padding-top: 0;
			padding-bottom: 0;
			border: none;
			align-items: flex-end;
		}

		.no-filters & {
			display: none;
		}

		&.u-open {
			.E1-filtered-feed {
				&__filter-by {
					margin-bottom: 24px;

					.icon-chevron-down {
						transform: rotate(180deg);
					}

					@include media(md) {
						margin-bottom: 0;
					}
				}

				&__reset-filters {
					opacity: 1;
					transform: translateX(0);
				}
			}

			.dropdown {
				display: block;
			}
		}

		.dropdown {
			display: none;
			width: 100%;

			label {
				display: block;
				color: var(--color-gray-1);
				font-size: 13px;
			}

			&__button {
				font-weight: var(--font-weight-medium);
			}

			&:not(:last-child) {
				margin-bottom: 16px;
			}

			@include media(md) {
				display: block;
				width: auto;

				&:not(:last-child) {
					margin-bottom: 0;
				}
			}
		}


		.E1-filtered-feed__filter-by {
			position: relative;
			font-size: 14px;
			padding-right: 40px;
			color: #1b1b1b;
			margin-bottom: 0;
			font-weight: var(--font-weight-medium);

			&:hover {

				text-decoration: none;
			}

			@include media(md) {
				padding-right: 20px;
				cursor: text;
				font-size: 16px;
				font-weight: normal;
				color: var(--color-gray-1);
			}

			.icon-chevron-down {
				position: absolute;
				top: 50%;
				margin-top: -6px;
				margin-left: 6px;
				color: var(--color-nov-red);
				font-weight: var(--font-weight-medium);

				@include media(md) {
					display: none;
				}
			}
		}

		.E1-filtered-feed__filter-by,
		.E1-filtered-feed__reset-filters {
			padding-top: 5px;
			padding-bottom: 5px;
			line-height: normal;
			white-space: nowrap;


			border-bottom: 2px solid transparent;

			@include media(md) {
				margin: 0;
			}

		}

		.E1-filtered-feed__reset-filters {
			@include no-appearance();
			margin-bottom: 0;
			margin-right: auto;
			margin-top: 5px;
			font-size: 10px;
			padding-top: 5px;
			padding-bottom: 5px;
			text-align: right;
			color: var(--color-gray-1);
			opacity: 0;
			transform: translate(100vw);
			transition: opacity .3s, transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

			@include media(md) {
				order: 100;
				padding-left: 12px;
				transform: translateX(0);
				opacity: 1;
				font-size: 16px;
				margin: 0;
			}

			&:hover {
				color: var(--color-nov-red);
			}
		}
	}

	&__load-more {
		display: none;

		.has-more & {
			display: flex;
			justify-content: center;
		}
	}

	.C1-card {

		&__body {
			height: calc(var(--body-line-height-small) * 3);
		}
	}
}
