//Placeholder for now to test header
.B3-home-page-hero {
	position: relative;
	overflow: hidden;

	min-height: 100vh;
	background-color: var(--color-gray-2);
	height: auto;

	.experienceeditor & {

		&-inner {
			display: block;
		}
		&__item {
			margin-left: 0;
			left: 0;
			display: block;
			min-height: auto;
		}
	}

	&-inner {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		height: auto;
		min-height: 100vh;

	}

	&__item {
		@include background-cover-image();

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		height: auto;
		min-height: 100vh;
		flex: none;
		margin-left: -100%;
		width: 100%;
		position: relative;
		left: 50%;
		background-color: var(--color-gray-2);

		.container {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			height: auto;
			min-height: 100vh;
			align-items: flex-start;

			&::before,
			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 65px;
				background-color: transparent;
			}
			
			/*
			&::before {
				height: calc(65px + 62px);

				@include media(md) {
					height: calc(65px + 73px);
				}

				@include media(lg) {
					height: calc(65px + 80px);
				}
			}
			*/

			&::after {
				height: calc(50px + 100px);
	
				// @include media(sm) {
				// 	height: calc(65px + 200px);
				// }
			}

			.ui-btn--primary:hover::before {
				width: 100%;
			}
		}

		&__video {
			width: 100%;
			height: 100%;
			position: absolute;

			video {
				min-width:100%;
				min-height:100%;
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				pointer-events: none;
				width:auto;
				height:auto;
				transform:translate(50%,-50%);
				position:absolute;
				right:50%;
				top:50%;
			}
		}

		&__text-block {
			color: var(--color-white);

			& > * {
				z-index: 1;
				max-width: 100%;
			}
		}

	}

	&__gradient {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}

		&::before {
			height: 243px;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0));
			z-index: 2;
		}

		&::after {
			bottom: 0;
			background-image: linear-gradient(243deg, rgba(81, 81, 81, 0), rgba(40,40,40, 1));
			z-index: 1;	
			opacity: 0.4;
		}
	}


	&__timers {
		position: absolute;
		bottom: 150px;
		left: 0;
		right: 0;
		z-index: 775;

		display: flex;
		justify-content: space-between;
		width: 100%;

		color: var(--color-white);
		font-size: 12px;

		@media screen and (max-height: 600px) {
			display: none;
		}

		@include media(sm, true) {
			display: none;
		}

		@include media(md) {
			font-size: 14px;
			margin-top: -200px;
		}

		&__item {
			cursor: pointer;
			text-align: left;
			position: relative;
			width: 30%;

			@include media(sm) {
				width: 25%;
			}

			span {
				display: inline-block;
				margin-right: 10px;
				font-weight: var(--font-weight-bold);
			}

			&:before,
			&:after  {
				position: absolute;
				content: '';
				height: 2px;
				left: 0;
				right: 0;
				bottom: -20px;
				background: rgba(255, 255, 255, 0.4);
			}

			&:after {
				right: 100%;
				background: var(--color-nov-red);
				transition: none;
			}

			&.is-active {
				&:after {
					transition: 4.5s var(--base-animation-duration) linear;
					right: 0%;
				}
			}
		}
	}
}

@import 'action-box';
