
.header__navigation-container-mobile {

    visibility: visible;
    top: 65px;
    bottom: 0;
    position: fixed;
    background: white;
    left: 0;
    right: 0;

    &.is-hidden {
        visibility: hidden;
    }

}