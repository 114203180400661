.search-result--main {
	width: 100%;
}

.search-promo-full-width {
	width: 100%;

	&, p {
		font-size: 16px;
		line-height: 1.71;
	}

	h4 {
		line-height: 1.25;
		margin: 0;
		margin-bottom: 0.25em;

		@include media(md) {
			font-size: 48px;
			line-height: 1.17;
		}
	}

	&__container {
		width: 100%;
		margin: 0 auto;
		background: var(--color-white);
		box-shadow: 0 12px 11px -6px rgba(0, 0, 0, 0.21);

		@include media(sm) {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			box-shadow: -3px 5px 10px 0 rgba(0, 0, 0, 0.14);
		}
	}

	&__media {
		@include background-cover-image();
		height: auto;
		width: 100%;
		margin-bottom: 0;
		padding: 0;

		&::before {
			content: "";
			display: block;
			padding-top: 200px;
		}

		@include media(sm) {
			order: 2;
			width: 292px;
			flex: 0 0 292px;

			&::before {
				padding-top: 352px;
			}
		}

		@include media(md) {
			width: 385px;
			flex: 0 0 385px;

			&::before {
				padding-top: 367px;
			}
		}
	}

	&__content {
		padding: 3em;

		@include media(sm) {
			display: flex;
			flex-direction: column;
		}

		p {
			flex-grow: 1;
		}
	}

	&__links {
		// column-count: 2;
		margin-top: 1.5em;
		display: block;

		li {
			width: auto;
			padding-right: 0;

			&:not(:last-child) {
				padding-bottom: 1em;
			}
		}

		li::before {
			position: relative;
			top: 0.1em;
			font-family: 'icomoon';
			content: "\e908";
			padding-right: 0.1em;
		}

		@include media(xs) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			li {
				padding-right: 16px;;
				width: 50%;
			}
		}

		@include media(md) {
			font-size: var(--label-font-size);
		}
	}
}
