.I5-signup {

    .sidebar-container {
        margin: 0 auto;
		padding: 15px 0 30px;

		width: 100%;

        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.08);

        @include media(sm) {
			max-width: 319px;
        }

        @include media(md) {
			max-width: 288px;
        }
	}

	.elq-form {

		.list-order.twoColumn,
		.list-order.threeColumn {
			float: none;
			width: 100%;
			overflow: hidden;
		}
	}

	.I5-signup__link {
		display: block;
		margin-top: 25px;
		margin-left: 2%;
		padding-left: 9px;
	}
}
