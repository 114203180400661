.D2-stats {
    .D2-stats-inner {
        @include media($bp-tablet) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__item {
        flex: 1;
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 30px;

            @include media($bp-tablet) {
                margin-bottom: 0;
            }

        }

        & > *:first-child {
            margin-top: 0;
            padding-top: 0;
        }

        & > *:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &__number {
            font-weight: var(--font-weight-bold);
            font-size: 72px;
            line-height: 0.72;
            @include media($bp-desktop-small) {
                font-size: 88px;
                line-height: 0.82;
            }
        }

        &__description {
            position: relative;
            padding-top: 80px;
            margin-bottom: 0;
            color: var(--color-gray-1);

            &:before {
                position: absolute;
                content: '';
                background: var(--color-nov-red);
                height: 2px;
                width: 30px;
                margin-top: -40px;
                left: 50%;
                margin-left: -15px;
            }

            & + a {
                margin-top: 11px;
            }
        }
    }


}
