.B2-text-hero {
	position: relative;
	min-height: 100vh;

	@include media(sm) {
		min-height: 720px;
	}

	@include media($bp-desktop-small) {

		.experienceeditor & {
			min-height: 720px;
		}
	}

	;

	.experienceeditor &,
	.experienceeditor & .scEmptyPlaceholder {
		height: auto;
		min-height: 720px;

		.simple-slider {
			&.is-attached .simple-slider-inner,
			.simple-slider-inner {
				flex-flow: column nowrap;
			}
		}
	}

	.simple-slider {
		position: relative;
		min-height: 100vh;

		@include media(sm) {
			min-height: 720px;
		}

		&.is-attached .simple-slider-inner,
		.simple-slider-inner {
			position: relative;
			display: flex;
			flex-flow: row nowrap;
			height: auto;
			height: auto;
			min-height: 100vh;

			@include media(sm) {
				min-height: 720px;
			}
		}
	}

	.simple-slider.is-attached .slide,
	.simple-slider .slide {
		height: auto;
	}

	.simple-slider.is-attached .nav-dots,
	.simple-slider .nav-dots {
		text-align: center;

		@include media(md) {
			text-align: left;
		}
	}

	&.has-arrows {
		.B2-text-hero__item,
		.container {
			padding: 0 40px;

			@include media(xs) {
				padding: 0 62px;
			}

			@include media($bp-desktop-medium) {
				padding: 0 25px;
			}
		}
	}

	&.center-text {
		.B2-text-hero__item {
			align-items: center;

			& > * {
				text-align: center;
			}
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-left: auto;
		margin-right: auto;
		padding: 0 25px;
		width: 100%;
		height: 100%;

		@include media(md) {
			max-width: 970px;
		}

		@include media(lg) {
			max-width: 1170px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 65px;
			background-color: transparent;
		}

		/*
		&::before {
			height: calc(65px + 62px);

			@include media(md) {
				height: calc(65px + 73px);
			}

			@include media(lg) {
				height: calc(65px + 80px);
			}
		}
		*/

		.ui-btn--primary:hover::before {
			width: 100%;
		}

		& > * {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		&__label {
			font-weight: var(--font-weight-bold);
		}

		&__heading,
		.experienceeditor &__heading p {
			font-size: 48px;
			line-height: 56px;

			@include media($bp-tablet) {
				font-size: 72px;
				line-height: 72px;
			}

			;

			@include media($bp-desktop-small) {
				font-size: 80px;
				line-height: 80px;
			}

			;
		}

		.experienceeditor &__heading p {
			margin-bottom: 0;
		}


		&__desc,
		&__desc p {
			font-size: 18px;
			line-height: 28px;
			color: var(--color-gray-1);
			max-width: 640px;
		}

		a {
			display: block;
		}
	}

	.nav-buttons {
		.slide-nav-btn {
			font-size: 40px;
			color: var(--color-black);
		}
	}

	.nav-dots {
		margin-top: -50px !important;
	}
}

// IE11 Hacks
_:-ms-input-placeholder, :root .B2-text-hero__item__desc {
	width: 100%;
}

_:-ms-input-placeholder, :root .B2-text-hero__item__heading {
    width: 100%;
}

_:-ms-input-placeholder, :root .B2-text-hero__item > * {
    max-width: 100%;
}
