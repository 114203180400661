/**
* UI checkbox
*/

.ui-checkbox {
	position: relative;
	text-align: center;
	padding: 0 15px;
	.ui-checkbox-item+.ui-checkbox-item {
		margin-left: 60px;
	} //when items length exceeds so use arrow UI
	&.show-arrow-ui {
		.ui-checkbox-arrow {
			display: block;
		}
		.ui-checkbox-masker {
			display: block;
		} //this can alter "offset parent"
		.ui-checkbox-list {
			transform: translate3d(0, 0, 0);
			will-change: transform;
			transition: transform 800ms;
		}
	}
	&.reached-first {
		.ui-checkbox-arrow[data-type="left"] {
			pointer-events: none;
			opacity: .25;
		}
	}
	&.reached-last {
		.ui-checkbox-arrow[data-type="right"] {
			pointer-events: none;
			opacity: .25;
		}
	}
}

.ui-checkbox-masker {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	overflow: hidden;
}

.ui-checkbox-list {
	display: inline-flex;
	flex-wrap: nowrap;
	align-items: center;
	padding: 15px 0px;
}

.ui-checkbox-arrow {
	position: absolute;
	padding: 5px;
	color: rgba(33, 33, 33, 1);
	display: none;
	top: 50%;
	transform: translateY(-50%); //this can vary per design
	i {
		pointer-events: none;
		display: inline-block;
		color: inherit;
		font-size: 24px;
	}
	&[data-type="left"] {
		left: -15px;
	}
	&[data-type="right"] {
		right: -15px;
	}
}

.ui-checkbox-item {
	font-size: 15px;
	line-height: 20px;
	display: inline-block;
	position: relative;
	label {
		display: flex;
		flex-wrap: nowrap;
	}
	input {
		// display: none;
		position: absolute;
		opacity: 0;
	}

	.custom-checkbox {
		flex: 0 0 auto;
		width: 20px;
		height: 20px;
		border: 2px solid #f1efea;
		display: inline-block;
		margin-right: 15px;
		vertical-align: middle;
	}
	i {
		color:var(--color-nov-red);
		font-size: 32px;
		top: 5px;
		left: 14px;
		transform: translate3d(-50%, -50%, 0);
		position: absolute;
		pointer-events: none;
		opacity: 0;
		will-change: opacity;
		transition: opacity 150ms;
	}

	input:focus+.custom-checkbox i,
	input:checked+.custom-checkbox i {
		opacity: 1;
	}
}

//in mobile/tablet
@media screen and (max-width: 768px) {
	.ui-checkbox.show-arrow-ui,
	.ui-checkbox {
		.ui-checkbox-masker {
			overflow-x: auto;
			justify-content: flex-start;
		}
	}
}

//in mobile/tablet
@media screen and (max-width: 1024px) {
	.ui-checkbox-container {
		.ui-checkbox+.ui-checkbox {
			margin-left: 30px;
		}
	}
	.ui-checkbox.show-arrow-ui,
	.ui-checkbox {
		// .ui-checkbox-arrow {
		//   display: none;
		// }
		.ui-checkbox-masker {
			overflow-x: auto;
		}
		.ui-checkbox-list {
			transform: none !important;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.ui-checkbox {
		.ui-checkbox-arrow[data-type="left"],
		.ui-checkbox-arrow[data-type="right"] {
			opacity: .25; // just visually indicative
			pointer-events: none; // none clikable
		}
	}
}
