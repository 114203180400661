.I12-stock {

    .container {
        padding: 0;

        @include media($bp-desktop-small) {
            padding: 0 25px;
        }
    }

    &__data {
        height: 160px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        padding: 16px;
        background: var(--color-white);
        border-bottom: 2px solid var(--color-gray-3);

        @include media($bp-tablet) {
            height: 100px;
            align-items: center;
            justify-content: space-around;
        }

        @include media($bp-desktop-small) {
            width: 800px;
            height: 432px;
            align-items: flex-end;
        }

        &__item {
            margin-left: 10px;
            margin-bottom: 6px;
            padding: 10px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 40px;
                background: var(--color-gray-6);
                left: -8px;
                top: 6px;

                @include media($bp-tablet) {
                    height: 20px;
                    top: 50px;
                }
            }


            &:first-child, &:last-child {
                &::after {
                    display: none;
                }
            }

            &:last-child {
                margin-top: 0;

                @include media($bp-tablet) {
                    margin-top: 8px;
                }
            }

            &__label, &__morelink {
                font-size: 10px;
                padding-bottom: 2px;
                color: var(--color-gray-1);
                text-align: center;

                @include media($bp-tablet) {
                    font-size: 12px;
                    line-height: 16px;
                }

                @include media($bp-desktop-small) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            &__footnote &__label {
                text-align: left;
            }

            &__footnote, &__morelink {
                width: 100%;
                text-align: left;
            }

            &__footnote {
                padding-bottom: 0;
            }

            &__morelink {
                padding-top: 0;
            }

            &__footnote::after {
                content: none;
            }

            @include media($bp-tablet) {
                padding-top: 24px;

                &__footnote, &__morelink {
                    width: auto;
                    padding: 10px;
                }

                &__footnote {
                    margin-top: 24px;
                }

                &__footnote::after {
                    content: '';
                }

                &__morelink {
                    display: none;
                }
            }

            @include media($bp-desktop-small) {
                &__footnote, &__morelink {
                    width: 48%;
                }
                &__morelink {
                    display: block;
                    text-align: right;
                }
                &__footnote {
                    margin-top: 0;
                }
                &__footnote::after {
                    content: none;
                }
            }

            &__content {
                font-size: 14px;
                color: var(--color-gray-1);
                font-weight: var(--font-weight-bold);
                position: relative;

                @include media($bp-tablet) {
                    font-size: 24px;
                    line-height: 32px;
                }

                @include media($bp-desktop-small) {
                    font-size: 32px;
                    line-height: 40px;
                }
            }

            &:first-child {
                .I12-stock__data__item__content {
                    color: var(--color-nov-red);
                }
            }

            &__stock-price {
                .ui-btn--icon {
                    pointer-events: none;
                    width: 11px;
                    height: 11px;

                    @include media($bp-tablet) {
                        width: 20px;
                        height: 20px;
                    }

                    i {
                        font-size: 16px;

                        @include media($bp-tablet) {
                            font-size: 24px;
                        }

                        &.is-green {
                            color: var(--color-green);
                        }
                    }
                }
            }
        }

    }
}
