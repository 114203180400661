.E4-content-list {
    &__inner, &__title {
        padding: 0 15px;
        width: 100%;
        margin: 0 auto;
        h2 {
            line-height: normal;
            margin: 0;
            padding: 0;

            @include media(md) {
                padding: 0 0 45px 0;
            }
        }
        h3 {
            font-size: 18px;
            line-height: 1.33;
            font-weight: var(--font-weight-medium);
        }
    }
    &__contentblock {
		display: block;
		max-width: 352px;
        width: 100%;
		margin: 0 auto;
		color: var(--color-black);

		&#{&}:hover {
			text-decoration: none;
		}

        &__image, &__twocard__image {
            padding-top: 58.14%;
            margin: 45px 0 30px 0;
            overflow: hidden;
			height: 0;

			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
        }
		h3 {
			color: var(--color-black);
		}
        &__paragraph, &__metadata, &__twocard__paragraph {
            font-size: var(--label-font-size);
            line-height: var(--label-line-height);
			color: var(--color-gray-1);

			.E4-content-list__contentblock:hover & {
				color: var(--color-gray-1);
				text-decoration: none;
			}
        }
        &__twocard {
			display: block;
            max-width: 544px;
            width: 100%;
			margin: 0 auto;
			color: var(--color-black);

			@include media(md) {
				margin: 0;
			}

			h3 {
				color: var(--color-black);
			}
			.E4-content-list__contentblock____paragraph,
			.E4-content-list__contentblock____metadata,
			.E4-content-list__contentblock__twocard__paragraph {
				.E4-content-list__contentblock__twocard:hover & {
					color: var(--color-gray-1);
					text-decoration: none;
				}
			}

            &__text {
                position: relative;
                width: 90.7%;
                margin: 0 auto;
                background-color: var(--color-gray-4);
                padding: 34px;
                padding-top: 22px;
                top: -70px;
                margin-bottom: -58px;
			}

			&#{&}:hover {
				text-decoration: none;

				.E4-content-list__contentblock__paragraph,
				.E4-content-list__contentblock__metadata,
				.E4-content-list__contentblock__twocard__paragraph {
					color: var(--color-gray-1);
					text-decoration: none;
				}
			}
        }
    }
    @include media(sm) {
        &__contentblock {
            &__image {
                padding-top: 59.09%;
            }
            &__twocard {
                &__image {
                    padding-top: 57.35%;
                }
                &__text {
                    width: 76.47%;
                    top: -126px;
                }
                &__paragraph {
                    font-size: var(--body-font-size-small);
                    line-height: var(--body-line-height-small);
                }
            }
        }
    }
    @include media(md) {
        &__inner, &__title {
            h2 {
                font-size: 48px;
                line-height: 1.7;
            }
            h3 {
                font-size: var(--body-font-size);
                line-height: 1.5;
                font-weight: var(--font-weight-bold);
            }
        }
        &__inner {
			display: flex;
			justify-content: flex-start;
            flex-wrap: wrap;
            max-width: 1120px;
        }
        &__contentblock {
			margin: 0;
			max-width: none;
            width: 33.3%;
            padding: 0px 15px 50px 15px;
            &__image {
                margin-top: 0;
            }
            &__metadata {
                font-size: var(--body-font-size);
                line-height: 1.5;
            }
            &__paragraph {
                font-size: var(--body-font-size-small);
                line-height: var(--body-line-height-small);
            }
            .ui-btn--secondary, &__twocard .ui-btn--secondary {
                font-size: var(--body-font-size-small);
                line-height: var(--body-line-height-small);
                font-weight: var(--font-weight-medium);
            }
            &__twocard {
                width: 50%;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
