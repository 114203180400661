.J7-model-filter {
	position: relative;
	overflow: hidden;

	label {
		display: block;

		margin-bottom: 20px;

		font-size: 14px;
		font-weight: var(--font-weight-medium);
	}

	select {
		appearance: none;

		display: block;

		padding: 5px 40px 5px 12px;
		height: 40px;
		min-width: 230px;
		max-width: 100%;

		color: var(--color-white);
		font-size: 14px;

		background: var(--color-nov-red) url(../assets/images/icons/chevron-down.svg) no-repeat calc(100% - 15px) 53%;
		background-size: 10px;
		border: none;
		border-radius: 2px;

		option {
			color: var(--color-black);
			background: white;
		}

		&:focus {
			outline: none;
		}
	}
}

// Edge Only Hacks
_:-ms-lang(x), .J7-model-filter select {
	background-color: var(--color-nov-red);
	background-image: url(../assets/images/icons/chevron-down.svg);
	background-repeat: no-repeat;
	background-position: calc(100% - 15px) 53%;
 }


 // IE11 Hacks
_:-ms-input-placeholder, :root .J7-model-filter select {
	background-color: var(--color-nov-red);
}
