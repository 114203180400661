//Placeholder for now to test header
.hero {

	width: 100%;
	height: 720px;
	overflow: hidden;

	figure img {
		display: block;
		width: 100%;
		height: auto;
	}

}