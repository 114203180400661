.custom-select-wrapper {
	position: relative;
	overflow: hidden;
	border-bottom: 1px solid var(--color-gray-1);

	&::before {
		content: "\e906";

		font-family: 'icomoon';
		text-transform: none;
		line-height: 1;
		speak: none;
		position: absolute;
		top: 50%;
		right: 12px;
		color: var(--color-nov-red);

		transform: translateY(-50%);
	}

	&.focused {
		outline: 2px dotted var(--color-gray-0);
	}


	select {
		color: black;
		background: transparent;
		border: 0;
		width: calc(100% + 15px);

		&.visible {
			color: inherit;
		}

		&:focus {
			outline: none;
		}
	}

}

label.custom-select-wrapper {
	display: block;

	&::before {
		pointer-events: none;
	}

	select {
		appearance: none;

		&::-ms-expand {
			display: none;
		}
	}
}
