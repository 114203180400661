/**
* Main project stylesheet combiner
*/

// ---------------------------------------------------------------------------
// Framework
@import 'base';
@import 'framework/font';
@import 'framework/icon';
@import 'framework/image';

@import 'framework/framework';
@import 'framework/media-queries';
@import 'framework/normalize';

// // Default typography for the site
@import 'framework/typography';

// // Modules
@import 'generic';
@import 'module/search';

// // Basic layout and default typography for the site
@import 'ui';
@import 'modules';
@import 'pages';
