.I1-header-search {
    display: none;
    position: fixed;
	top: calc(15% + 57px);

	left: 50%;
	margin: 0 auto;

	width: 100%;
	max-width: 720px;

	background-color: var(--color-white);

	transform: translateX(-50%);

	text-align: left;

	h3 {
		margin-top: 0;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
	}

    &__container {
        display: flex;
        flex-direction: column;
		height: calc(85vh - 50px - 25px);
		overflow-y: auto;
    }

    &__suggestions, &__products {
        position: relative;
        padding: 25px;
    }

    &__suggestions {
        flex-grow: 1;
        box-shadow: 0 1px 7px 2px rgba(0, 0, 0, 0.1);
        z-index: 999;

        ul {
            width: 100%;

            li {
                position: relative;
                padding-right: 2em;
                margin: 0.5em 0;

                a {
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    color: inherit;
                }

                .icon {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &__products {
        overflow-y: auto;
        width: 100%;
        background-color: var(--color-gray-3);

        ul {
            width: 100%;
            font-size: var(--label-font-size);
            line-height: var(--label-line-height);
            color: var(--color-gray-1);

            li {
                margin: 1em 0;

                a {
					display: flex;
					align-items: flex-start;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    color: inherit;
                }
            }
        }

        a, a .icon {
            color: var(--color-nov-red);
        }
        a {
            display: inline-block;
            position: relative;
            margin-top: 1em;
            font-size: var(--body-font-size-small);
            line-height: var(--body-line-height-small);
            font-weight: var(--font-weight-bold);

            .icon {
                position: absolute;
                top: 50%;
                right: -2em;
                transform: translateY(-50%);
            }
        }

    }

    &__thumbnail {
        width: 120px;
        height: auto;
        flex: 0 0 120px;
    }

    &__product-name {
		flex-grow: 1;
		margin-top: 1em;
        margin-left: 1.5em;
    }

    &.is-open {
        display: block;
    }

    @include media(sm) {
        width: calc(100% - 50px);
        box-shadow: 0 1px 7px 2px rgba(0, 0, 0, 0.1);

        &__container {
            flex-direction: row;
            height: auto;
        }

        &__suggestions {
            box-shadow: none;
        }

        &__products {
            width: 398px;
            flex: 0 0 398px;
        }
    }

    @include media(md) {
        top: calc(15% + 48px);
	}

}
