.I11-search-dynamic-map {
	&__container {
		position: relative;
		padding-bottom: 1040px;
		width: 100%;
		height: 0;
		overflow: auto;

		@include media(sm) {
			padding-bottom: 376px;
		}
	}

	&__iframe {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border: none;
	}
}
