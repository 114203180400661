.C1-card {
	position: relative;
	display: block;
	margin-bottom: 48px;
	width: 100%;
	overflow: hidden;

	background-color: var(--color-gray-4);

	transition: all .1s $easing-ease-out-quad;

	&:hover {
		text-decoration: none !important;

		transform: scale(1.03);
		box-shadow: 0 7px 20px rgba(0,0,0,.3);
	}

	@include media(sm) {
		width: calc(50% - 12px);
		margin-bottom: 28px;
	}

	@include media(md) {
		width: calc(33% - 16px);
	}

	&--product {

		display: flex;
		flex-flow: column nowrap;

		.C1-card__content {
			flex-grow: 1;
		}
		
		.ui-btn--secondary {
			margin-top: auto;
			font-size: 14px;
			line-height: 24px;
		}

		@include media(sm) {
			width: calc(50% - 8px);
			margin-right: 16px;
			margin-bottom: 16px;

			&:nth-child(2n) {
				margin-right: 0;
			}

		}

		@include media(md) {
			width: calc(50% - 8px);
		}

		@include media(lg) {
			width: calc(33.33% - 12px);

			&:nth-child(2n) {
				margin-right: 16px;
			}

			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}

	&__body {
		margin-bottom: var(--body-line-height-small);

		color: var(--color-gray-1);
		font-size: var(--body-font-size-small);
		line-height: var(--body-line-height-small);

		max-height: calc(var(--body-line-height-small) * 3);

		overflow: hidden;

		p {
			margin-bottom: var(--body-line-height-small);
			color: var(--color-gray-1);
			font-size: var(--body-font-size-small);
			line-height: var(--body-line-height-small);
			max-height: calc(var(--body-line-height-small) * 3);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__figure {
		position: relative;
		padding-bottom: 57.39%;
		width: 100%;
		height: 0;

		overflow: hidden;

		> img {
			display: block;

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			width: 100%;
			height: auto;
		}
	}

	&__content {
		display: flex;
		flex-flow: column nowrap;
		padding: 25px 30px;
		max-width: 100%;
		
		.ui-btn--secondary {
			font-size: 14px;
			line-height: 24px;

			i {
				position: relative;
				top: 1px;
			}
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 8px;
		width: 100%;

		color: var(--color-black);
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;

		@include multiline-ellipsis(2, 24px, true);
	}

	&__product-data {
		width: 100%;
		font-size: 14px;
		line-height: 22px;
	}

	&__meta-before,
	&__meta-after {
		margin-bottom: 8px;
		color: var(--color-gray-1);
		font-size: 12px;
		line-height: 22px;

		width: 100%;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__meta-before {
		order: -1;
		font-size: 12px;
		line-height: 22px;
	}

}

_:-ms-input-placeholder, :root .C1-card__content { width: 100%; }
