/**
* UI Input
*/
:root {
	--ui-input-field-height: 50px;
	--ui-input-field-height-sm: 30px;
	--ui-input-field-height-lg: 90px;
	--ui-input-font-size: 16px;
}

%ui-input,
.ui-input {
	position: relative;
	font-family: var(--ui-font-family);
	font-size: var(--ui-input-font-size);
	display: inline-block;

	input {
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
		color: var(--color-gray-1);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		height: var(--ui-input-field-height);
		outline: none;
		background: transparent;
		padding: 0 var(--spacing);
		text-decoration: none;
		box-sizing: border-box;
		border: 0;
		border-bottom: 1px solid var(--color-border);

		&::-moz-placeholder,
		&:-moz-placeholder {
			opacity: 0;
		}

		// Remove user-agent red box shadow on Firefox
		&:not(output):-moz-ui-invalid {
			box-shadow: none;
		}

		&::placeholder {
			opacity: 0;
		}

		// ie10-Edge
		&:-ms-input-placeholder {
			opacity: 0;
		}

		&::-ms-clear {
			color: inherit;
			opacity: 0;
		}

		&::-ms-reveal {
			opacity: 0;
		}

		&::-webkit-input-placeholder {
			color: inherit;
			opacity: 0;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:hover {
			box-shadow: 0 0 0px 1000px white inset !important;
		}

		// &:valid {}
		// &:invalid {}
		&:active,
		&:focus {

			// when js init
			.init & {
				outline: none;
				text-decoration: none;
			}
		}
	}

	// per type
	// input[type="search"],
	// input[type="text"],
	// input[type="password"],
	// input[type="number"],
	// input[type="phone"],
	// input[type="email"]{}

	label {
		font-size: var(--ui-input-font-size);
		color: var(--color-gray-2);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: var(--spacing);
		transition: transform var(--ui-transition-dur);
		pointer-events: none;
	}

	// icon buttons(before|after)
	.ui-btn--icon {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;

		i {
			font-size: 20px;
		}

		&.before {
			left: 0;
		}

		&.after {
			right: 0;
		}
	}

	&.small {

		input {
			height: var(--ui-input-field-height-sm);
		}
	}

	&.no-label {
		label {
			@extend %visuallyhidden;
		}

		input {

			&::-moz-placeholder,
			&:-moz-placeholder {
				opacity: 1;
			}

			&::placeholder {
				opacity: 1;
			}

			// ie10-Edge
			&:-ms-input-placeholder {
				opacity: 1;
			}
		}
	}

	// bottom hair line
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		opacity: 0;
		transform: scaleX(0);
		transform-origin: 50%;
		transition: transform var(--ui-transition-dur);
		background-color: var(--color-nov-red);
	}

	&.focus {

		// show "focus" line
		&:after {
			opacity: 1;
			transform: scaleX(1);
		}
	}

	// !! when value is not empty || focus
	&.focus,
	&:not(.empty-value) {
		label {
			transform: translate3d(0, -36px, 0);
			font-weight: 500;
		}
	}

	// variation
	&.has-icon-before {

		input {
			padding-left: calc(var(--spacing) * 4);
		}

		label {
			left: calc(var(--spacing) * 4);
		}
	}

	&.has-icon-after {

		input {
			padding-right: calc(var(--spacing) * 4);
		}
	}

	&.white {

		label,
		input {
			color: var(--color-white);
			border-color: var(--color-white);
		}

		.ui-btn--icon i {
			color: var(--color-white);
		}
	}
}
