.K1-map {
	&__container {
		position: relative;
		width: 100%;
		height: 1100px;
		overflow: hidden;

		@include media(md) {
			padding-bottom: 50%;
			height: 0;
		}
	}

	&__iframe {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border: none;
	}
}
