.I4-sidebar-section {
    &, p {
        font-size: var(--label-font-size);
        font-weight: var(--font-weight-regular);
        line-height: 2;

        @include media($bp-tablet) {
            line-height: 1.33;
        }
    }

    h5 {
        font-size: var(--subhead-font-size);
        font-weight: var(--font-weight-medium);
        line-height: 1.67;
        margin: 0;
        margin-bottom: 0.25em;

        @include media($bp-tablet) {
            font-size: var(--h4-font-size);
            line-height: 1.25;
            font-weight: var(--font-weight-bold);
        }
    }

    p {
        margin: 0;
        margin-bottom: 1.5em;

        @include media($bp-desktop-small) {
            color: var(--color-gray-1);
        }
    }

    &__media {
        @include background-cover-image();
		height: auto;
        width: 100%;
        margin-bottom: 0;
        padding: 0;

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        @include media($bp-tablet) {
            &::before {
                padding-top: 66.67%;
            }
        }
    }

    &__content {
        padding: 3em;

        @include media($bp-tablet) {
            padding: 2em;
        }
    }

    &__links {
        column-count: 2;

        li::before {
            position: relative;
            top: 0.1em;
            font-family: 'icomoon';
            content: "\e908";
            padding-right: 0.1em;


        }

        @include media($bp-tablet) {
            li {
                padding-bottom: 1em;
            }

            li::before {
               content: none;
            }

            a {
                text-decoration: underline;
            }
        }

        @include media($bp-desktop-small) {
            li {
                padding-bottom: 0.75em;
            }
        }
    }
}
