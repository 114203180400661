/**
* Generic.
*/

@import "base";
// AA focus color & underline
@mixin global-focus-link() {
	text-decoration: none;
	outline: 2px dotted var(--color-global-focus-color);
}

@mixin global-focus-box() {
	text-decoration: none;
	outline: 2px dotted var(--color-global-focus-color);
}

@mixin module-box-shadow() {
	box-shadow: 0px 7px 14px 9px rgba(37, 37, 37, 0.08);
}

@keyframes animate-y-in-plus {
	0% {
		transform: translateY(90px);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes animate-y-in-minus {
	0% {
		transform: translateY(-90px);
	}

	100% {
		transform: translateY(0);
	}
}

.hidden{
	display: none !important;
}

html {
	-webkit-overflow-scrolling: touch;
}

body {
	font-family: var(--base-font);
	-webkit-font-smoothing: antialiased;
	color: var(--color-font-black);
	overflow-x: hidden;
	overflow-y: auto;
	// padding: 0 4px; // AA outline

	// states
	&.no-scroll {
		@extend %no-scroll;
	}

	// when page fetching
	&.app-fetching {
		@extend %fetching;

		.app-loader {
			opacity: 1;
			bottom: 10px;
			transition: opacity 200ms,
			bottom 200ms;
		}
	}

	&.app-error {
		.app-modal {
			@extend .active;
		}
	}

	&.search-active {
		overflow-y: scroll;
		width: 100%;
	}

	&.show-load-more {
		.load-more-wrapper {
			display: block;
		}
	}

	// prevent scrolling on scrollingElement
	&.main-nav-expanded {
		// overflow: hidden;
		position: fixed !important;
		overflow-Y: scroll !important;
		width: 100%;
	}

}

// simple normalize
* {
	box-sizing: border-box;
}

ul,
li {
	padding: 0;
	margin: 0;
	list-style: none;
}

main {
	background-color: var(--color-white);
}

a {
	text-decoration: none;
	-webkit-tap-highlight-color: transparent; // IOS gray box
	color: var(--color-nov-red);

	&:not([class^="ui-btn--"]) {
		&:active,
		&:hover {
			text-decoration: underline;
		}
	}
}

// app loader
.loader {
	display: inline-block;
}

// RTE styles
.rte {
	p, ul, ol, blockquote {
		color: var(--color-gray-1);
	}
	.h1, .h2, .h3, .h4, .h5,
	h1, h2, h3, h4, h5 {
		margin-block-end: 14px;
	}
	.h5, h5 {
        font-weight: var(--font-weight-medium);
	}
	blockquote {
		margin-inline-start: 0;
		margin-inline-end: 0;
		font-style: italic;
		font-size: var(--subhead-font-size);
		line-height: var(--subhead-line-height);
		font-weight: var(--font-weight-regular);
		text-align: center;
		margin-left: 0;
		margin-right: 0;
	}
	ul, ul li {
		list-style: disc;
	}
	ol, ol li {
		list-style: decimal;
	}
	ul, ol {
		margin: 0 0 1.25em;
		padding-inline-start: 20px;
		line-height: var(--body-line-height);
	}

	p,
	ul, ol,
	h1, .h1,
	h2, .h2,
	h3, .h3,
	h4, .h4,
	h5, .h5,
	h6, .h6 {
		&:last-child {
			margin-bottom: 0;
		}
	}

	@media screen and (min-width: $bp-tablet){
		blockquote {
			text-align: left;
		}
	}
}
// End RTE styles

// align center
.is-centered {
	text-align: center;
}

.top-component-height-margin {
	margin-top: var(--header-height);
}

.standard-padding {
	padding-top: var(--standard-padding);
}

.meta-data {
	width: 156px;
	height: 12px;
	font-size: 16px;
	line-height: 0.75;
	letter-spacing: 0;
	color: var(--color-gray-1);
}

.main-logo {
	display:inline-block;

	a:focus {
		@include global-focus-box();
	}

	&.enclosed {
		background: var(--color-black);
		padding: 15px 25px;
	}

}

.dev-note {
	display: inline-block;
	padding: 25px 15px;
	background: #eaff00;
	font-size: 12px;

	> i {
		position: absolute;
		top: 5px;
		left: 5px;
	}
}

.lazy-video-container {
	position: relative;
	@include background-cover-image();
	.lazy-video-inner {
		width: 100%;
		height: 100%;

		position: absolute;
		left: 0;
		top: 0;

		overflow: hidden;

		video {
			width: 100%;
			height: 100%;

			object-fit: cover;
			pointer-events: none;
		}

		.ie-poster {
			display: none;

			&.u-disappear {
				opacity: 0;
			}
		}
	}
}
//---- UTIL CLASS---//
// during any type of fetching
%fetching,
%lock,
.lock,
.fetching {
	pointer-events: none !important;
	user-select: none;
}

// this should be added due to AA instead of
%visuallyhidden,
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

%no-scroll {
	overflow-y: scroll;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

//visible only below 480px
.mobile-only {
	display: block;
	@media screen and (min-width: $bp-mobile + 1px) {
		display: none;
	}
}

.mobile-hide {
	@media screen and (max-width: $bp-mobile) {
		display: none;
	}
}

.t-center {
	text-align: center;
}

.media-reponsive-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 20px;
	height: 0;
	margin: 30px 0;
	>iframe,
	>object {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.off {
	display: none !important;
}

.off-only-desktop {
	@media screen and (min-width: 1025px) {
		display: none !important;
	}
}

.hide {
	opacity: 0;
	pointer-events: none;
}

.blur {
	filter: blur(3px);
	transform: translate3d(0, 0, 0);
}

.debug {
	width: 100%;
	position: fixed;
	padding: 15px;
	font-weight: 600;
	background-color: red;

	width: 150px;
	height: 150px;
	top: 0;
	left:0;
	z-index: 9999999;
	display: none;
}

