.B3-home-page-hero__action-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    z-index: 775;

    background-color: var(--color-white);

    @include media(sm) {
        display: flex;
        &:after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: var(--color-gray-2);
            opacity: .24;
        }
    }

    @include media(md) {
        width: 816px;
        right: 0;
        left: auto;
    }

    &__search {
        height: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & > * {
            margin-left: 10px;
            margin-right: 10px;
        }

        @include media(sm) {
         height: 100%;
        }

        &__link {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            margin: 0;

            & > * {
                margin-left: 10px;
                margin-right: 10px;
            }

            text-decoration: none !important;

        }

        .ui-btn--icon {
            .icon-search {
                font-size: 20px;
            }
        }

        p {
            margin: 0;
            color: var(--color-gray-1);
            font-size: 16px;
            line-height: 24px;
            background: transparent;
            white-space: nowrap;
        }

        &:after {
            position: absolute;
            content: '';
            left: 24px;
            right: 24px;
            bottom: 0;
            height: 1px;
            background: var(--color-gray-2);
            opacity: .24;
            @include media($bp-tablet) {
                display: none;
            }
        }
    }

    &__categories {
        position: relative;
        height: 50%;
        width: 100%;

        @include media(sm) {
			margin-left: 16px;
			margin-right: 16px;
            height: 100%;
        }

        &__container {
            position: relative;
            display: flex;
            align-items: center;
            overflow-y: hidden;
            overflow-x: scroll;
            width: calc(100% - 120px);
            height: 100%;

            -ms-overflow-style: none;

            [class*="firefox"] & {
                height: calc(100% + 16px);
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__item {
            margin: 0 16px;
            flex-shrink: 0;

            &:first-child {
                position: sticky;
                top: 0;
                left: 0;
                bottom: 0;
                background: var(--color-white);

                padding-right: 10px;
            }

            &.active {
                color: var(--color-nov-red);
                font-weight: var(--font-weight-medium);
            }

            a {
                color: var(--color-gray-1);

                &:hover {
                    color: var(--color-nov-red);
                    text-decoration: none;
                }
            }
        }

        &__navigation {
            &__left, &__right {
				position: sticky;
				top: 0;
                pointer-events: all;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 40px;
                text-decoration: none !important;

                .icon {
                    color: var(--color-nov-red);
                    font-size: 35px;
                    line-height: 1;
                    margin: 0;
                    padding: 0;
				}

				.no-nav & {
					visibility: hidden;
				}
            }

            &__left {
				float: left;
				left: 0;
                background-image: linear-gradient(to left, rgba(255,255,255, 0.5) , var(--color-white));

                &:active,
                &:focus {
                    outline: none;
                }
            }

            &__right {
				float: right;
				right: 0;
                background-image: linear-gradient(to right, rgba(255,255,255, 0.5) , var(--color-white));

                &:active,
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

// IE only fix
_:-ms-input-placeholder, :root .B3-home-page-hero__action-box__search { flex: 0 0 300px; }
