.H2-social-share {
	position: relative;
	z-index: 1;
	padding: 60px 0 30px;

	.container {
		position: relative;
	}

	&__wrapper {
		position: relative;

		&.is-open {
			.H2-social-share__links {
				pointer-events: unset;
				opacity: 1;
			}
			.js-share-trigger {
				.icon {
					color: var(--color-nov-red);
				}
			}
		}
	}

	.js-share-trigger {
		text-decoration: none !important;

		&:active,
		&:focus {
			outline: none;
		}

		&:hover {
			.icon {
				color: var(--color-nov-red);
			}
		}
	}

	&__links {
		display: block;
		opacity: 0;
		pointer-events: none;

		position: absolute;

		border: 1px solid var(--color-gray-3);
		border-radius: 6px;
		padding: 6px;

		top: -10px;
		left: 30px;

		z-index: var(--tool-bar-index);
		background-color: var(--color-white);

		white-space: nowrap;

		transition: opacity .2s linear;

		&:before {
			content: "";
			position: absolute;
			display: block;
			border-style: solid;
			border-width: 1px 0 0 1px;
			border-color: var(--color-gray-3);
			width: 8px;
			height: 8px;
			transform: rotate(-45deg);
			background-color: var(--color-white);
			left: -5px;
			top: calc(50% - 4px);
		}

		a {
			display: inline-block;
			margin: 0 5px;

			&:hover {
				text-decoration: none;

				.icon {
					color: var(--color-nov-red);
				}
			}

			&:first-child {
				margin-left: 0;
			}
		}

		.icon {
			font-size: 24px;
		}

		.icon-mail {
			position: relative;
			font-size: 18px;
			line-height: 1.1;
			top: -2px;
		}
	}

	&--small-container {
		padding-bottom: 15px;

		.H2-social-share__wrapper {
			position: relative;
			margin: 0 auto;
			max-width: 736px;
		}
	}

	&--D10 {
		padding: 30px 0 0;

		.container {
			position: relative;

			display: flex;
			justify-content: flex-end;

			margin: 0 auto;
			max-width: 736px;
		}

		.H2-social-share__links {
			right: 30px;
			left: auto;

			&:before {
				left: auto;
				right: -5px;
				transform: rotate(135deg);
			}
		}

		@include media(md) {
			margin-top: -20px;
			padding: 0;

			.container {
				position: relative;

				display: flex;
				justify-content: flex-end;

				margin: 0 auto;
				max-width: 736px;
			}

			.H2-social-share__wrapper {
				transform: translate(90px, -40px);
			}
		}
	}

	.D14-sidebar-section & {
		.container {
			padding: 0;
		}
	}

	& + .standard-padding {
		padding-top: 0;
	}
}
