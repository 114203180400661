.search-results {
	.container {
		display: flex;
		flex-flow: row wrap;

		.experienceeditor & {
			justify-content: flex-end;
		}
	}
}

.I14-search-results {
	margin-top: 140px;

	flex-grow: 1;

	@include media(sm) {
		margin-top: 60px;
		margin-right: 30px;
		width: calc(100% - 323px);
	}

	@include media(md) {
		margin-right: 60px;
		width: calc(100% - 353px);
	}

    &__container {
        margin: 0 auto;
    }

    &, p {
        font-size: 14px;
    }

    &__header {
        display: flex;
		flex-wrap: wrap;
		align-items: center;

		.custom-select-wrapper {
			margin-top: 20px;
			width: 100%;
			height: 40px;

			@include media(sm) {
				margin-top: 0;
				margin-left: 15px;
				width: auto;
			}

			select {
				height: 40px;
                width: 100%;
                padding-right: 35px;
			}
		}
    }

    &__count {
		margin: 0 10px 0 0;

        color: var(--color-nov-red);
        font-size: 40px;
        line-height: 1.17;
    }

    &__search-term {
		display: block;
		margin: 0;

		font-size: var(--body-font-size-small);
		font-weight: 600;
        line-height: var(--body-line-height-small);
    }

    &__filter {
		direction: block;
		margin: 1em 0;

        height: 1.75em;
        border-bottom: 1px solid var(--color-gray-2);
        font-size: var(--body-font-size-small);
        line-height: var(--body-line-height-small);

        .icon {
            position: relative;
            top: 0.1em;
            color: var(--color-nov-red);
        }

        @include media($bp-tablet) {
            position: relative;
            left: 2em;
        }
    }

    &__list {
        li {
            display: flex;
            align-items: center;
			flex-wrap: wrap;

			overflow: hidden;

            position: relative;
			padding: 2em 0;

            border-bottom: 1px solid var(--color-gray-6);
        }

        img {
            display: block;
            margin: .5em 1em .5em 0;
			width: 75px;

			@include media(sm) {
				float: right;
				margin: .5em 0 .5em 1em;
				width: 160px;
			}
        }

		p {
			width: 100%;
		}

		a {
			color: var(--color-black);

			&:hover {
				text-decoration: none;
			}
		}

        a.read-more {
			color: var(--color-nov-red);
            text-decoration: underline;
        }

        @include media($bp-tablet) {
            li {
                display: block;
            }

            p {
                width: 70%;
            }
        }
    }

    &__title {
        display: block;
        flex-basis: 60%;
		flex-grow: 1;

		margin: 0;

        font-size: var(--body-font-size-small);
        line-height: 1;
		font-weight: var(--font-weight-medium);

		@include media(sm) {
			margin-bottom: 10px;
			font-size: 16px;
			line-height: 24px;
		}

		+ a {
			margin-top: 1.25em;
		}
    }

    .ui-pager {
        margin-top: 2em;
    }
}
