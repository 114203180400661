/**
* Mobile Header
*/

@keyframes header-close-btn-in {
	0% {
		opacity: 0;
		transform: translate3d(0, -16px, 0);
	}

	60% {
		transform: translate3d(0, 0, 0);
	}

	61% {
		transform: rotate(-90deg);
	}

	100% {
		opacity: 1;
		transform: rotate(0);
	}
}

// desktop nav/header
.header {
	position: fixed;
	pointer-events: auto;
	width: 100%;
	left: 0;
	top: 0;
	z-index: var(--header-index);

	will-change: background-color;
	transition: background-color var(--ui-transition-dur);

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-touch-callout: none;

	[class*="safari"] & {
		// disable transition in safari due to bug where transitions doesn't happen during a "bouncy scroll to top"
		will-change: none;
		transition: none;
	}

	.experienceeditor & {
		position: relative;
		background-color: var(--color-black);
	}

	&__ui {
		position: relative;
		z-index: calc(var(--header-index) + 1);

		-webkit-tap-highlight-color: rgba(0,0,0,0);
		-webkit-touch-callout: none;
	}

	.f-row {
		height: var(--header-height);
		display: flex;
		flex-wrap: nowrap;
		align-items: center;

		-webkit-tap-highlight-color: rgba(0,0,0,0);
		-webkit-touch-callout: none;
	}

	.f-col {
		min-height: 1px;
		display: flex;
		justify-content: flex-start;

		-webkit-tap-highlight-color: rgba(0,0,0,0);
		-webkit-touch-callout: none;

		&.left {
			flex: 0 0 auto;
			width: var(--header-side-col-width);
		}

		&.center {
			flex: 1 1 auto;
			text-align: center;
		}

		&.right {
			flex: 0 0 auto;
			text-align: right;
			justify-content: flex-end;
			align-items: center;
			width: var(--header-side-col-width);
		}
	}

	.ui-btn--icon {
		font-size: 14px;
		margin-right: calc(var(--spacing) * 2);
		transition: none;

		> i {
			font-size: 20px;
			color: var(--color-white);

			&.icon-x {
				transform: scale(1.2);
			}
		}

		&:last-child {
			margin-right: 0;
		}

		&[data-type="user"] {
			position: relative;

			.ui-balloon {
				position: absolute;
				top: calc(100% + 17px);
				right: 0;
				width: 256px;
				transform: translate3d(25%,-5px,0);

				opacity: 0;
				visibility: hidden;
				transition: transform var(--ui-transition-dur),
				opacity var(--ui-transition-dur),
				visibility var(--ui-transition-dur);
			}

			&.active {

				.ui-balloon {
					opacity: 1;
					visibility: visible;
					transform: translate3d(25%, 0, 0);
				}

			}

		}

		&[data-type="menu"] {
			width: auto;
			display: flex-inline;
			flex-direction: row;
			flex-wrap: nowrap;
			align-content: center;

			> span {
				color: var(--color-white);
				margin-right: calc(var(--spacing) * 2);
			}

			&.active {
				i {
					color: var(--color-black);
				}
			}
		}

		&.active {
			i {
				color: var(--color-nov-red);
			}
		}
	}

	.focus-exit {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		padding: 0;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;

		&:focus {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}
	}

	.ui-container {

		&--user {
			position: relative;

			.ui-btn {

				&--icon {
					margin-right: calc(8px * 2);
				}
			}

			.ui-balloon {
				position: absolute;
				top: calc(100% + 17px);
				right: 17px;
				width: 256px;
				transform: translate3d(25%,-5px,0);

				opacity: 0;
				visibility: hidden;
				transition: transform var(--ui-transition-dur) ease-in-out,
				opacity var(--ui-transition-dur) ease-in-out,
				visibility var(--ui-transition-dur) ease-in-out;
			}

			&.active {

				.ui-balloon {
					opacity: 1;
					visibility: visible;
					transform: translate3d(25%, 0, 0);
				}

			}
		}
	}

	// ui balloon on user account
	.user-account-content {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;

		&__t {
			max-width: 160px;
			margin: 23px auto 0;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: center;
		}

		p {
			max-width: 190px;
			font-size: 12px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: center;
			color: #707070;
			margin: 22px auto 11px;
		}

		.ui-btn--box {
			width: 100%;
			margin-top: 16px;
		}
	}

	.main-logo {
		display: inline-flex;
		flex-direction: colunm;
		align-items: center;

		img {
			display: block;

			&:nth-of-type(2) {
				display: none;
			}
		}
	}

	&__search {
		position: fixed;
		top: 15%;
		left: 0;
		right: 0;

		margin: 0 25px;

		width: calc(100% - 50px);
		max-width: 680px;

		transition: opacity var(--ui-transition-dur),
					transform var(--ui-transition-dur),
					visibility var(--ui-transition-dur);

		visibility: hidden;

		opacity: 0;

		@include media(sm) {
			margin: 0 auto;
			left: 50%;
			right: auto;
			transform: translateX(-50%);
		}

		input {
			border-width: 2px;
		}

		&:after {
			height: 2px;
		}

		.ui-btn {
			&--icon {
				left: auto;
				right: 25px;

				@include media(sm) {
					right: 0;
				}
			}
		}
	}

	&.solid {
		background-color: var(--color-black);
	}

	// states
	// collapsed
	&.collapsed {
		@extend .solid;
	}

	// show menus
	&.expanded {
		background: var(--color-white) !important;

		.ui-btn--icon {
			span,
			i {
				color: var(--color-black);
			}

			&:nth-of-type(0),
			&:nth-of-type(1) {
				i {
					color: var(--color-nov-red);
				}
			}
		}

		.main-logo {
			img:nth-of-type(1) {
				display: none;
			}
			img:nth-of-type(2) {
				display: block;
			}
		}

		.header__navigation-container {
			@extend .active;
		}

		.focus-exit {
			visibility: visible;
		}

	}

	&:not(.expanded) {
		.header__search {
			@extend .white;

			input {
				border: 0;
			}
		}
	}

	body.search-active &,
	&.show-search {
		.header__search {
			visibility: visible;
			opacity: 1;
		}
	}

	body.no-hero & {
		// solid version
		@extend .solid;
	}
}

.header__navigation-container {
	position: fixed;
	top: var(--header-height);
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: var(--header-index);
	background-color: var(--overlay-bg);

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: var(--header__nav-height);
		z-index: 0;
		background-color: var(--color-white);
	}


	opacity: 0;
	visibility: hidden;
	transition: opacity var(--ui-transition-dur),
	visibility var(--ui-transition-dur);

	&.active {
		opacity: 1;
		visibility: visible;
	}

}

.header__navigation-container-empty-space {
		position: absolute;
		top: var(--header__nav-height);
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		display: none;
}

@media screen and (min-width: $bp-desktop-small) {
	.header__navigation-container-empty-space {
		display: block;
	}
}

.header__nav {
	position: absolute;

	.icon-arrow-left {
		opacity: 0;
		visibility: hidden;
	}

	// nested
	&[data-lv="1"], &[data-lv="2"] {
		top: 0;
		left: var(--header__nav-width);
		z-index: 1;
		opacity: 0;
		visibility: hidden;
	}
}

.header__nav-item {

	.ui-btn--secondary {
		position: relative;
		width: 100%;
		margin-left: 2px;
		text-decoration: none;
		font-size: 14px;
		font-weight: 600;
		line-height: normal;
		padding-right: 2rem;

		i {
			position: absolute;
			right: 0;
			top: 50%;
			display: inline-block;
			font-size: 18px;
			font-weight: 100;
			color: inherit;
			transform: translateY(-50%);
			visibility: hidden;
		}

		&:hover {
			color: var(--color-nov-red);
		}

		// waapi supported
	 	.waapi & {
			opacity: 0;
			transition-property: opacity, transform;
			transition: opacity var(--ui-transition-dur),
			transform var(--ui-transition-dur);
		}
	}

	.header__teaser,
	.header__teaser-list {
		opacity: 0;
		visibility: hidden;

		.item {
			//TODO
			.waapi & {
				// opacity: 0;
				// transition-property: opacity, transform;
				// transition: opacity var(--ui-transition-dur),
					// transform var(--ui-transition-dur);
			}
		}
	}

	// &[data-lv="1"] {}
	// &[data-lv="2"] {}

	// states
	&.has-children,
	&.has-nav-alternate {
		// chevron
		>.ui-btn--secondary i {
			visibility: visible;
		}
	}

	&.has-teaser {}

	&.active {
		>.header__nav,
		>.header__teaser,
		>.header__teaser-list {
			opacity: 1;
			visibility: visible;
			transform: none;
		}

		>.ui-btn--secondary {
			pointer-events: none;
			outline: none;
			color: var(--color-nov-red);
		}
	}

	&.hide-teaser {

		>.header__teaser,
		>.header__teaser-list {
			opacity: 0;
			visibility: hidden;
		}
	}
}

// teasers
.header__teaser {
	position: fixed;
	top: var(--header-height);
	right: 0;
	width: var(--header__teaser-width);
	height: var(--header__nav-height);
	background: var(--color-white);
	border-left: 1px solid var(--color-gray-3);

	figure {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: auto;
		max-height: 50%;
		overflow: hidden;

		img, a {
			display: block;
			width: 100%;
			height: auto;

			object-fit: cover;
		}
	}

	&-meta {
		padding: 20px 32px 28px 32px;
		color: var(--color-gray-1);
	}

	&-cta {
		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		height: 40px;
		line-height: 40px;
		padding: 0 32px;
		font-size: 16px;
		font-weight: bold;
		color: var(--color-white);
		background-color: var(--color-nov-red);
		outline: none;
		text-decoration: none;

		position: absolute;
		left: 0;
		bottom: 0;

		transition: background-color .1s ease-in-out;

		&:hover {
			background-color: var(--color-dark-red);
			text-decoration: none !important;
		}

		i {
			color: inherit;
			font-size: 16px;
		}
	}

	h4 {
		font-size: 20px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: var(--color-black);
		margin-top: 10px;

		a:hover {
			text-decoration: none;
		}

		a {
			color: inherit;
		}
	}

	p {
		font-size: 16px;
		line-height: 1.6;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		margin-bottom: 0;
	}
}

.header__teaser-list {
	position: fixed;
	top: var(--header-height);
	right: 0;
	width: var(--header__teaser-width);
	height: var(--header__nav-height);
	background: var(--color-gray-4);

	.items {
		margin-top: 100px;
		padding: 0 40px;

		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;

		max-height: 410px; // 3x2 matrix
		overflow: hidden;
	}

	.item {
		width: var(--header__teaser-list-item-width);
		height: 120px;
		margin-bottom: 24px;

		figure {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;

			// gradient
			>a {
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;

				&:after {
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background-image: linear-gradient(193deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55));
				}

				&:focus {
					@include global-focus-box();
				}

			}
		}

		figcaption {
			position: absolute;
			bottom: 13px;
			left: 16px;
			z-index: 2;

			font-size: 10px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.6;
			letter-spacing: normal;
			color: var(--color-white);
		}

		img {
			display: block;
			width: 180px;
			height: auto;;

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			min-height: 100%;
			margin: auto;
		}

	}

}

body {
	&::after {
		content: "";

		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;

		z-index: 800;

		background-color: rgba(0, 0, 0, .7);

		opacity: 0;
		visibility: hidden;

		transition: opacity .2s ease-in-out;
	}

	&.search-active {
		&::after {
			visibility: visible;
			opacity: 1;
		}
	}
}

// Fix layering patchwork
.header {
	&__navigation-container {

		&::before {
			display: none;

			@include media(md) {
				display: block;
			}
		}

		.container {
			position: absolute;
			top: 41px;
			right: 0;
			left:0;
			bottom: 0;
			padding: 0;
			width: auto;
			margin: 0;
			overflow: hidden;

			@include media(md) {
				position: static;
				width: 100%;
				overflow: visible;
				margin-left: auto;
				margin-right: auto;
				padding: 0 25px;
			}


		}

		.icon-arrow-left {
			position: relative;
			width: 30px;
			height: 30px;

			@include media(md) {
				display: none;
			}

			&::before {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				z-index: 1;
			}

			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: transparent;
				z-index: 2;
			}
		}
	}

	&__nav {
		top: 0;
		padding-right: 25px;
		right: -25px;
		left: 0;
		width: auto;
		background-color: var(--color-white);


		@include media(md) {
			top: var(--header-height);
			width: var(--header__nav-width);
			padding-right: calc(var(--header__nav-width)/4);
			right: auto;
		}

		&-item {
			&.root {
				position: absolute;
				top: 0;
				right: 25px;
				left: 25px;
				bottom: 0;
				overflow: hidden;

				@include media(md) {
					position: relative;
					overflow: visible;
					right: auto;
					left: auto;
				}
			}
		}

		&:first-child {
			bottom: 0;

			@include media(md) {
				bottom: auto;
			}
		}

	}
	&__zero-level-ul {

		&.u-zero-collapse {
			& > .header__zero-level-li {
				overflow: hidden;
				width: 0;
				height: 0;
				margin: 0;

				&:not(:first-child) {
					margin-top: 0;

					@include media(md) {
						margin-top: 14px;
					}
				}

				@include media(md) {
					overflow: visible;
					width: auto;
					height: auto;
				}
			}
		}
	}

	&__zero-level-li {

		&:not(:first-child) {

			margin-top: 14px;

		}

		.header__nav[data-lv="1"],
		.header__nav[data-lv="2"] {
			height: 0;
			width: 0;
			overflow: hidden;
			right: 0;

			@include media(md) {
				height: auto;
				width: var(--header__nav-width);
				overflow: visible;
				right: auto;
			}
		}

		&.u-zero-active {
			.header__nav[data-lv="1"] {
				z-index: 10;
				height: auto;
				width: auto;
				overflow: visible;

				@include media(md) {
					width: var(--header__nav-width);
				}
			}
		}

	}

	&__first-level-ul {
		&.u-first-collapse {
			& > .header__first-level-li {
				width: 0;
				height: 0;
				margin: 0;

				&:not(:first-child) {
					margin-top: 0;

					@include media(md) {
						margin-top: 14px;
					}
				}

				@include media(md) {
					overflow: visible;
					width: auto;
					height: auto;
				}
			}
		}
	}

	&__first-level-li {

		&:not(:first-child) {
			margin-top: 14px;
		}

		&.u-first-active {
			.header__nav[data-lv="2"] {
				z-index: 11;
				height: auto;
				width: auto;
				overflow: visible;

				@include media(md) {
					width: var(--header__nav-width);
				}

				.header__second-level-li {
					height: auto;
					width: auto;
					overflow: visible;

					&:not(:first-child) {
						margin-top: 14px;
					}
				}
			}
		}
	}

	&__second-level-li {
		height: 0;
		width: 0;
		overflow: hidden;

		&:not(:first-child) {
			margin-top: 0;
		}

	}

	&__teaser {
		z-index: 12;
	}
}

// Header vars
:root {
	--header-height: 65px;
	--header-height-sm: 50px;
	--header-side-col-width: 125px;
	--header__nav-height: 700px;
	--header__nav-width: 220px;

	--header__teaser-width: 390px;
	--header__teaser-height: 464px;
	--header__teaser-list-item-width: 144px;

	--color-header-bg: #f5f5f5;
	--color-header-text-color: var(--color-font-black);
	--color-header-bg-dark: #f5f5f5;
}

@media screen and (orientation: landscape) {
	.user-account-content {
		&.mobile {
			display: none;
		}
	}
}

@include media(sm) {
	:root {
		--header-side-col-width: 200px;
	}

	.user-account-content {
		&.mobile {
			display: none;
		}
	}
}

@include media(md) {
	.header__search-wrapper {
		position: relative;
	}
}

@include media(1280) {

	:root {
		--header__teaser-width: 464px;
		--header__teaser-list-item-width: 180px;
	}

}

@include media(lg) {

	:root {
		--header__nav-width: 300px;
	}

}

// Mobile navigation styling
@media screen and (max-width: $bp-desktop-small - 1) {

	.header__navigation-container {
		background: var(--color-white);
		.icon-arrow-left {
			opacity: 1;
			visibility: visible;
			cursor: pointer;
			position: absolute;
			top: 10px;
			left: 25px;
		}
	}

	.header__teaser-list,
	.header__teaser {
		display: none;
	}

	.header .header__nav:first-child {
		overflow-y: scroll;
	}

	.header__nav[data-lv="1"] {
		left: 0;
	}

	.header__nav[data-lv="2"] {
		left: 0;
	}

	.has-nav-alternate i {
		visibility: hidden !important;
	}

	.ui-btn--icon[data-type="user"] {
		display: none;
	}

	.user-account-content {
		&.mobile {
			z-index: 50;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: var(--color-white);

			border-top: 1px solid var(--color-gray-2)
		}
	}
}

@media screen and (min-width: $bp-tablet) and (max-width: $bp-desktop-small - 1) {

	.ui-btn--icon[data-type="user"] {
		display: flex;
	}
}
