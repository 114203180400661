.B1-hero {
	position: relative;
	min-height: 100vh;

	background-color: var(--color-gray-2);
	height: auto;

	.container {
		align-items: flex-start;
		z-index: 2;

		.ui-btn--primary:hover::before {
			width: 100%;
		}
	}

	.B1-hero__item__fold_scroll_indicator {
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 35px;
		z-index: 99;
		padding: 0;
		display: flex;
		justify-content: center;
		a.ui-btn--icon {
			padding: 0 25px;
			.icon-chevron-down {
				color: white;
				font-size: 60pt;
			}
		}		
	}

	.hidden-anchor {
		height: 1px;
		margin: 0;
		padding: 0;
		position: absolute;
		bottom: 64px;
	}

	.container::before,
	.container::after {
		content: "";
		display: block;
		width: 100%;
		height: 100px;
		background-color: transparent;
	}

	/*
	.container::before {
		height: calc(65px + 62px);

		@include media(md) {
			height: calc(65px + 73px);
		}

		@include media(lg) {
			height: calc(65px + 80px);
		}
	}
	*/

	&.has-arrows {
		.container {
			padding: 0 40px;

			@include media(xs) {
				padding: 0 62px;
			}

			@include media($bp-desktop-medium) {
				padding: 0 25px;
			}
		}
	}

	&.center-text {
		.B1-hero__item {
			.container {
				align-items: center;

				& > * {
					text-align: center;
				}

			}
		}
	}

	.experienceeditor & {
		height: auto;
		min-height: 720px;
	}

	.simple-slider {
		@include background-cover-image();

		height: auto;
		min-height: 100vh;

		&.is-attached .simple-slider-inner,
		&-inner {
			position: relative;
			height: auto;
			min-height: 100vh;
		}

		&.is-attached .slide,
		.slide {
			position: relative;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			height: auto;
			min-height: 100vh;
		}
	}

	&__static-text {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;


		.experienceeditor .B1-hero--text-black &,
		.experienceeditor .B1-hero--text-white & {
			position: relative;
			background-color: var(--color-white) !important;

			.B1-hero__label,
			.B1-hero__heading,
			.B1-hero__desc,
			.B1-hero__desc p,
			a {
				color: var(--color-black) !important;
			}
		}

		.B1-hero__label,
		.B1-hero__heading,
		.B1-hero__desc,
		.B1-hero__desc p,
		a {
			max-width: 100%;
			color: var(--color-white);
		}

		.B1-hero__desc,
		.B1-hero__desc p {
			max-width: 640px;
		}
	}

	&.B1-hero--text-white {
		.B1-hero__static-text {
			.B1-hero__label,
			.B1-hero__heading,
			.B1-hero__desc,
			.B1-hero__desc p,
			a {
				color: var(--color-white);
			}
		}
	}

	&.B1-hero--text-black {
		.B1-hero__static-text {
			.B1-hero__label,
			.B1-hero__heading,
			.B1-hero__desc,
			.B1-hero__desc p,
			a {
				color: var(--color-white);
			}
		}
	}

	&__item {
		@include background-cover-image();

		@include media(md) {
			height: 720px;
		}

		& > * {
			padding-top: 8px;
			padding-bottom: 8px;
		}

		&.has-image,
		&.B1-hero--text-white {
			.B1-hero__item__label,
			.B1-hero__item__heading,
			.B1-hero__item__desc,
			.B1-hero__item__desc p,
			a {
				color: var(--color-white);
			}
		}

		&.B1-hero--text-black {
			.B1-hero__item__label,
			.B1-hero__item__heading,
			.B1-hero__item__desc,
			.B1-hero__item__desc p,
			a {
				color: var(--color-black);
			}
		}
	}

	&__static-text > .container,
	&__item > .container {
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		height: auto;
		min-height: 100vh;
	}

	&__label,
	&__item__label {
		font-weight: var(--font-weight-bold);
		margin-bottom: 1em;
		
		padding-left: 5px;
	}

	&__item__heading,
	&__item__desc,
	&_heading,
	&__desc {
		max-width: 100%;
	}

	&__heading,
	.experienceeditor &__heading p,
	&__item__heading,
	.experienceeditor &__item__heading p {
		font-size: 48px;
		line-height: 56px;

		@include media(sm) {
			font-size: 72px;
			line-height: 72px;
		}

		@include media(md) {
			font-size: 80px;
			line-height: 80px;
		}
	}

	.experienceeditor &__item__heading p,
	.experienceeditor &__heading p {
		margin-bottom: 0;
	}

	&__desc,
	&__desc p,
	&__item__desc,
	&__item__desc p {
		font-size: 18px;
		line-height: 28px;
		color: var(--color-gray-1);
		max-width: 640px;
	}

	a {
		font-weight: var(--font-weight-medium);
	}

	&__video,
	&__item__video {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		padding: 0;
		margin: 0;
		height: 100%;

		& + .container {
			position: relative;
			z-index: 2;
		}
	}
	&__video{
		min-width:100%;
		min-height:100%;
		width:auto;
		height:auto;
		transform: translate(-50%, -50%)!important;
		position:absolute;
		left:50%;
		top:50%;
	}

	.has-background-image &__item {
		background: transparent !important;
	}

	&__video {
		flex: 1;
		object-fit: cover;
	}

	&__gradient {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}

		&::before {
			height: 243px;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0));
			z-index: 2;
		}

		&::after {
			bottom: 0;
			background-image: linear-gradient(243deg, rgba(81, 81, 81, 0), rgba(40,40,40, 1));
			z-index: 1;	
			opacity: 0.4;
			background-image: linear-gradient(198deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));

		}

		.has-gradient & {
			display: block;
		}
	}


	.simple-slider.simple-slider.has-contact-button &__item {
		padding-bottom: 150px;
	}

	&__contact-button {
		display: flex;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 500;

		@media screen and (max-height: 500px) {
			display: none;
		}

		@include media(md) {
			left: auto;
		}

		&__inner {
			width: 100%;
		}

		.container{
			padding:0;

			&::before, &::after{
				display:none;
			}
		}

		a {
			display: block;
			padding: 0 34px;
			width: 100%;
			height: var(--contact-button-height);
			color: var(--color-white);
			font-size: 18px;
			font-weight: var(--font-weight-medium);
			line-height: var(--contact-button-height);
			text-align: center;
			background-color: var(--color-nov-red);
			transition: background-color .1s ease-in-out;
			transition: width .3s ease-in-out;

			&:hover {
				background: var(--color-dark-red);
				text-decoration: none;
			}

			@include media(md) {
				width: 450px;
			}
		}

		&.is-sticky {
			position: fixed;
			bottom: var(--contact-button-height);
			height: 0;
			transition: bottom .3s ease-in-out;
		}
	}

	.nav-buttons {
		.slide-nav-btn {
			font-size: 50px;
		}
	}

	&.B1-hero--text-black {
		.nav-buttons {
			.slide-nav-btn {
				color: var(--color-black);
			}
		}
	}

	.nav-dots {
		.container {
			&::before,
			&::after {
				display: none; 
			}
		}
	}

	.simple-slider.has-contact-button.is-attached .nav-dots {
		bottom: 200px;
	}

	@include media(sm) {
		.simple-slider .slide-nav-btn:first-child {
			left: 12px;
		}

		.simple-slider .slide-nav-btn:last-child {
			right: 12px;
		}

		.simple-slider.has-contact-button.is-attached .nav-dots {
			bottom: 250px;
		}
	}

	@include media(md) {
		.simple-slider .slide-nav-btn:first-child {
			left: 32px;
		}

		.simple-slider .slide-nav-btn:last-child {
			right: 32px;
		}

		.simple-slider.has-contact-button.is-attached .nav-dots {
			bottom: 150px;
		}
	}

	&__item__heading {
		padding: 0;
		margin: 0;
	}

	&__item__desc {
		margin-top: 37px;
	}
	
}

// Edge Only Hacks
_:-ms-lang(x), .B1-hero__video {
	flex: none;
	transform: scale(1.4);
 }

@media only screen and (max-width:$bp-desktop-xlarge) {
	_:-ms-lang(x), .B1-hero__video {
		transform: scale(1);
	}
}

// IE11 Hacks
_:-ms-input-placeholder, :root .B1-hero__item > .container {
	height: 100%;
}

_:-ms-input-placeholder, :root .B1-hero__static-text > .container {
	height: 100%;
}

_:-ms-input-placeholder, :root .B1-hero__item__desc {
	width: 100%;
}

_:-ms-input-placeholder, :root .B1-hero .conainer > * {
	max-width: 100%;
}
