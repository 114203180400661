.J1-sticky-button {
	position: relative;

	height: 70px;

	.container {
		padding: 0;
		transition: all .3s ease-in-out;

		@include media(sm) {
			padding: 0 25px;
		}
	}

    &__container {
		display: flex;

		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 500;


        &.is-open {
            .icon.icon-minus {
				opacity: 1;
            }

            .icon.icon-plus {
				opacity: 0;
            }

            .J1-sticky-button__header p {
				opacity: 1;
				max-height: 50vh;
				margin-top: 11px;
            }

            .J1-sticky-button__content {
				max-height: 50vh;
				overflow-y: auto;

				transition: all .3s ease-in;
            }

        }

        &.is-sticky {
			position: fixed;
			top: 65px;
			height: 0;
			transition: all .2s ease-in;

			.container {
				max-width: 100%;
			}
        }
	}

	&__inner {
		display: block;
		float: right;
		width: 100%;
		overflow: hidden;
		transition: width .3s ease-in-out;

		@include media(sm) {
			margin-right: -25px;
			width: 350px;
		}

		@include media(lg) {
			width: 450px;
		}

        h5 {
            font-size: var(--body-font-size-small);
            line-height: 0.86;
            font-weight: var(--font-weight-medium);
            margin: 0;
            padding: 0;
        }

        p {
            font-size: var(--body-font-size-small);
            line-height: var(--body-line-height-small);
            color: var(--color-gray-1);
            margin: 0;
            padding: 0;
        }

        .icon {
            display: block;
            font-size: var(--subhead-font-size);
            font-weight: var(--font-weight-regular);
            line-height: normal;
            color: var(--color-nov-red);

			opacity: 0;
			transition: opacity .3s ease-in;

            &.icon-plus {
				opacity: 1;
            }
        }

        .J1-sticky-button__header p {
			opacity: 0;
			max-height: 0;
			margin-top: 0;
			transition: all .3s ease-in;
        }
    }

    &__header, &__footer {
        background-color: var(--color-gray-4);
	}

    &__header {
		padding: 20px 25px;

		@include media(sm) {
			padding: 29px 32px;
		}
	}

    &__footer, &__content__list li {
        padding: 29px 32px;
    }

    &__header {
        z-index: 10;
        cursor: pointer;
        position: relative;

        .icon {
            position: absolute;
            right: 32px;
			top: 16px;

			@include media(sm) {
				top: 22px;
			}
        }

        p {
            margin-top: 11px;
            font-size: var(--label-font-size);
            line-height: var(--label-line-height);
            margin-right: 23px;
        }
    }

    &__content {
		max-height: 0;
		transition: all .3s ease-in;

        &__list {
            margin: 0;
            padding: 0;

            background: var(--color-white);

            li {
                margin: 0;
                border-bottom: 1px solid var(--color-gray-3);
                display: flex;
                flex-direction: row;

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    font-size: var(--body-font-size-small);
                    line-height: 0.86;
                    font-weight: var(--font-weight-medium);

                    &.ui-btn--secondary {
                        font-weight: var(--font-weight-regular);
                    }
                }
            }
            &__imageblock {
                width: 30%;
                margin: 0.5em 32px 0 0;

                &__image {
                    width: 100%;
                    height: 0;
                    padding-bottom: 56.25%;
                    overflow: hidden;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
            &__text {
                flex: 1;

                p {
                    padding-bottom: 1em;
                }
            }
        }
    }

    &__footer {
        p {
            margin: 0;
            padding: 0;
        }

        a {
            margin: 0;
            padding: 0;
            font-size: var(--body-font-size-small);
            line-height: 1.36;
        }
    }
}
