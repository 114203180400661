.E2-podcast-feed {

	&__load-more {
		display: none;

		.has-more & {
			display: flex;
			justify-content: center;
		}
	}

	p {
        color: var(--color-gray-1);
        font-size: var(--body-font-size-small);
        line-height: var(--body-line-height-small);
        margin-bottom: 1.75em;
    }

    h3 {
        font-size: var(--body-font-size);
        font-weight: var(--font-weight-medium);
        line-height: 1.5;
        margin: 0 0 0.75em 0;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 374px;

        @include media(747px) {
            max-width: 748px;
        }
        @include media(1221px) {
            max-width: 1122px;
        }
    }

    &__item {
        width: 344px;
        margin: 0 15px 30px 15px;
        background-color: var(--color-gray-4);

        &__player {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 75%;
            overflow: hidden;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                border: 0;
            }
        }

        &__content {
            padding: 32px;

			p#{&}__guests,
            p#{&}__length {
				font-size: var(--body-font-size-small);
				line-height: var(--body-line-height-small);
                margin-bottom: 0.75em;
            }

            .ui-btn--secondary {
				font-size: var(--body-font-size-small);
				line-height: var(--body-line-height-small);
                font-weight: var(--font-weight-medium);
            }
        }
    }
}
