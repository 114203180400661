.D9-image-block {
    position: relative;
    display: block;
	margin-top: var(--standard-padding);
    padding: 0;
    overflow: hidden;

    background: var(--color-black);

    .experienceeditor & {
        background: var(--color-white);
    }

    @include media(md) {
        height: 0;
        padding-bottom: 56.25%;
    }

    &__inner {
        width: 100%;
		padding: 0;

        @include media(md) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

		> .scEmptyPlaceholder {
			height: 100%;
			width: 100%;
		}
    }
	.D-CTA{
		vertical-align: middle;
		color: #da291c;
	}

}
@import 'item';
@import 'splitter';

