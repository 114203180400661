.J2-product-filters {
	position: relative;
	padding-top: 5px;

	background: var(--color-white);

	display: none;

	@include media(md) {
		display: block;
	}

	@include media(md) {
		position: relative;
		float: left;
		margin: 0;
		width: 215px;
	}

	@include media(lg) {
		width: 288px;
	}

	.show-filters & {
		display: block;

		position: sticky;
		top: 121px;
		height: calc(100vh - 121px);
		z-index: 749;

		margin: 0 -15px;
		padding: 0 15px;

		overflow: auto;
	}

	&__mobile-button {
		// position: sticky;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 750;

		display: flex;
		justify-content: flex-end;
		padding: 0 0 50px 0;

		// border-top: 2px solid var(--color-gray-3);

		background: var(--color-white);

		button {
			height: 38px;
			border-radius: 0;
		}

		@include media(md) {
			display: none;
		}
	}

	&__heading {
		margin-bottom: 0;
		padding-bottom: 16px;

		color: var(--color-black);
		font-size: 16px;
		font-weight: var(--font-weight-bold);
		line-height: 24px;

		border-bottom: 2px solid var(--color-gray-3);

		.J2-product-filters__capabilities & {
			margin-bottom: 32px;
		}
	}

	&__reset {
		position: absolute;

		top: 23px;
		right: 15px;

		padding: 0;

		color: var(--color-black);
		font-size: 12px;
		line-height: 16px;

		border: none;
		background: none;

		appearance: none;

		&:focus {
			outline: none;
		}

		@include media(md) {
			right: 0;
		}

		&::after {
			content: '\e901';
			font-family: 'icomoon';
			color: #da291a;
			margin-left: 0.25em;
			position: relative;
			bottom: -0.0625em;
		}
	}

	&__capabilities-list {
		padding-left: 22px;

		ul {
			padding-left: 22px;

			height: 0;

			overflow: hidden;
		}

		.J2-product-filters__item.is-open > ul {
			margin-top: 8px;
			height: auto;
		}

		.J2-product-filters__item {
			position: relative;
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: 0;
			}

			> .icon {
				position: absolute;
				top: 4px;
				left: -22px;

				cursor: pointer;
			}
			&.is-open > .icon {
				transform: rotate(90deg);
			}
		}
	}

	&__capabilities {
		&.is-hidden {
			display: none;
		}
	}

	&__brands {
		margin-top: 34px;

		.J2-product-filters__capabilities.is-hidden + & {
			margin-top: 0;
		}

		&.is-hidden {
			display: none;

			& + .J2-product-filters {
				&__mobile-button {
					margin-top: 34px;
				}
			}
		}

		&.is-open {
			margin-bottom: 34px;
		}
	}

	&__brands-button {
		position: relative;

		display: block;
		margin: 0 0 36px;
		padding: 20px 0 18px;
		width: 100%;

		color: var(--color-black);
		font-size: 12px;
		line-height: 16px;
		text-align: left;

		border: none;
		background: none;
		border-bottom: 2px solid var(--color-gray-3);

		appearance: none;

		.icon {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);

			font-size: 22px;
		}

		.icon-plus {
			display: block;
		}

		.icon-minus {
			display: none;
		}

		.is-open & {
			.icon-plus {
				display: none;
			}

			.icon-minus {
				display: block;
			}
		}


		&:focus {
			outline: none;
		}
	}

	&__brands-list {
		position: relative;
		height: 0;
		padding-left: 22px;

		overflow: hidden;

		.is-open & {
			height: auto;
		}

		li {
			margin-bottom: 8px;
		}
	}

    &__checkbox {
        position: relative;

		display: block;
        padding: 4px 0 4px 32px;


		color: var(--color-black);
		font-size: 12px;
		line-height: 16px;

		cursor: pointer;

        > .custom-checkbox {
            position: absolute;
            top: 4px;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: transparent;
            border: 2px solid var(--color-nov-red);
            border-radius: 4px;

            &:after {
                font-family: 'icomoon' !important;
                speak: none;
                content: "\e912";
                position: absolute;
                display: none;
                color: var(--color-white);
                font-weight: var(--font-weight-bold);
                width: 100%;
                height: 100%;
                text-align: center;
                left: 0;
                top: 0;
                font-size: 11px;
                line-height: 1.2;
			}

			.is-checked > & {
				background-color: var(--color-nov-red);

				&:after {
					display: block;
				}
			}
        }
    }
}
