.B6-product-hero {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	min-height: 100vh;
	background-color: var(--color-gray-2);
	height: auto;
	overflow: hidden;

	&--text-black {
		background-color: var(--color-white);
	}

	.simple-slider {
		position: relative;
		user-select: auto !important;

		&-inner {
			z-index: 1;
		}

		.slide-nav-btn {
			&:first-child {
				left: 0;
			}
			&:last-child {
				right: 0;
			}
		}
	}

	.container {
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		height: auto;
		min-height: 100vh;
		z-index: 2;

		&::before,
		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 65px;
			background-color: transparent;
		}	
		
		&::before {
			height: calc(65px + 62px);

			@include media(md) {
				height: calc(65px + 73px);
			}

			@include media(lg) {
				height: calc(65px + 80px);
			}
		}

		&::after {
			height: calc(var(--contact-button-height) + 43px);
		}
	}

	.B6-product-hero__fold_scroll_indicator {
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 85px;
		z-index: 99;
		display: flex;
    	justify-content: center;
		a.ui-btn--icon {
			padding: 0 25px;
			.icon-chevron-down {
				color: white;
				font-size: 60pt;
			}
		}

		@include media(sm) {
			bottom: 130px;
		}		

		@include media(md) {
			bottom: 35px;
		}		
	}

	.hidden-anchor {
		height: 1px;
		margin: 0;
		padding: 0;
		position: absolute;
    	display: flex;
    	bottom: 65px;
	}

	.nav-buttons {
		.slide-nav-btn {
			z-index: 3;
		}
	}

	.simple-slider .nav-dots,
	.simple-slider.is-attached .nav-dots {
		z-index: 4;
		bottom: calc(var(--contact-button-height) + 20px);

		@include media(md) {
			bottom: calc(var(--contact-button-height) + 10px);
		}
	}

	&.has-arrows {
		.simple-slider {
			&-inner {
				z-index: 1;
			}
		}

		.container {
			padding: 0 36px;

			@include media(md) {
				padding: 0 25px;
			}


			&::after {
				height: calc(var(--contact-button-height) + 60px);
			}
		}
	}

	&__heading {
		margin-bottom: 0;
		font-size: 48px;
		line-height: 56px;

		@include media(sm) {
			font-size: 64px;
			line-height: 64px;
		}

		@include media(md) {
			padding: 0;
			font-size: 80px;
			line-height: 80px;
		}
	}

	&__description {
		margin-top: 37px;

		@include media(sm) {
			width: 592px;
		}

		@include media(md) {
			padding: 0;
			width: 358px;

			.B6-product-hero--full-width-image &,
			.B6-product-hero--no-image & {
				width: 512px;
			}
		}

		@include media(lg) {
			width: 448px;

			.B6-product-hero--full-width-image &,
			.B6-product-hero--no-image & {
				width: 640px;
			}
		}

		&,
		p {
			font-size: 18px;
			line-height: 28px;
		}
	}

	.J7-model-filter {
		margin-top: 44px;
	}


	&__gradient {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}

		&::before {
			height: 243px;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0));
			z-index: 2;
		}

		&::after {
			bottom: 0;
			background-image: linear-gradient(243deg, rgba(81, 81, 81, 0), rgba(40,40,40, 1));
			z-index: 1;
			opacity: 0.4;
		}
	}

	&__contact-button {
		display: flex;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 500;

		@media screen and (max-height: 500px) {
			display: none;
		}

		@include media(md) {
			left: auto;
		}

		&__inner {
			width: 100%;
		}

		a {
			display: block;
			padding: 0 34px;
			width: 100%;
			height: var(--contact-button-height);
			color: var(--color-white);
			font-size: 18px;
			font-weight: var(--font-weight-medium);
			line-height: var(--contact-button-height);
			text-align: center;
			background-color: var(--color-nov-red);
			transition: background-color .1s ease-in-out;
			transition: width .3s ease-in-out;

			&:hover {
				background: var(--color-dark-red);
				text-decoration: none;
			}

			@include media(md) {
				width: 450px;
			}
		}

		&.is-sticky {
			position: fixed;
			bottom: var(--contact-button-height);
			height: 0;
			transition: bottom .3s ease-in-out;
		}
	}

	&--no-image {
		background-color: var(--color-white);

		@include media(sm) {
			min-height: 720px;
		}

		.container {
			@include media(sm) {
				min-height: 720px;
			}
		}
	}

	&--product-image {

		@include media(sm) {
			min-height: 720px;
		}

		&.has-arrows {
			.container {
				position: static;
			}
		}

		.container {
			position: static;

			@include media(sm) {
				min-height: 720px;
			}
		}

		.B6-product-hero__gradient {
			display: none;
		}

		.B6-product-hero__inner {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;

			@include media(md) {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
			}

			.B6-product-hero__content {
				position: relative;
				z-index: 2;
			}
		}

		.simple-slider {
			position: static;
			margin-top: 40px;
			margin-bottom: 40px;
			height: auto;


			.experienceeditor & {
				height: auto;
				padding-bottom: 0;
			}

			@include media(md) {
				margin-left: 10px;

				.experienceeditor & {
					height: auto;
				}
			}

			@include media(lg) {
				.experienceeditor & {
					height: auto;
				}
			}

			.simple-slider-inner {
				position: static;
				margin-left: auto;

				.experienceeditor & {
					height: auto;
				}
			}

			.slide {
				width: 100%;

				img,
				.lazy-video,
				.lazy-video video {
					display: block;
					width: 100%;
					height: 100%;
				}

				img {
					height: auto;
				}
			}

			.slide-nav-btn {
				top: calc(50% - 30px);
			}

			.nav-dots {
				.container {
					display: block;
					min-height: auto;
					text-align: center;

					@include media(md) {
						text-align: left;
					}

					&::before,
					&::after {
						display: none;
					}
				}
			}
		}
	}

	&--full-width-image {
		position: relative;

		.simple-slider {
			position: absolute;
		}

		.container {

			&::before {
				height: 65px;
	
				@include media(md) {
					height: 65px;
				}
	
				@include media(lg) {
					height: 65px;
				}
			}

			.experienceeditor & {
				height: auto;
			}
		}

		.experienceeditor & {
			height: auto;
		}

		.B6-product-hero__heading,
		.B6-product-hero__inner {
			position: relative;
			width: 100%;
		}

		.B6-product-hero__heading,
		.B6-product-hero__content p,
		.B6-product-hero__content label {
			color: var(--color-white);
		}

		.B6-product-hero__content label {
			font-weight: var(--font-weight-bold);
		}

		&.B6-product-hero--text-black {
			.B6-product-hero__heading,
			.B6-product-hero__content p,
			.B6-product-hero__content label {
				color: var(--color-black);
			}

			.B6-product-hero__content label {
				font-weight: var(--font-weight-medium);
			}
		}

		.simple-slider {

			.experienceeditor & {
				position: relative;
				height: auto;
				width: auto;
			}

			.simple-slider-inner {
				height: 100%;

				.experienceeditor & {
					height: auto;
				}
			}

			.slide {
				overflow: hidden;
				width: 100%;

				img,
				.lazy-video,
				.lazy-video-inner,
				.lazy-video video {
					display: block;
					//width: 100%;
					//height: 100%;
					width:auto;
					height:auto;
					transform:translate(50%,-50%);
					position:absolute;
					right:50%;
					top:50%;
					min-width:100%;
					min-height:100%;
				}

				img,
				.lazy-video video {
					object-fit: cover;
				}
			}

			.slide-nav-btn {
				top: calc(50% - 30px);

				@include media (md) {
					font-size: 40px;
				}

				@include media ( 1200) {
					font-size: 50px;
				}
			}

			.nav-buttons {
				z-index: 2;
			}

			.nav-dots {
				.container {
					display: block;
					min-height: auto;
					text-align: center;

					@include media(md) {
						text-align: left;
					}

					&::before,
					&::after {
						display: none;
					}
				}
			}
		}
	}
}

// IE11 Hacks
_:-ms-input-placeholder, :root .B6-product-hero {
	height: 100%;
}

_:-ms-input-placeholder, :root .B6-product-hero .container {
	height: 100%;
}


_:-ms-input-placeholder, :root .B6-product-hero--product-image .B6-product-hero__inner {
	height: auto;
	flex: 0 0 auto;
}
