.D8-faq-panel__card {
    border-bottom: 1px solid var(--color-gray-3);
    
    &:first-child {
        border-top: 1px solid var(--color-gray-3);
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--label-font-size);
        line-height: normal;
        height: 56px;
        &__name, &__title {
            flex-grow: 1;
        }
        &__indicator {
            width: 56px;
            .icon {
                text-align: center;
                font-size: var(--subhead-font-size);
                font-weight: var(--font-weight-regular);
                line-height: normal;
                color: var(--color-nov-red);
            }
        }
    }

    &__header__title, &__content {
        color: var(--color-gray-1);
    }

    &__content {
        display: flex;

        img {
            margin-right: 1em;
            margin-top: 0.5em;
        }

        p {
            display: block;
            font-size: var(--body-font-size-small);
            line-height: var(--body-line-height-small);
            padding-bottom: 1em;
        }
    }

    @include media($bp-tablet) {

        &__header {
            font-size: var(--body-font-size);
        }

    }
}