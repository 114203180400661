/**
* UI Tag
*/
.tags {
	display:flex;
	flex-wrap: wrap;
}

.ui-tag {
	display: inline-block;
	position: relative;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.5px;
	text-align: left;
	border-radius: 6px;
	padding: 4px 8px;
	margin: 0;
	color: var(--color-white);
	background-color:var(--color-gray-1);

	& + & {
		margin-left: calc(var(--spacing)/2);
	}

	&.teal {
		background-color: var(--color-nov-red);
	}
	&.blue {
		background-color: var(--color-black);
	}
	&.red {
		background-color: var(--color-nov-red);
	}
	&.yellow {
		background-color: var(--color-black);
		color: var(--color-black);
		font-weight: 500;
	}

	&:first-letter {
		text-transform: uppercase;
	}
}
