.J4-dynamic-card-list {
	position: relative;

	&__mobile-filter {
		position: sticky;
		top: var(--header-height);
		left: 0;
		right: 0;
		z-index: 749;

		display: flex;
		justify-content: space-between;
		margin: 0 -25px;
		padding: 15px 25px;

		border-top: 1px solid var(--color-gray-3);
		border-bottom: 1px solid var(--color-gray-3);

		background: var(--color-white);

		@include media(md) {
			display: none;
		}

		button {
			appearance: none;

			padding: 0;

			font-size: 14px;
			font-weight: 600;
			line-height: 24px;

			background: none;
			border: none;

			i {
				position: relative;
				top: 3px;
				color: var(--color-nov-red);
			}

			&:focus {
				@include global-focus-box;
			}
		}
	}

	&.show-filters {
		button {
			i {
				&::after {
					transform: rotate(180deg);
				}
			}
		}
	}

	.disabled {
		display: none;
	}

	&__mobile-count {
		margin: 0;

		font-size: 14px;
		line-height: 24px;
	}

	.container {
		position: relative;

		min-height: 300px;

		@include media(md) {
			overflow: hidden;
		}
	}

	&__list {
		margin-top: 24px;

		@include media(md) {
			float: left;
			margin-top: 0;
			padding-left: 42px;
			width: calc(100% - 215px);
		}

		@include media(lg) {
			width: calc(100% - 288px);
		}

		&__count {
			display: none;

			margin-top: 14px;

			text-align: right;

			@include media(md) {
				display: block;
			}
		}
	}

	&__product-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		// This is a hack that only works because we're using a 3 column grid. Anything bigger and it won't work.
		&::after {
			content: '';

			@include media(md) {
				width: calc(33% - 16px);
			}
		}
	}

	&__pagination {
		display: flex;

		button {
			appearance: none;

			margin-top: 16px;
			padding: 0;

			font-size: 24px;
			line-height: 1;

			background: none;
			border: none;

			&:focus {
				outline: none;
			}

			i {
				color: var(--color-nov-red);
				font-size: 24px;
				line-height: 1;
			}

			&.is-disabled {
				pointer-events: none;
				i {
					color: var(--color-gray-2);
				}
			}
		}
	}

	&__loading {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		height: 100%;
		width: 100%;

		display: none;
		justify-content: center;
		align-items: center;

		background-color: rgba(255, 255, 255, 0.8);

		.is-loading & {
			display: flex;
		}
	}

	.C1-card__title {
		height: 72px;
		-webkit-line-clamp: 3;
	}

	label[for="J4-sorting-selector"] {
		margin: 0 0 24px;
		display: inline-block;

		@include media(md) {
			float: left;
			margin: 0;
		}
	}

	.custom-select-wrapper {
		border-bottom: 0;
		color: #707070;
		font-size: 12px;
		margin: 0 1.5em;
	
		&::before {
			bottom: calc(0.0625em + 5px);
			color: #1b1b1b;
			font-size: 16px;
			left: auto;
			right: 0;
			top: auto;
			transform: none;
		}

		&.is-disabled {
			&::before {
				color: #707070;
			}
			select {
				color: #707070;
			}
		}

		select {
			color: #1b1b1b;
			cursor: pointer;
			font-size: 16px;
			padding: 5px 0;
			width: 100%;
			-webkit-appearance: none;

			&:disabled {
				cursor: default;
			}
		}

		option {
			font-weight: 400;
		}

	}

	#showAllToggle {
		padding: 0;
		font-size: 16px;
		line-height: 16px;
		border: none;
		background: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin-left: auto;
		position: relative;

		&::after {
			content: "\e90e";
			font-family: 'icomoon';
			text-transform: none;
			speak: none;
			color: var(--color-nov-red);
			position: relative;
			margin-left: 0.25em;
			bottom: -0.0625em;
		}

	}

	.js-current-page {
		display: none;
	}
}
