.search-results {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 165px;
		background: var(--color-black);
	}

	&--has-main-feature-modules {
		&::before {
			height: 380px;
		}
	}

	.container {
		position: relative;
	}

	&__input {
		position: relative;
		width: 100%;
		color: var(--color-white);

		@include media(sm) {
			margin: 0 auto 20px;
			max-width: 736px;
		}

		input {
			height: 35px;
			width: 100%;
			border: none;
			border-bottom: 2px solid var(--color-white);
			background: none;
			outline: 0;
			color: var(--color-white);

			&::-ms-clear {
				display: none;
			}
		}

		button.ui-btn--icon {
			position: absolute;
			top: 0;
			right: 15px;

			i {
				color: var(--color-white);
				font-size: 24px;
			}
		}
	}
}
