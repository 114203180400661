.G1-content-slider {
    position: relative;
    width: 100%;
    height: 650px;
    max-width: calc(var(--module-inner-width));
    min-width: 300px;
    margin: 50px auto;

	.experienceeditor & {
		height: auto;
	}

    &__item {
        display: flex;
        flex-wrap: wrap;

		.experienceeditor & {
			height: 550px;
			margin-bottom: 30px;
		}

        & > * {
            height: 50%;
            @include media(sm) {
                width: 33.33% !important;
                height: 100%;
            }
        }

        &__left {
            width: calc(100% - 116px);
            padding: 16px 32px 0 32px;
            position: relative;
            order: 1;

            @include media(sm) {
                order: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 56px;
            }

            &__label {
                font-size: 12px;
                margin: 8px 0;
                color: var(--color-gray-1);
            }

            &__heading {
                font-size: 24px;
                line-height: 32px;
                @include media(md) {
                    font-size: 48px;
                    line-height: 56px;
                }
            }

            &__cta-btn {
                background: var(--color-nov-red);
                color: var(--color-white);
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 56px;
                padding: 16px 32px;
                text-decoration: none !important;
                @include media(sm) {
                    cursor: pointer;
                    height: 80px;
                    padding: 24px 32px;
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: bold;
                }
            }
        }

        &__middle {
            width: 100%;
            order: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

			&.show-desktop {
				display: none;
            }

            @include media(sm) {
				order: 1;

				&.show-desktop {
					display: block;
				}
				&.show-mobile {
					display: none;
                }
                &.show-mobile {
                    display: none;
                    
                    &.lazy-video-container {
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        overflow: hidden;
                    }
				}
            }
        }

        &__right {
            width: 116px;
            order: 2;
            text-align: center;
            color: var(--color-white);


            &__percentage {
                height: 100%;
                padding-bottom: 56px;
                background: var(--color-nov-red);
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;

                padding-left: 16px;
                padding-right: 16px;

                @include media(sm) {
                    padding: 0 50px;
                }

                &__heading {
                    font-size: 48px;
                    line-height: 52px;
                    @include media(sm) {
                        font-size: 104px;
                        line-height: 104px;
                    }
                }

                &__desc {
                    font-size: 12px;
                    line-height: 14px;
                    @include media(sm) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                @include media(sm) {
                    height: 50%;
                    padding-bottom: 0;
                }
            }

            &__small-image {
                @include background-cover-image();

                position: absolute;
                height: 0;
                width: 0;
                overflow: hidden;

                @include media(sm) {
                    position: relative;
                    display: block;
                    width: auto;
					height: 50%;
                    overflow: visible;
                }
            }

        }
    }
    .nav-buttons {
        height: 56px;
        bottom: 0;
        width: 116px;
        right: 0;
        position: absolute;

        @include media(sm) {
            right: auto;
            left: 33.33%;
            width: 160px;
            height: 80px;
        }

        .slide-nav-btn {
            background: #353535;
            height: 100%;
            width: 50%;

            top: auto !important;
            bottom: 0 !important;
            padding-left: 12px;
            padding-top: 12px;
            color: var(--color-nov-red) !important;

            @include media(sm) {
                padding-left: 26px;
                padding-top: 24px;
            }

            &:first-child {
                left: auto !important;
                right: auto;
            }

            &:last-child {
                right: 0 !important;
                &::after {
                    position: absolute;
                    content: '';
                    height: 100%;
                    top: 0;
                    left: 0;
                    width: 1px;
                    background: var(--color-gray-1);
                }
            }
        }
    }

    .nav-dots {
        bottom: 96px !important;
        left: 32px !important;
        right: auto !important;

        @include media(sm) {
            bottom: 112px;
        }

        .nav-dot {
            width: 12px !important;
            height: 12px !important;
            margin-right: 6px !important;
        }
    }

    .lazy-video {
        width: 100%;
        height: 100%;
    }

    // .lazy-video-inner {
    //     width: auto;
    //     height: auto;
    //     right: 0;
    //     bottom: 0;
    // }
}


// IE10+ hack to mimic object-fit for video elements
_:-ms-input-placeholder, :root .G1-content-slider .lazy-video-container .lazy-video-inner video.ie-video-target__full-height {
	width: auto;
	position: absolute;
	left: 50%;
	top: 0;
    transform: translateX(-50%);
    z-index: 2;
}

_:-ms-input-placeholder, :root .G1-content-slider .lazy-video-container .lazy-video-inner video.ie-video-target__full-width {
	height: auto;
	position: absolute;
	left: 0;
	top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

// IE10+ hack to mimic video poster
_:-ms-input-placeholder, :root .G1-content-slider .lazy-video-container .lazy-video-inner .ie-poster {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    z-index: 1;
}

// Edge 12+ hack to mimic object-fit for video elements
@supports (-ms-ime-align:auto) {
	.G1-content-slider .lazy-video-container .lazy-video-inner video.ie-video-target__full-height {
		width: auto;
		position: absolute;
		left: 50%;
		top: 0;
        transform: translateX(-50%);
        z-index: 2;
	}
	.G1-content-slider .lazy-video-container .lazy-video-inner video.ie-video-target__full-width {
		height: auto;
		position: absolute;
		left: 0;
		top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }

    // Edge 12+ hack to mimic video poster
    .G1-content-slider .lazy-video-container .lazy-video-inner .ie-poster {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        z-index: 1;
    }
}
