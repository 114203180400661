/**
* UI Letter masker
*/

.letter-masker {
	position: relative;
	display: inline-block;
	overflow: hidden;
	vertical-align: top;

	.masker {
		display: inline-flex;
		flex-direction: column;
		height: auto;
		position: absolute;
		width: 100%;
		z-index: 1;
		transition-property: transform;
	}

	.letter {
		font-family: inherit;
		font-size: inherit;
		display: inline-block;
		margin: 0;
		padding: 0;
	}

	.base {
		visibility: hidden;
		opacity: 0;
		z-index: 0;
		pointer-events: none;
		padding: 0 10px;
	}

	// animate
	&.animate-up {
		.masker {
			transition: transform var(--letter-masker-duration);
			transform: translate3d(0, -50%, 0);
		}
	}
}

:root {
	--letter-masker-duration: 250ms;
}
