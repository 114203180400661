.J9-filtered-brand-capability-list {
	&__filters {
		display: flex;
		justify-content: center;

		margin: 0 auto 85px;
		max-width: 325px;

		border-bottom: 1px solid var(--color-gray-2);

		input {
			flex-grow: 1;
			padding: 0 10px;
			line-height: 32px;

			border: 0;

			&:focus {
				outline: none;
			}
		}

		.icon-search {
			color: var(--color-nov-red);
			font-size: 24px;
		}
	}

	&__container {
		column-count: 1;
		column-fill: balance;
		perspective: 1;

		margin: 0 auto;
		max-width: 640px;

		@include media(sm) {
			column-count: 3;
		}
	}

	&__card {
		margin: 0 0 15px;
		padding: 0;

		font-size: 16px;
		line-height: 24px;

		a {
			color: var(--color-black);

			&:hover {
				color: var(--color-nov-red);
				text-decoration: none;
			}
		}
	}
}
