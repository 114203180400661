.A3-footer {
    margin-top: var(--standard-padding);

	.show-mobile,
	.hide-desktop {
		display: block;
	}
	.show-desktop {
		display: none;
	}

    &__inner {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    &__logo {
        width: 50%;
        height: 80px;
        background-color: var(--color-gray-4);
        position: relative;
        p {
            text-align: center;
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
        }
    }
    &__contact {
        width: 50%;
        height: 80px;

        color: var(--color-white);
        font-size: var(--ui-btn-font-size);
        line-height: 80px;
        font-weight: var(--font-weight-medium);
        text-align: center;

        background-color: var(--color-nov-red);

        transition: background-color .1s ease-in-out;

        background-color: var(--color-nov-red);
        outline: none;
    }
    .A3-footer__contact:hover {
        background: var(--color-dark-red);
        text-decoration: none;
    }
    &__logo .main-logo {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
    }
    &__nav, &__social, &__legal {
        width: 100%;
    }
    &__nav {

		&__submenu {
			padding-right: 20px;
		}

        ul, li {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        ul li {
            width: 100%;
            & ul {
                display: block;
                padding: 0 59px 0 59px;
                background-color: var(--color-gray-4);
                & li {
                    width: auto;
                    padding: 14px 0;
                    & a {
                        font-size: var(--body-font-size-small);
                        line-height: var(--body-line-height-small);
                        color: var(--color-gray-1);
                        font-weight: var(--font-weight-regular);
                        outline: none;
                        height: auto;
                        border: 0;
                    }
                    &:first-child {
                        padding-top: 40px;
                    }
                    &:last-child {
                        padding-bottom: 48px;
                    }
                }
            }
		}

		&__link,
        &__heading {
            position: relative;
            display: block;
            height: 54px;
            width: 100%;
            color: var(--color-black);
            font-weight: var(--font-weight-medium);
            border-bottom: 1px solid var(--color-gray-3);
			outline: none;

			&:hover {
				text-decoration: none;
			}


            span {
                position: absolute;
                top: 50%;
                left: 32px;
                transform: translateY(-50%);
            }
            i {
                position: absolute;
                top: calc(50% + 2px);
                right: 34px;
                transform: translateY(-50%);
                color: var(--color-nov-red);
                font-size: 24px;
                &.when-open {
                    top: calc(50% - 2px);
                    transform: rotate(180deg) translateY(50%);
                }
            }
		}
    }
    &__social {
        height: 119px;
        ul {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        ul, li {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: nowrap;
        }
        li {
            display: block;
            flex-grow: 1;
            text-align: center;
        }
        a {
            text-decoration: none;
            color: transparent;
            outline: none;
        }
        i {
            font-size: 30px;;
            color: var(--color-gray-1);
        }
    }
    &__legal, &__legal p {
        font-size: var(--body-font-size-small);
        line-height: var(--body-line-height-small);
        font-weight: var(--font-weight-regular);
    }
    &__legal {
        // height: 96px;
        background-color: var(--color-gray-4);
        padding: 28px 32px 20px 32px;
        ul, li {
            list-style: none;
            padding: 0;
            margin: 0;
            display: inline-block;
        }
        ul > li {
            padding-bottom: 16px;
            &::after {
                content: " |";
            }
            &:last-child::after {
                content: "";
            }
            a {
                color: inherit;
                padding: 0 5px;
            }
            &:first-child > a {
                padding-left: 0;
            }
        }
    }

    // Order mobile
    &__logo {
        order: 1;
    }
    &__contact {
        order: 3;
    }
    &__nav {
        order: 4;
    }
    &__social {
        order: 5;
    }
    &__legal {
        order: 6;
    }

    @include media(sm) {
        &__logo {
            flex-grow: 1;
        }
        &__contact {
            width: 205px;
        }
        &__social {
            ul {
                max-width: 61%;
            }
        }
        &__legal {
            position: relative;
            height: 88px;
            ul li {
                padding: 0;
            }
            &__links, &__copyright {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
            }
            &__copyright {
                right: 32px;
            }
        }
    }

    @include media(md) {
		.show-mobile {
			display: none;
		}
		.hide-desktop {
			display: none;
		}
		.show-desktop {
			display: block;
		}

        &__logo, &__contact, &__social {
            height: 104px;
            flex-grow: 0;
        }
        &__logo {
            width: calc(20% + 12px);
            .main-logo {
                left: 79px;
            }
        }
        &__contact {
            width: 240px;
            line-height: 104px;
        }
        &__nav {
            ul {
                width: auto;
                height: auto;
                display: flex;
                padding: 30px 80px 30px 80px;
                justify-content: space-between;
                & li {
                    width: auto;
                    height: auto;
                    flex-grow: 1;
                    flex: 1;
                    & ul {
                        width: 100%;
                        display: block;
                        background-color: transparent;
                        padding: 0;
                        padding-top: 11px;
                        & li {
                            padding: 6px 0;
                            &:first-child {
                                padding-top: 3px;
                            }
                            &:last-child {
                                padding-bottom: 3px;
                            }
                        }
                    }
                }
            }
            &__heading {
                width: auto;
                height: auto;
                span {
					position: static;
					transform: translateY(0);
                }
                border: 0;
            }
            &__indicator {
                display: none;
            }
        }
        &__legal {
            padding: 28px 80px 20px 80px;
            &__copyright {
                right: 80px;
            }
        }

        // Order desktop
        &__social {
            background-color: var(--color-gray-4);
            order: 2;
            width: auto;
            flex-grow: 1;
            li {
                text-align: left;
            }
        }
    }
}
