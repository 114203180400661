.D9-image-block__item {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    flex-direction: column;

    overflow: hidden;

	> .scEmptyPlaceholder {
		width: 100%;
		height: 100%;
	}

    &__image {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: cover;

        // Scaling slightly to fill "in-between" pixels on retina displays
        transform: scale(1.01);

        // transition: transform .5s ease-in-out;

        // .D9-image-block__item:hover & {
        //     transform: scale(1.05);
        //     transition: transform 8s linear;
        //  }
    }

    &.lazy-video-container {
        .lazy-video {
            height: 100%;

            .lazy-video-inner {
                position: relative;
        
                video {
                    display: block;
                }
            }
        }
    }

    &__gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(199deg, rgba(81, 81, 81, 0) 60%, rgba(40, 40, 40, .8));
        mix-blend-mode: multiply;
    }

    &__caption {
        position: absolute;
        bottom: 0;
        left: 0;
        mix-blend-mode: normal;
        color: var(--color-white);
        font-size: var(--label-font-size);
        line-height: normal;
        font-weight: var(--font-weight-medium);
        padding: 2em;
    }

    &__headline {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        margin: 0;
    }

    .ui-btn--secondary {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        margin: 0.5em 0 0;
        padding: 0;
    }

    @include media(sm) {
        &__caption {
            font-size: var(--body-font-size);
            line-height: 1.5;
        }
    }

}
