:root {
	--c2-baseZ: 100;
}

.C2-flip-card {

	.D4-title-block {
		margin-bottom: 80px;
	}

	h5 {
		font-size: var(--subhead-font-size);
		line-height: 1.33;
	}

	&__wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;

		@include media($bp-tablet) {
			flex-direction: row;
			flex-wrap: wrap;

			&::before {
				content: "";
				padding-top: 100%;
			}
		}

		@include media($bp-desktop-small) {
			&::before {
				padding-top: 50%;
			}
		}
	}

	&__card, &__splitter {
		display: flex;
		flex-grow: 1;

		@include media($bp-tablet) {
			flex-basis: 50%;
		}

		@include media($bp-desktop-small) {
			flex-basis: 25%;
		}
	}

	&__card {
		width: 100%;
		position: relative;
		perspective: 1000;
		z-index: var(--c2-baseZ);

		&::before {
			content: "";
			padding-top: 200%;
		}

		&__container {
			position: relative;
			width: 100%;
			// backface-visibility: hidden;
			transform-style: preserve-3d;
			transform-origin: 50% 50%;
			transition: all 0.2s linear;
		}

		&__content {
			position: absolute;
			width: 100%;
			height: 100%;
			backface-visibility: hidden;

			visibility: visible;
			perspective: 0;
			transform: translate3d(0, 0, 0);
			transform-style: preserve-3d;


			p {
				font-size: var(--body-font-size-small);
				line-height: var(--body-line-height-small);
				color: var(--color-gray-2);


				margin: 1em;
				max-width: 100%;

				&.is-small {
					font-size: var(--label-font-size);
					margin: 0;
				}
			}

			hr {
				width: 2em;
				border-color: var(--color-nov-red);
			}

			h2,
			h5 {
				margin: 0;
			}

			.ui-btn--secondary {
				color: var(--color-white);
				margin: 0;
				margin-top: 1em;

			}

			&__wrapper {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				background-color: var(--color-black);
				color: var(--color-white);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;

				// fixes issue with elements on top of lazy-video
				>*:not(.lazy-video) {
					z-index: var(--c2-baseZ);
					backface-visibility: hidden;
					max-width: 100%;
				}
			}

			&__fadedbg {
				top: 0;
				left: 0;
				position: absolute;
				display: block;
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				backface-visibility: hidden;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
				opacity: 0.2;
				// mix-blend-mode: screen;
			}

			&__indicator {
				position: absolute;
				top: 20px;
				right: 20px;

				.icon {
					font-size: var(--subhead-font-size);
					color: var(--color-white);
				}
			}

			&.back {
				transform: rotateY(180deg);
				background-blend-mode: darken;
			}
		}

		&.is-flipped {
			.C2-flip-card__card__container {
				transform: rotateY(180deg);
			}
		}

		&.is-flipping {
			z-index: calc(var(--c2-baseZ) + 1);
		}
	}

	&__splitter {
		flex-direction: column;
	}

	&__splitter &__card {
		&::before {
			padding-top: 100%;
		}
	}
}

// IE 11 fixes
.ie-11  .C2-flip-card {
	&__card.is-flipped {
		.C2-flip-card__card__content {
			backface-visibility: visible;
		}
	}
}
