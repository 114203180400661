.J8-product-tabs {
    &__navigation-desktop {
        display: none;
        justify-content: center;
        border-bottom: 1px solid var(--color-gray-3);
        /* NOV-308 */
        position:sticky;
        z-index: 800;
        top: 65px;
        padding-top: 15px;
        margin-top: -15px;
        background-color: #fff;

        li {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
            position: relative;
            top: 2px;
            border-bottom: 3px solid var(--color-gray-3);
            padding: 0.5em 3.5em;
            text-align: center;

            &:hover, &.is-open {
                border-color: var(--color-nov-red);
			}
			&:hover {
				cursor: pointer;
			}
		}
	}

	&__icon {
		display: block;
		margin-bottom: 18px;
		width: 24px;
		height: 24px;
	}

    &__header, &__content {
        padding: 1em;
        border-style: solid;
        border-color: var(--color-gray-3);
        border-width: 0 0 1px 0;
	}

	&__content {
		padding: 1em 0;
	}

    &__header {
        position: relative;
    }

    &__tab:first-child &__header {
        border-width: 1px 0 1px 0
    }

    &__tab-title {
        font-size: var(--body-font-size);
        line-height: 2;
        font-weight: var(--font-weight-bold);
    }

    &__indicator {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        .icon {
            text-align: center;
            font-size: var(--subhead-font-size);
            font-weight: var(--font-weight-regular);
            line-height: normal;
            color: var(--color-nov-red);
        }
    }

    @include media($bp-desktop-small) {
        &__navigation-desktop {
            display: flex;
        }

        &__tab {
            width: 100%;
        }

        &__header {
            display: none;
        }

        &__content {
            border: 0;
			display: none;

			.experienceeditor & {
				display: block;
				min-height: 200px;
			}
        }

		&__tab.is-open  &__content {
            display: block;
        }

        &__tab:first-child &__header {
            border-width: 0 0 1px 0;
        }

        &__tab-title {
            color: var(--color-gray-1);
            font-weight: var(--font-weight-regular);
        }
	}

	.J5-features-list,
	.J6-product-table {
		.container {
			padding: 0 16px;
		}
	}

	.E5-document-list {
		.container {
			padding: 0;
		}
	}
}

/* NOV-308 */

  main section{
    opacity: 1;
    transition: opacity 250ms; 

    &.fade{
        opacity: 0;
    }

  }
  
