.D4-title-block {
    &-inner {
        max-width: 736px;
        margin: 0 auto;
	}

	&__body,
	&__body p {
		color: var(--color-gray-1);
    }

    &__body p {

        &:last-child {

            margin-bottom: 0;
            
        }
    }
    
    h2 {
        margin: 0 0 20px 0;
        padding: 0;
    }

    .ui-btn--secondary {
        margin-top: 30px;
    }

    @include media(md) {
        h2 {
            font-size: 48px;
        }
    }
}
