:root {
    --E3-filtered-document-list-item-height: 104px;
}
.E3-filtered-document-list {

	&__search {
		display: flex;
		justify-content: center;

		margin: 0 auto 85px;
		max-width: 325px;

		border-bottom: 1px solid var(--color-gray-2);

		input {
			flex-grow: 1;
			padding: 0 10px;
			line-height: 32px;

			border: 0;

			&:focus {
				outline: none;
			}
		}

		.icon-search {
			color: var(--color-nov-red);
			font-size: 24px;
		}
	}

	&__container {
		@include media(md) {
			display: flex;
			align-items: stretch;
		}
	}

	&__items {
		width: 100%; // Replaced / Removed flex: 1 0 auto, causing element to expand past its container
	}

	&__category {
		&__link {
			color: var(--color-black);
			font-size: 14px;
			line-height: 24px;

			&.is-active {
				font-weight: var(--font-weight-medium);
			}
		}
	}

    &__list {

        &__item {
			position: relative;

			display: flex;
			justify-content: space-between;
			align-items: stretch;

            margin: 0 0 25px 0;
            width: 100%;
            min-height: var(--E3-filtered-document-list-item-height);

			border: 1px solid var(--color-gray-3);

            &:last-child {
                margin: 0;
            }

            strong {
				display: block;
				margin: 0;
				width: 100%;

				max-height: 4.29em;
				overflow: hidden;

                font-size: var(--body-font-size-small);
                line-height: 1.43;
                font-weight: var(--font-weight-medium);
				color: var(--color-black);

            }

            p {
                margin: 0;
                font-size: 12px;
                line-height: 16px;
				color: var(--color-gray-1);
				display: none;

				@include media(sm) {
					display: block;
				}
            }

            &__label {
				position: relative;
				cursor: pointer;

				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				padding: 10px 17px 10px 60px;

				width: 100%;

				min-height: var(--E3-filtered-document-list-item-height); // Fix IE11 stretch

				&::before {
					content: "";

					position: absolute;
					top: 50%;
					left: 20px;

					height: 12px;
					width: 12px;

					border: 2px solid var(--color-nov-red);
					border-radius: 4px;

					transform: translateY(-50%);
				}

                &::after {
                    content: "";
                    display: none;

					position: absolute;
					top: 50%;
					left: 23px;

					transform: translateY(-50%);

					color: var(--color-white);
					font-family: 'icomoon';
					font-size: 10px;
					font-weight: var(--font-weight-medium);
                }

				&.is-checked {
					background-color: var(--color-nov-red);

					strong, p {
						color:  var(--color-white);
					}

					&::before {
						border-color:  var(--color-white);
					}
					&::after {
						content: '\e912';
						display: block;
					}

					@include media(sm) {
						background-color: var(--color-white);

						strong {
							color: var(--color-black);
						}

						p {
							color: var(--color-gray-1);
						}

						&::before {
							border-color:  var(--color-nov-red);
							background-color:  var(--color-nov-red);
						}
					}
				}

				input {
					position: absolute;
					top: -100%;
					left: -100%;

					visibility: hidden;
				}
            }

            &__link {
				align-self: stretch;

				display: flex;
				justify-content: center;
				align-items: center;
				flex: 0 0 calc(var(--E3-filtered-document-list-item-height) - 10px);

                width: calc(var(--E3-filtered-document-list-item-height) - 10px);

				background-color: var(--color-gray-4);

                .icon {
                    color: var(--color-nov-red);
                }

                &:hover, &:active {
					background-color: var(--color-nov-red);

                    .icon {
                        color: var(--color-white);
                    }
                }
            }
        }
	}

	label.custom-select-wrapper {
		margin-bottom: 15px;

		font-size: 12px;
		color: var(--color-gray-1);

		@include media(sm) {
			max-width: 300px;
		}

		&::before {
			bottom: 6px;
			top: auto;
			transform: none;
			font-size: 16px;
		}

		select {
			padding: 5px 0;
			font-size: 14px;
			font-weight: 600;
			-webkit-appearance: none; // Remove appearance on safari

			option.business-unit {
				appearance: none;
				padding-left: 15px;
			}

			&::-ms-expand {
				display: none; // Removes select appearance in IE
			}
		}
	}

	&__filter-form {
		@include media(sm) {
			display: inline-block;
			margin-right: 20px;
		}
	}

	&__category-list {
		display: none;

		@include media(md) {
			display: block;
		}
	}

	&__mobile-category.custom-select-wrapper {
		display: block;

		@include media(sm) {
			display: inline-block;
		}

		@include media(md) {
			display: none;
		}
	}

	&__download {
		display: none;

		&-button {
			display: inline-block;
			padding: 15px 30px;

			color: white;

			background-color: var(--color-nov-red);
			transition: background-color .1s ease-in-out;
			text-decoration: none;
		}
		.E3-filtered-document-list__download-button:hover {
			background-color: var(--color-dark-red);
			text-decoration: none;
		}

		@include media(md) {
			display: block;

		}
	}

	&__mobile-footer {
		position: sticky;
		left: 0;
		right: 0;
		bottom: 0;

		margin: 0 -25px;
		padding: 15px 25px;

		background-color: white;
		border-top: 1px solid var(--color-gray-3);
		border-bottom: 1px solid var(--color-gray-3);

		.E3-filtered-document-list__download {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				margin: 0;
			}

			&-button {
				display: block;
				padding: 15px 30px;

				color: white;

				background-color: var(--color-nov-red);

				&:hover {
					text-decoration: none;
				}
			}
		}

		@include media(md) {
			display: none;
		}
	}

	.container {
		@include media(md) {

			@keyframes hide {
				0%{
					opacity:1;
				}
				100%{
					opacity:0;
				}
			}

			.hidden_by_search{
				animation:hide;
				animation-duration: 750ms;
				display: none;
			}

			@keyframes show {
				0%{
					opacity:0;
				}
				100%{
					opacity:1;
					display: flex;
				}
			}

			.shown_by_search {
				animation:show;
				animation-duration: 750ms;
				display: flex;
			}

			@keyframes hide {
				0%{
					opacity:1;
				}
				100%{
					opacity:0;
				}
			}

			.hidden_by_search{
				animation:hide;
				animation-duration: 750ms;
				display: none;
			}

			@keyframes show {
				0%{
					opacity:0;
				}
				100%{
					opacity:1;
					display: flex;
				}
			}

			.shown_by_search {
				animation:show;
				animation-duration: 750ms;
				display: flex;
			}

			.E3-filtered-document-list__filters {
				flex: 0 0 280px;
				width: 280px;

				border-right: 1px solid var(--color-gray-3);
			}

			.E3-filtered-document-list__filter-form {
				position: sticky;
				top: 100px;

				background-color: var(--color-white);
			}

			.E3-filtered-document-list__category-list {
				position: sticky;
				top: 166px;

				padding-top: 0;
				padding-bottom: 150px;

				background-color: var(--color-white);

				li {
					margin-bottom: 10px;
				}
			}

			.E3-filtered-document-list__download {
				position: sticky;
				top: calc(100vh - 150px);
				margin-top: auto;
				padding: 25px 0;

				border-top: 1px solid var(--color-gray-3);
				background-color: var(--color-white);
			}

			.E3-filtered-document-list__items {
				padding: 0;
				margin-left: 25px;
			}
		}

		@include media(lg) {
			.E3-filtered-document-list__filters {
				flex: 0 0 300px;
				width: 300px;
			}

			.E3-filtered-document-list__items {
				margin-left: 50px;
			}
		}
	}


}
