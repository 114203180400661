/**
* Base typographical setup (vertical rythm etc)
*/


h1,
h2,
h3,
h4,
h5 {
	letter-spacing: 0;
}

h1,
.h1 {
	font-size: var(--h1-font-size);
	line-height: var(--h1-line-height);
}

h2,
.h2 {
	font-size: var(--h2-font-size);
	line-height: var(--h2-line-height);
}

h3,
.h3 {
	font-size: var(--h3-font-size);
	line-height: var(--h3-line-height);
}

.h4,
h4 {
	font-size: var(--h4-font-size);
	line-height: var(--h4-line-height);
}

.subhead,
.h5,
h5 {
	font-size: var(--subhead-font-size);
	line-height: var(--subhead-line-height);
}

p {
	margin: 0 0 em(20px);
	font-size: var(--body-font-size);
	line-height: var(--body-line-height);

	&.is-small {
		font-size: var(--body-font-size-small);
		line-height: var(--body-line-height-small);
	}
	&.is-gray {
		color: var(--color-gray-1);
	}
}

// Typography
:root {
	--h1-font-size: 28px;
	--h1-line-height: 38px;

	--h2-font-size: 30px;
	--h2-line-height: 40px;

	--h3-font-size: 32px;
	--h3-line-height: 42px;

	--h4-font-size: 32px;
	--h4-line-height: 42px;

	--body-font-size: 16px;
	--body-line-height: 28px;

	--body-font-size-small: 14px;
	--body-line-height-small: 24px;

	--subhead-font-size: 24px;
	--subhead-line-height: 36px;

	--subhead2-font-size: 20px;
	--subhead2-line-height: 34px;

	--label-font-size: 12px;
	--label-line-height: 16px;

}

@media screen and (min-width: $bp-mobile) and (max-width: $bp-tablet) {
	:root {
		--h1-font-size: 48px;
		--h1-line-height: 56px;

		--h2-font-size: 40px;
		--h2-line-height: 48px;
	}
}

@media screen and (min-width: $bp-tablet) and (max-width: $bp-desktop-small) {
	:root {
		--h1-font-size: 80px;
		--h1-line-height: 80px;

		--h2-font-size: 40px;
		--h2-line-height: 48px;
	}
}

@media screen and (min-width: $bp-desktop-small) {
	:root {
		--h1-font-size: 80px;
		--h1-line-height: 80px;

		--h2-font-size: 48px;
		--h2-line-height: 56px;

		--h3-font-size: 40px;
		--h3-line-height: 56px;

		--h4-font-size: 32px;
		--h4-line-height: 42px;

		--body-font-size: 16px;
		--body-line-height: 32px;
	}
}
