/**
* UI Dot
*/

.ui-dots {
	display: inline-flex;
	align-items: center;

	&__item {
		width: 24px; //hit area extended
		height: 24px; //hit area extended
		text-decoration: none;
		position: relative; //inner
		cursor: pointer;

		// dot
		&:before {
			content: "";
			width: 6px;
			height: 6px;
			position: absolute;
			top: 50%;
			left: 50%;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			background-color: var(--ui-btn-inactive-color);
			transition: width var(--ui-transition-dur),
			height var(--ui-transition-dur);
		}

		&:focus {
			@include global-focus-box;
		}

		&:active,
		&:hover,
		&:focus,
		&.active {
			&:before {
				width: 12px;
				height: 12px;
				background-color: var(--color-nov-red);
			}
		}
	}

	//state & color variation
	&.inactive {
		opacity: .25;
		pointer-events: none;
	}
}
