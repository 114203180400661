/**
* UI Image component
*/
.image-c {
	overflow: hidden;
	position: relative;

	figure {
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
	}

	figcaption{
		opacity: 0;
	}

	img {
		width: 100%;
		height: auto;

		&.lz-load {
			opacity: 0;
		}
		&.lz-loaded {
			opacity: 1;
			transition: opacity 400ms;
		}
	}

	// svg/gif loader(64x64)
	.loader {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		transform: translate3d(-50%, -50%, 0);
		pointer-events: none;
	}

	// state
	&.img-loading {
		.loader {
			opacity: 1;
		}
	}
	&.img-loaded {
		.loader {
			opacity: 0;
		}
	}

}
