.I13-related-search-terms {
    &, p {
        font-size: var(--label-font-size);
        line-height: var(--label-line-height);
        font-weight: var(--font-weight-medium);
    }

    &__heading {
        display: block;
        font-size: var(--body-font-size);
        line-height: var(--body-line-height);
        font-weight: var(--font-weight-bold);
        margin-bottom: 1.5em;
    }

    &__list {
		width: 100%;

        li {
            padding: 0.75em 0;
            margin: 0;
        }

        @include media(sm) {
            column-count: 2;
        }
    }
}
