/**
* Base.
* Variables and function definitions.
*/

@import 'framework/media-queries';

// ---------------------------------------------------------------------------
// Base variables(not using)
$base-font-size: 16px;
$base-line-height: 1.231em;
// ---------------------------------------------------------------------------
// Breakpoints (default is same as Bootstrap 3).
$bp-mobile-sm: 320px;
$bp-mobile: 425px;
$bp-tablet: 767px;
$bp-desktop-small: 1024px;
$bp-desktop-medium: 1280px;
$bp-desktop-large: 1440px;
$bp-desktop-xlarge: 1600px;
// Spacing

// ---------------------------------------------------------------------------
// CSS VARS(Ultimately, we will use css vars over sass)
:root {

	// FONTS(TODO, SourceSansPro)
	--base-font: 'Source Sans Pro',
	Calibri,
	'Apple SD Gothic Neo',
	Helvetica,
	Arial, sans-serif;
	--fallback-font: Calibri, 'Apple SD Gothic Neo', Helvetica, Arial, sans-serif;

	--standard-padding: 30px;

	@include media(sm) {
		--standard-padding: 80px;
	}


	--font-weight-black: 900;
	--font-weight-bold: 700;
	--font-weight-medium: 600;
	--font-weight-regular: 500;
	--font-weight-light: 300;
	--font-weight-ultra-light: 100;

	// COLORS
	// darkest -> lightest
	--color-gray-0: #78757b;
	--color-gray-1: #707070;
	--color-gray-2: #b2b2b2;
	--color-gray-6: #ececec;
	--color-gray-3: #f1f1f1;
	--color-gray-4: #f8f8f8;
	--color-gray-5: #f5f5f5;

	--color-white: #ffffff;
	--color-black: #000000;
	--color-nov-red: #da291c;
	--color-hover-red: #ed3124;
	--color-dark-red: #ba291c;
	--color-blue: #050505;
	--color-beige: #dccdbd;
	--color-green: #3A873E;

	--color-font-black: var(--color-black);
	--color-global-focus-color: var(--color-gray-0); // AA accessibility
	--color-gray-light: rgb(181, 182, 182); // #b5b6b6
	--color-custom-tap-color: rgba(3, 218, 197, .25); // when tapped

	// UI
	--ui-font-family: var(--base-font);
	--ui-btn-font-size: 16px;
	--ui-btn-inactive-color: #d2d2d2;
	--ui-btn-icon-size: 16px;
	--ui-box-shadow: 0px 0px 8px 3px rgba(33, 33, 33, 0.07);

	--ui-btn-line-height: 16px;
	--ui-text-btn-color: var(--color-black);
	--ui-transition-dur: 200ms;
	--ui-transition-dur-long: 500ms;
	--ui-easing: cubic-bezier(0.15, 0.67, 0.67, 1);

	--ui-svg-width: 24px;
	--ui-svg-height: 24px;
	--ui-sprite-width: 432px;

	--contact-button-height: 60px;

	@include media(sm) {
		--contact-button-height: 104px;
	}

	--spacing: 8px; // default spacing unit
	--base-animation-easing: cubic-bezier(.25, .12, .65, .94);
	--base-animation-duration: 300ms;

	--app-fetching-mimimun-delay: 1000ms; // header related
	--app-height: 100vh;

	// Overlay
	--overlay-bg: rgba(0, 0, 0, .8);
	--overlay-bg-light: rgba(0, 0, 0, .6);

	// Z Index
	--page-index: 1;
	--overlay-index: 1100;
	--header-index: 850;
	--image-detail-index: 100;
	--tool-bar-index: 2000 ;
	--seasrch-sidebar-index: 900;

	// border
	--color-border: #c5c5c5;
	--color-border-light: var(--color-white);

	// Breakpoints
	--bp-mobile-sm: 376px; // or 320
	--bp-mobile: 480px;
	--bp-tablet: 768px;

	--module-inner-width: 1178px;

	--bp-desktop-small: 1024px;
	--bp-desktop-medium: 1280px;
	--bp-desktop-large: 1440px;
	--bp-desktop-xlarge: 1600px;
}
